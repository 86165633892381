import styled from 'styled-components';

export const AdditionalPhones = styled.div`
  font-size: 12px;
  color: #6c757d;
  transition: .3s all;

  & span{
    :hover{
      transition: .3s all;
      text-decoration: underline;
      cursor:pointer;
      text-shadow: .25px .25px #6c757d;
      color: hsl(208, 7%, 10%);
    }
  }
`

/* Delete btn for additional phone's entries */
export const RemovePhone = styled.button`
  background-color: rgb(0, 179, 192);
  border: 1px solid transparent;
  border-radius: 4px;
  color: white;
  transition: all .2s ease-out;
  &:hover {
    background-color: rgb(0, 152, 163);
    transition: all .2s ease-out;
  }
`;

/* Styled entry for beneficiaries category */
export const StyledEntry = styled.div`
  border: 1px solid gray;
  border-radius: 6px;
  position: relative;
`;