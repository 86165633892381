import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { characters, envSetting, paramsAdd, paramsEdit, sOption, assistantsApp, unitedStates } from '../constants';
import { changeCitizenByOperator, sortElement, trimLowerCase } from '../helpers';
import { useFormContext } from 'react-hook-form';
import { bankReferencesField, statusRequest, userAmericanCitizenField } from '../constants';
import { useLocation } from 'react-router-dom';

/**
 * Hook to get the data to shared/select
 * @param {String} name
 * @param {Function} setValue
 * @param {String} text
 * @param {Boolean} isStaticData
 * @param {Boolean} isSetValue
 * @returns
 */
export const useSelectSimple = (name, setValue, text, isStaticData, isSetValue) => {
  const { requestLanguage, allowedCountries } = useSelector(state => state.base);
  const { [`data_${trimLowerCase(requestLanguage)}`]: staticData } = useSelector(
    state => state.staticData);
  const { pathname } = useLocation()
  let options = isStaticData ? staticData[name] : name;
  options = isStaticData ? sortElement(options, text) : options;

  /* Only show allowedCountries, when accesing the user's Profile */
  if(pathname.includes('/user/profile') && name.includes('country')) options = allowedCountries  

  const handleChange = ({ target }) => {
    (isSetValue) ? setValue(target.name, target.value) : setValue(target.value);
  };

  return { options, handleChange };
};

/**
 * Get element is disabled
 * @param {Object} field
 * @returns
 */
export const useDisabled = field => {
  const { action } = useSelector(state => state.tier);
  const { dataProductRequest } = useSelector(state => state.user);
  const [isDisabled, setIsDisabled] = useState(false);
  const { infoProductRequest } = useSelector(state => state.user)
  const { code } = useSelector(state => state.base.country)
  const { setValue } = useFormContext()

  useEffect(() => {
    if (action === paramsAdd || action === paramsEdit) {
      const fieldAction = (field?.action) ? JSON.parse(field.action) : {};
      if (fieldAction?.attributes) {
        const index = Array.from(fieldAction.attributes).findIndex(
          item => item.name === 'disabled');
        setIsDisabled(index >= 0);
      }
    }
  }, [action, field]);

  useEffect(() => {
    const uniqueName = `${field?.unique_name}__id__${field?.id}`
    
    if (action === paramsAdd && uniqueName.startsWith(bankReferencesField) && infoProductRequest.application.unique_name === assistantsApp) {
      setValue(uniqueName, sOption)
    }

    // Adding condition for the 'user__american_citizen' field in the Check component
    if (uniqueName?.startsWith(userAmericanCitizenField)) {
      setTimeout(() => {
        if (action === paramsAdd && trimLowerCase(code) !== trimLowerCase(unitedStates)) {
          setValue(uniqueName, false);
          // getting into changeCitizenByOperator
          setTimeout(() => {
            changeCitizenByOperator(setValue, code);
          }, 50);
        }
      }, 500);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // TODO: Commented if this validation is needed on future.
    // const allDataUpdateRequests = Array.from(dataProductRequest).filter(
    //   item => item.application_origin.unique_name === appsOrigin.data_update);

    const currentlyUpdating = Array.from(dataProductRequest).some(request => request.status.unique_description === statusRequest.processing)

    if (action === paramsEdit) {
      if (field?.never_editable) {
        setIsDisabled(true);
      } else {
        setIsDisabled(true);
        if (currentlyUpdating) {
          setIsDisabled(false);
        }
      }
    }
  }, [action, field]);

  return { isDisabled };
};

/**
 * Get description_languague and language
 * @returns
 */
export const useTextLanguage = () => {
  const { actualLanguage } = useSelector(state => state.base);
  const languageText = `_${trimLowerCase(actualLanguage)}`;
  const description = `description${languageText}`;

  return { description, languageText };
};

/**
 * Hook to errors
 * @param {String} typeMessage
 * @returns
 */
export const useErrors = typeMessage => {
  const { t } = useTranslation();
  let message = '';

  switch (typeMessage) {
    case 'fieldRequired':
      message = t("common.fieldRequired");
      break;

    case 'fieldMin6':
      message = t("common.fieldMin", { min: 6 });
      break;

    case 'fieldMin8':
      message = t("common.fieldMin", { min: 8 });
      break;

    case 'emailInvalid':
      message = t("common.emailInvalid");
      break;

    case 'usernameRequired':
      message = t("getStarted.usernameRequired", { min: 6 });
      break;

    case 'passwordLowerCase':
      message = t("getStarted.passwordLowerCase");
      break;

    case 'passwordUpperCase':
      message = t("getStarted.passwordUpperCase");
      break;

    case 'passwordSpecial':
      message = t("getStarted.passwordSpecial", { characters });
      break;

    case 'passwordNumber':
      message = t("getStarted.passwordNumber");
      break;

    case 'passwordNotTheSame':
      message = t("password.notTheSame");
      break;

    case 'tokenFieldMax':
      message = t("common.fieldMax", { max: envSetting.maxMinutesToken });
      break;

    case 'phoneFieldMax':
      message = t("common.fieldMax", { max: '25' });
      break;

    case 'urlInvalid':
      message = t("common.urlInvalid");
      break;

    case 'integer':
      message = t("common.integer");
      break;

    case 'fieldMax6':
      message = t("common.fieldMax", { max: 6 });
      break;

    case 'documentNumberFieldMax':
      message = t("common.fieldMax", { max: '20' });
      break;

    default:
      const number = typeMessage.split('__').pop();
      message = typeMessage.split('__').shift();

      if (message === 'fieldMax') {
        message = t("common.fieldMax", { max: number });
      } else {
        if (message === 'fieldMin') {
          message = t("common.fieldMin", { min: number });
        } else {
          message = typeMessage;
        }
      }
      break;
  }

  return message;
};
