import React from 'react';
import { useBlock } from '../../../hooks/useBlock';
import { Entry } from './Entry';
import { beneficiaryCategory, additionalPhonesCategory, paramsAdd, paramsEdit } from '../../../constants';
import PropTypes from 'prop-types';
import { t } from 'i18next'
import { useAdditionalPhones } from '../../../hooks/useAdditionalPhones';
import { AdditionalPhones } from './styled';

/**
 * Component Block
 * @component
 * @param {Object} field
 * @returns {JSX.Element}
 */
export const Block = ({ field, category }) => {
  const {
    fields,
    maxLength,
    handleAdd,
    setEntries,
    entries,
    canContinue,
    setCanContinue,
    phoneBlock,
    action
  } = useBlock(field, category);
  const { phoneEntries, setPhoneEntries, minEntryId, canAddPhone, setCanAddPhone } = useAdditionalPhones(category)

  if(category === beneficiaryCategory){
    // For the BENEFICIARIES implementation
    return (
      <section className="row">
        {entries.map((entry) => {
          return(
            <Entry
              fields={fields}
              setEntries={setEntries}
              entry={entry}
              key={entry.id}
              maxLength={maxLength}
              entries={entries}
              canContinue={canContinue}
              setCanContinue={setCanContinue}
              handleAdd={handleAdd}
              category={category}
            />
          );
        })}
      </section>
    );
  }

  if(category === additionalPhonesCategory){
    // For the ADDITIONAL PHONES implementation
    return (
      <>
        {
          phoneEntries
            .map((entry, idx) => {
            return(
              <Entry 
                fields={fields}
                setEntries={setPhoneEntries}
                key={entry.id}
                maxLength={maxLength}
                entries={phoneEntries}
                handleAdd={handleAdd}
                category={category}
                entry={entry}
                minEntryId={minEntryId}
                canAddPhone={canAddPhone}
                setCanAddPhone={setCanAddPhone}
              />
            )
          })
        }
        {
          /* While NOT empty, but maxLength not reached */
          ((phoneEntries.length < phoneBlock?.max_length) && (action !== paramsEdit)) && canAddPhone ? (
            <AdditionalPhones className='col-6'>
              {t('common.additionalPhonesPre')} <span onClick={() => handleAdd(setPhoneEntries, phoneEntries, setCanAddPhone)}> {t('common.additionalPhonesLink')}</span>
            </AdditionalPhones>
          ) : null
        }
      </>
    )
  }
};

Block.propTypes = {
  field: PropTypes.object.isRequired,
  category: PropTypes.string
};