import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { sendPostRequest, sendGetEndpoint } from '../services/baseApi';
import { setMessage } from '../redux/actions';
import { beneficiaryCategory, sOption, endpoints, siteMap, paramsAdd, paramsEdit, additionalPhonesCategory } from '../constants';
import { getValue } from '../helpers';
import { getNextEntryId } from '../helpers';

const startingEntries = [
  {
    isValid: false,
    id: 1
  }
];

/**
 * Component Block's custom hook
 * Custom hook
 * @param {Object} field
 * @returns
 * @property {Object[]} fields         - All beneficiaryCategory related fields
 * @property {Number}   maxLength      - Max length, to control the max ammount of entries.
 * @property {Function} setEntries     - setEntries setter function
 * @property {Object[]} entries        - Entries state.
 * @property {Boolean}  canContinue    - Variable that controls if the user can advance on the steps or create a new Entry
 * @property {Function} setCanContinue - canContinue setter function.
 * @property {Function} handleClick    - click handle that redirects
 * @property {Object}   phoneBlock     - Admin's field representing the container block field for the additional phones (block__additional_phones)
 * @property {String}   action         . String representing the current action; mainly 'add' or 'edit'
 */
export const useBlock = (field, category) => {
  const [entries, setEntries] = useState(startingEntries);
  const { action } = useSelector(state => state.tier);
  /* In order to have as many entries as blockEntries we have on the Redux personData (when editing) */
  const blockEntries = useSelector(state => state.personData.data.related_accounts);

  // Set currentTier, according to the current 'category'
  const currentTier = category === beneficiaryCategory ? 'advanced' : 'basic'
  let fields = useSelector(state => state.tier.tiers[currentTier]?.categories.fields[category]);
  
  // Since the 'phone numbers' don't have their own 'category' (as 'beneficiaries' did), we will have to filter just the ones with 'action.isBlock'
  fields = (category === beneficiaryCategory) ? fields : fields.filter(field => JSON.parse(field?.action)?.isBlock)
  // We will also make use of the main 'block__' field. The container that holds all of the phone blocks.
  let [phoneBlock] = useSelector(state => state.tier.tiers[currentTier]?.categories.fields[category].filter(field => field.unique_name.includes('block__')))
    
  const maxLength = field.max_length;
  const { getValues, setValue } = useFormContext();
  const [canContinue, setCanContinue] = useState(true);
  const navigate = useNavigate();
  let firstSelectValue;

  useEffect(() => {
    if (action === paramsEdit || action === paramsAdd) {
      if (blockEntries) {
        const entriesLength = blockEntries?.length;
        setEntries(prev => {
          const newEntries = [...prev];
          newEntries[0].isValid = true;
          return newEntries;
        });
        for (let i=2; i <= entriesLength; i++) {
          setEntries(prev => {
            if (prev?.length < entriesLength) return [...prev, {  isValid: true, id: i }];
            return prev;
          });
        }
      }
    }
  }, [action]);

  useEffect(() => {
    entries.sort((a, b) => {
      return a.id - b.id;
    });
  }, [entries]);

  useEffect(() => {
    if(category === beneficiaryCategory){
      const [firstSelect] = fields.filter(field => field?.unique_name === 'block__type');
      const firstSelectValue = getValues(firstSelect.unique_name + '__id__' + firstSelect.id);
      if (entries.length > 1) {
        setCanContinue(entries.every(entry => entry.isValid));
      } else {
        if (firstSelectValue === sOption || firstSelectValue === '') {
          setCanContinue(true);
        } else {
          setCanContinue(entries.every(entry => entry.isValid));
        }
      }
    }
  }, [entries, canContinue, firstSelectValue]);

  const handleClick = () => {
    navigate(siteMap.editBeneficiaries.path);
  };

  
  /**
   * Adds a new entry to the current Entry array.
   *
   * @param {Function} setterFunction
   * @param {Array} currentEntries
   */
  const handleAdd = (setterFunction, currentEntries=null, setCanAddPhone=null) => {
    //* Getting values for 'contact_type', 'main' and 'validated'  in new entries, case additionalPhones 
    if (category=== additionalPhonesCategory) {
      if (setCanAddPhone) setCanAddPhone(false)
      const fieldsToBeAdded = fields.filter(field => field.unique_name.includes(getNextEntryId(currentEntries) + 1))
      fieldsToBeAdded.forEach(field => {
        const uniqueName = field?.unique_name + '__id__' + field?.id
        if (field.unique_name.includes('contact_type')) {
          const defaultValue = getValue(field)
          // Setting value to 'contact_type' in new Entry
          setValue(uniqueName, defaultValue)
        } else if (field.unique_name.includes('main') || field.unique_name.includes('validated')) {
          setValue(uniqueName, false)
        } else {
          setValue(uniqueName, '')
        }
      });

      setterFunction(prevEntries => {
        return [...prevEntries, {
          id: getNextEntryId(prevEntries)
        }]
      }
    );
    }

    if(category === beneficiaryCategory){
      setterFunction(prevEntries => {
          return [...prevEntries, {
            id: getNextEntryId(prevEntries),
            isValid: false
          }]
        }
      );
    }
  };

  return {
    fields,
    maxLength,
    handleAdd,
    setEntries,
    entries,
    canContinue,
    setCanContinue,
    handleClick,
    phoneBlock,
    action
  };
};

/**
 * Custom hook for the Edit component
 * Custom Hook
 * @returns
 */
export const useEditBlock = ({ selectedProduct }) => {
  const { t } = useTranslation();
  const userId = useSelector(state => state.personData.data.id);
  const [entries, setEntries] = useState([{
    'active':false,
    'user': userId,
    'related_user_wisenroll_code': '',
    'authorized_signature': false,
    'client_account_link_type_id': 0,
    'isValid': false,
    'order': 1,
    'client_related_account_id': null
  }]);
  const [canSubmit, setCanSubmit] = useState(false);
  const [wasPopulated, setWasPopulated] = useState(null);
  const [loadingRelatedAccounts, setLoadingRelatedAccounts] = useState(false);

  const getRelatedAccounts = async () => {
    setLoadingRelatedAccounts(true);
    let result = await sendGetEndpoint(
      endpoints.relatedAccountsPerProduct(selectedProduct.product_id));
    if (result.success) {
      setWasPopulated(result.success.length > 0);
      result = result.success.map(entry => {
        return {
          ...entry,
          'related_user_wisenroll_code':entry.related_wisenroll_code,
          'client_account_link_type_id':entry.account_link_type,
          'isValid':true,
          'active': true
        };
      });
      result = result.map((entry, idx) => {
        return {
          ...entry,
          'order':idx + 1
        };
      });

      setEntries(result);
    }
    setLoadingRelatedAccounts(false);
  };

  useEffect(()=>{
    if (selectedProduct.product_id) {
      getRelatedAccounts();
    }
  }, [selectedProduct.product_id]);

  /* Function that adds (by adding or by just changing its 'active' to 'true') beneficiaries to the list */
  const handleAdd = () => {
    setEntries(prevEntries => {
      const ids = prevEntries.map(entry => entry.order);
      const activeIds = prevEntries.filter(entry => entry.active).map(entry => entry.order);
      if (activeIds.length === ids.length) {
        return [
          ...prevEntries,
          {
            'related_user_wisenroll_code': '',
            'authorized_signature': false,
            'client_account_link_type_id': 0,
            'isValid': false,
            'order': prevEntries.length + 1,
            'active': true,
          }
        ];
      } else {
        let missingNumber;
        for (let i = 1; 1 <= ids.length; i++) {
          if (activeIds.includes(i)) continue;
          if (activeIds[i] !== i) {
            missingNumber = i;
            break;
          }
        }
        return prevEntries.map(prevEntry => {
          if (prevEntry.order === missingNumber) {
            return {
              ...prevEntry,
              active:true
            };
          } else {
            return prevEntry;
          }
        });
      }
    });
  };

  const resetBeneficiary = () => {
    setEntries(prevEntries => {
      return prevEntries.map(entry => {
        return {
          ...entry,
          active: false
        };
      });
    });
  };

  useEffect(() => {
    setCanSubmit(entries?.filter(entry => entry.active).every(entry => {
      return entry.isValid;
    }));
  }, [entries, canSubmit]);

  return {
    handleAdd,
    entries,
    canSubmit,
    t,
    setEntries,
    resetBeneficiary,
    loadingRelatedAccounts,
    wasPopulated
  };
};

/**
 * Custom hook for the Edit/Entry component
 * Custom Hook
 * @returns
 */
export const useEditEntry = ({ entry, entries, setEntries }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [wisenrollUser, setWisenrollUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const { code } = useSelector(state => state.user);

  async function checkBeneficiary() {
    setLoading(true);
    const response = await sendPostRequest({
      'wisenroll_code': entry.related_user_wisenroll_code,
      'owner_wisenroll_code': code
    }, endpoints.validateWisenrollCode);

    if (response?.error) {
      setWisenrollUser({
        error:true,
        user:null
      });
      setEntries(prevEntries => {
        return prevEntries.map(prevEntry => {
          if (prevEntry.order === entry.order) {
            return {
              ...prevEntry,
              isValid: false
            };
          } else {
            return prevEntry;
          }
        });
      });
    }

    if(response?.success) {
      setWisenrollUser({
        error: false,
        user:{
          firstName: response.success.first_name,
          lastName: response.success.last_name,
          docType: response.success.document_type_description,
          docNumber: response.success.document_number
        }
      });
      setEntries(prevEntries => {
        return prevEntries.map(prevEntry => {
          if (prevEntry.order === entry.order) {
            return {
              ...prevEntry,
              isValid: true
            };
          } else {
            return prevEntry;
          }
        });
      });
    }
    setLoading(false);

    return response;
  };

  async function handleCheck() {
    setLoading(true);

    if (entry.related_user_wisenroll_code === code) {
      setMessage(t("block.errorSameOwnerCode"), 'error');
      setLoading(false);
      return;
    } else {
      const response = await checkBeneficiary();
      if (response.error) dispatch(setMessage(t("block.error"), "error"));
      setEntries(prevEntries => {
        return prevEntries.map(prevEntry => {
          if (prevEntry.order === entry.order) {
            return {
              ...prevEntry,
              isValid: response.error ? false : true
            };
          } else {
            return prevEntry;
          }
        });
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (entry.related_user_wisenroll_code === '') {
      setLoading(false);
    } else {
      checkBeneficiary();
    }
  }, []);

  useEffect(() => {
    if(entries.length === 1 && entry.client_account_link_type_id === '') setWisenrollUser(null);
  }, [entries]);

  const handleRemove = () => {
    setEntries(prevEntries => {
      return prevEntries.map(prevEntry => {
        if (prevEntry.order === entry.order) {
          return {
            ...prevEntry,
            active:false
          };
        } else {
          return prevEntry;
        }
      });
    });
  };

  return {
    checkBeneficiary,
    handleCheck,
    loading,
    setLoading,
    wisenrollUser,
    setWisenrollUser,
    handleRemove
  };
};
