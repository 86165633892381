import React, { useState } from 'react';
import { useDescription, useModule, useTextLanguage } from '../../hooks';
import { Back, StaticMessage, Title } from '../shared/simple';
import { Form } from './Form';
import { ModuleContext } from '../../context';
import { Row, Col, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FloatButton, ListModal } from '../assistants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadset } from '@fortawesome/free-solid-svg-icons';
import { isActualRequestSuccessOrRejected, isUndefinedNull, notUndefinedNull } from '../../helpers';
import { useSelector } from 'react-redux';

/**
 * Component module
 * @component
 * @returns
 */
export const Module = () => {
  const { description } = useTextLanguage();
  const {
    allAssistants,
    categoriesTier,
    needAssistant,
    selectedAssistant,
    tier,
    tierFieldState
  } = useModule();
  const {
    flagRequest
  } = useDescription();
  const { t } = useTranslation();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { dataProductRequest, lastProductRequestId } = useSelector(state => state?.user);

  return (
    <Row>
      <Col className="p-0 text-end" xs={12}>
        <Back />
      </Col>
      <Col className="p-4 pb-1 pt-1" xs={12}>
        {(notUndefinedNull(selectedAssistant)) &&
          <StaticMessage
            variant="info"
            message={ t("assistants.assignedAssistant", { name: `${
              selectedAssistant.first_name} ${selectedAssistant.last_name}` }) }
          />
        }
        {(needAssistant && isUndefinedNull(selectedAssistant)) &&
          <StaticMessage
            variant="warning"
            message={ `${t("assistants.needAssistant")}` }
          />
        }
      </Col>
      <Col className="p-4 pt-0 pb-1" xs={12}>
        <Title className="pb-1">
          { tier[description] }
        </Title>
      </Col>
      {(flagRequest) && (
        <Col className="p-3" xs={12}>
          <Alert variant={'warning'}>
            {t("common.updateDataWarningMessage")}
          </Alert>
        </Col>
      )}
      {(isActualRequestSuccessOrRejected(dataProductRequest, lastProductRequestId)) && (
        <Col className="p-3" xs={12}>
          <Alert variant={'warning'}>
            {t("common.createNewRequestWarning")}
          </Alert>
        </Col>
      )}
      <Col className="p-4 pt-0" xs={12}>
        <ModuleContext.Provider
          value={[ categoriesTier, tier, tierFieldState ]}
        >
          <Form />
        </ModuleContext.Provider>
      </Col>
      {(allAssistants?.length !== 0 && notUndefinedNull(
        allAssistants) && isUndefinedNull(selectedAssistant)) && (
        <FloatButton
          className="btn"
          type="submit"
          color="first"
          onClick={() => setModalIsOpen(true) }
        >
          <FontAwesomeIcon icon={ faHeadset } size="2x"/>
        </FloatButton>
      )}
      <ListModal
        modalIsOpen={ modalIsOpen }
        setModalIsOpen={ setModalIsOpen }
      />
    </Row>
  );
};