import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card , Col, Row } from 'react-bootstrap';
import { Email } from './Email';
import { profile, statusRequest } from '../../../constants';
import ProfileBlock from '../../tier/block/edit/profile/ProfileBlock';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { ButtonCircle } from '../../shared/simple';
import i18n from '../../../translations/i18n';
import { setMessage } from '../../../redux/actions';

/**
 * Link component
 * @component
 * @param {String} codeCard
 * @param {String} color
 * @param {Array} data
 * @param {Function} setRequestEdit
 * @param {Function} setRequestTokenForce
 * @param {String} title
 * @returns
 */
export const Section = ({
  codeCard,
  color,
  data,
  setRequestEdit,
  setRequestTokenForce,
  title,
  editRequest,
  isEditing,
  setIsOpenValidateSms,
  cancelEditRequest,
  phones,
  entries,
  setEntries,
  isTouched,
  setIsTouched
}) => {
  const { t } = useTranslation();
  const { code, dataProductRequest } = useSelector(state => state.user);

  const onClick = () => {
    const sent = dataProductRequest.some(request => request.status.unique_description === statusRequest.sent)
    if (sent) { 
      setMessage(i18n.t('common.updateDataWarningMessage'), 'error')
    } else {
      editRequest()
    }
  } 

  return (
    <Card className="mt-3 mb-2" style={{ borderColor: color }}>
      <Card.Body>
        <Card.Title className="mb-2 p-6" style={{ color: color }}>
          <div className=' d-flex justify-content-between align-items-center'>
            { title }
            {/* Request edit button */}
            {(codeCard === profile.cardPhone) && (
              <ButtonCircle
                id={ profile.editModulePhone }
                className="float-end"
                color="first"
                onClick={ onClick }
                title={ i18n.t("common.edit") }
                name="btn_edit_email"
              >
                <FontAwesomeIcon size='sm' icon={ faPen }/>
              </ButtonCircle>
            )}

          </div>
        </Card.Title>
        <Row>
          {(codeCard === profile.cardGeneral) && (
            <Col className="mb-1" xs={12} lg={6}>
              <small className="text-muted" xs={12}>
                <strong>{ t("homepage.code") }</strong>
              </small>&nbsp;<strong>{ code }</strong>
            </Col>
          )}
          {(codeCard !== profile.cardPhone) && data.map(item =>
            <Col className="mb-1" lg={ item?.lg } xs={12} key={ item.key }>
              <small className="text-muted" xs={12}>
                <strong>{ item.name }</strong>
              </small>
              <p className="mb-0" xs={12} id={ item.key }>
                { item.text }
              </p>
            </Col>
          )}
          {(codeCard === profile.cardEmail) && (
            <Email
              color={ color }
              setRequestEdit={ setRequestEdit }
              setRequestTokenForce={ setRequestTokenForce }
            />
          )}
          {(codeCard === profile.cardPhone) && (data?.[0]?.phones) && (
            <ProfileBlock
              data={data}
              isEditing={isEditing}
              setRequestEdit={ setRequestEdit }
              setRequestTokenForce={ setRequestTokenForce }
              cancelEditRequest={cancelEditRequest}
              setIsOpenValidateSms={setIsOpenValidateSms}
              phones={phones}
              entries={entries}
              setEntries={setEntries}
              isTouched={isTouched}
              setIsTouched={setIsTouched}
            />
          )}


        </Row>
      </Card.Body>
    </Card>
  );
};

Section.propTypes = {
  codeCard: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  setRequestEdit: PropTypes.func,
  setRequestTokenForce: PropTypes.func,
  title: PropTypes.string.isRequired
};