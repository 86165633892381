import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { Col } from 'react-bootstrap';
import { defaultAux, isUndefinedNull, notUndefinedNull, trimLowerCase } from '../../../helpers';
import { Input } from '../../shared/Input';
import { fileStatus, paramsEdit, mainDocument } from '../../../constants';
import { useTextLanguage } from '../../../hooks';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

/**
 * Component aux input
 * @component
 * @param {Object} field
 * @returns
 */
export const AuxInput = ({ field }) => {
  const uniqueName = `${field.unique_name}__id__${field.id}`;
  const { description, languageText } = useTextLanguage();
  const { register, formState: { errors }, setValue } = useFormContext();
  const { action } = useSelector(state => state.tier);
  const { codeTier } = useParams();

  useEffect(() => {
    setTimeout(() => {
      if (field?.default_value) {
        if (codeTier === fileStatus.rejected || codeTier === fileStatus.expired) {
          const storageData = (field?.storage_data_key) ? JSON.parse(
            field.storage_data_key) : null;
          if (isUndefinedNull(storageData) || storageData?.type === 'default') {
            defaultAux(field, setValue, uniqueName);
          }
        } else {
          if (action !== paramsEdit) {
            defaultAux(field, setValue, uniqueName);
          } else {
            const storageData = (field?.storage_data_key) ? JSON.parse(
              field.storage_data_key) : null;
            if (isUndefinedNull(storageData) || storageData?.type === 'default') {
              defaultAux(field, setValue, uniqueName);
            } 
          }
        }
      }
    }, 1000);
  }, [field, action, setValue, uniqueName]);

  return (
    <>
      {field && (
        <Col
          className={ field.col_size }
          data-module={ `module-${field.category?.field_category}` }
          data-name={ uniqueName }
          id={ `group_field_${field.id}` }
          key={ field.id }
          xs={12}
        >
          <Input
            autoComplete="off"
            className="w-100"
            field={ field }
            id={ trimLowerCase(field.id) }
            isRequiredInput={ field.required }
            label={ field[description] }
            message={ errors?.[uniqueName]?.message }
            name={ register(uniqueName).name }
            placeholder={ field?.[`placeholder${languageText}`] }
            register={ register(uniqueName) }
            setValue={ setValue }
            tooltipText={ field?.[`tooltip${languageText}`] }
            type="text"
          />
        </Col>
      )}
    </>
  );
};

AuxInput.propTypes = {
  field: PropTypes.object.isRequired
};