import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col } from 'react-bootstrap';
import { StyledGroup, StyledControl } from '../../../../shared/Input/styled';
import { Label } from '../../../../shared/simple';
import { editionStatus, blockCases } from '../../../../../constants';

/**
 * TextInput component for handling text input specifically for different block cases.
 *
 * @component
 * @param {Object} props                    - The properties passed to the component.
 * @param {String} props.blockCase          - Case identifier to handle specific logic. ('beneficiary' || 'additional_phone')
 * @param {Object} props.entry              - Current entry
 * @param {Function} props.setEntries       - Function to update entries.
 * @param {Function} props.setWisenrollUser - Function to update the Wisenroll user data. In case blockCase === 'beneficiary' only.
 * @param {Object} props.selectedProduct    - Product data to check the edition status. In blockCase === 'beneficiary' only.
 * @param {Boolean} props.loading           - Loading state.
 * @param {String} props.className          - Classnames
 * @returns {JSX.Element} A React component that renders a styled text input.
 */
export const TextInput = (props) => {
  const { t } = useTranslation();
  const blockCase = props.blockCase || ''

  const handleChange = (e) => {
    if (blockCase === blockCases.beneficiary) {
      const { entry, setEntries, setWisenrollUser } = props
      setWisenrollUser({});
      setEntries(prevEntries => {
        return prevEntries.map(prevEntry => {
          if (prevEntry.order === entry.order) {
            return {
              ...prevEntry,
              related_user_wisenroll_code: e.target.value,
              isValid:false
            };
          } else {
            return prevEntry;
          }
        });
      });
    }

    if (blockCase === blockCases.phones) {
      const { entry, setEntries, setIsTouched } = props

      const updateEntries = () => {
        setEntries(prevEntries => {
          return prevEntries.map(prevEntry => {
            if (prevEntry.key === entry.key){
              return {
                ...prevEntry,
                description: e.target.value,
                validated: false
              }
            }
            else return prevEntry
          })
        })
        setIsTouched(true)
      }

      if (entry?.validated){
        if(window.confirm(t('block.confirmModification'))){
          updateEntries()
        }
      } else {
        updateEntries()
      }

    }
  };
  
  const isDisabled = () => {
    if(blockCase === 'beneficiary'){
      const { loading, selectedProduct } = props
      return (loading || selectedProduct.status === editionStatus.pending)
    } else {
      return false
    }
  }

  const getValue = () => {
    if(blockCase === 'beneficiary'){
      const { entry } = props
      return entry.related_user_wisenroll_code
    }
    if(blockCase === blockCases.phones){
      const { entry } = props
      return entry?.description
    }
  }

  return (
    <>
      <Col
        className={props.className}
        xs={12}
      >
        <StyledGroup className="mb-3">
            { (blockCase === blockCases.beneficiary) && <>
              <Label>
                { props.label }
                <strong className="text-danger">&nbsp;*</strong>
              </Label>
            </> }

            {/* Label for blockCase.phones */}
            {(blockCase === blockCases.phones) &&
              <small className="text-muted" xs={12}>
                <strong>{ props?.label }</strong>
              </small>
            }
          <StyledControl
            type='text'
            value={getValue()}
            onChange={(e) => handleChange(e)}
            disabled={isDisabled()}
          />
        </StyledGroup>
      </Col>
    </>
  );
};

TextInput.propTypes = {
  setEntries: PropTypes.func,
  setWisenrollUser: PropTypes.func,
  loading: PropTypes.bool
};