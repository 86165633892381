import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  hiddenField,
  sOption,
  hiddenNumber,
  hiddenCode ,
  paramsAdd,
  paramsEdit,
  siteMap,
  statusRequest
} from '../constants';
import {
  caseEditAction,
  fieldAttribute,
  isUndefinedNull,
  notUndefinedNull,
  parentConditionEdit,
  setCategoriesTierFields,
  setFieldsCategories,
  setTierFields,
  trimLowerCase,
  validateAssistantRule,
  getCondition,
  fieldUniqueName
} from '../helpers';
import {
  getAvailableAssistants,
  getProductRequest,
  setLoader,
  setNavigate
} from '../redux/actions';
import {
  TIER_CATEGORY,
  TIER_SET_TIERS,
  TIER_CATEGORIES,
  BASE_ALL_ASSISTANTS,
  TIER_VALUES_EDIT,
  TIER_CLEAR_CATEGORY,
  TIER_PARAMS,
  TIER_PARENTS,
  USER_FILES
} from '../redux/types';

/**
 * use to validate action in tier
 * @returns
 */
export const useValidateTier = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { actionTier, codeTier } = useParams();
  const { level } = useSelector(state => state.user);
  const { requestLanguage } = useSelector(state => state.base);
  const { action, code } = useSelector(state => state.tier);
  const { tier: tiers } = useSelector(
    state => state.staticData[`data_${trimLowerCase(requestLanguage)}`]);

  useEffect(() => {
    if (notUndefinedNull(actionTier) && notUndefinedNull(codeTier)) {
      if (isUndefinedNull(action) && isUndefinedNull(code)) {
        dispatch({
          type: TIER_PARAMS,
          payload: { action: actionTier, code: codeTier }
        });
      }
      if (action !== actionTier || code !== codeTier) {
        dispatch({
          type: TIER_PARAMS,
          payload: { action: actionTier, code: codeTier }
        });
      }
    }
  }, [actionTier, codeTier]);

  useEffect(() => {
    if (trimLowerCase(actionTier) !== paramsEdit && trimLowerCase(
      actionTier) !== paramsAdd) {
      navigate(siteMap.homepage.path);
    }
  }, [actionTier, navigate]);

  useEffect(() => {
    const info = tiers?.find(elem => codeTier === elem.unique_name);
    if ((parseInt(level + 1) < parseInt(info?.verification_level) || parseInt(
      level) >= parseInt(info?.verification_level)) && trimLowerCase(
        actionTier) === paramsAdd) {
      navigate(siteMap.homepage.path);
    }
    if (parseInt(info?.verification_level) > parseInt(level) && trimLowerCase(
      actionTier) === paramsEdit) {
      navigate(siteMap.homepage.path);
    }
  }, [actionTier, codeTier, level, tiers, navigate]);
};

/**
 * Use to component module
 * @returns
 */
export const useModule = () => {
  const dispatch = useDispatch();
  const { codeTier } = useParams();
  const { tiers, tier, tierFieldState, tierCategories } = useSelector(
    state => state.tier);
  const { data: personData } = useSelector(state => state.personData);
  const { requestLanguage, location, selectedAssistant, allAssistants } = useSelector(
    state => state.base);
  const { [`data_${trimLowerCase(requestLanguage)}`]: {
    assistant_rule: assistantRules } } = useSelector(state => state.staticData);
  const { dataProductRequest } = useSelector(state => state.user);
  const [tierCode, setTierCode] = useState({});
  const [categoriesCode, setCategoriesCode] = useState([]);
  const [fieldStateCode, setFieldStateCode] = useState([]);

  useEffect(() => {
    if (isUndefinedNull(tierFieldState) && isUndefinedNull(
      tierCategories) && isUndefinedNull(tier)) {
      const dataTier = tiers[trimLowerCase(codeTier)];
      setTierCode(dataTier.data);
      setCategoriesCode(dataTier.categories.data);
      setFieldStateCode(dataTier.fields);
      dispatch({
        type: TIER_CATEGORIES,
        payload: {
          tier: dataTier.data,
          tierCategories: dataTier.categories.data,
          tierFieldState: dataTier.fields,
          numberCategories: dataTier.categories.data.length
        }
      });
      const parents = [...new Set(Array.from(
        dataTier.fields).map(item => item.parent).filter(
          item => notUndefinedNull(item)))];
      dispatch({ type: TIER_PARENTS, payload: parents });
      dispatch({ type: USER_FILES, payload: { files: null } });
    } else {
      setTierCode(tier);
      setCategoriesCode(tierCategories);
      setFieldStateCode(tierFieldState);
    }
  }, [codeTier]);

  useEffect(() => {
    const getAssistants = async () => {
      if (isUndefinedNull(allAssistants)) {
        dispatch(setLoader(true));
        const data = {
          country_code: location,
          user_id: personData.id,
          by_distance: true
        };
        const response = await dispatch(getAvailableAssistants(data));
        dispatch({ type: BASE_ALL_ASSISTANTS, payload: response });
        dispatch(setLoader(false));
      }
    };

    getAssistants();
  }, [allAssistants]);

  const needAssistant = validateAssistantRule(location, assistantRules);

  return {
    allAssistants,
    categoriesTier: categoriesCode,
    needAssistant,
    selectedAssistant,
    tier: tierCode,
    tierFieldState: fieldStateCode
  };
};

/**
 * Use to completed tier
 */
export const useCompletedForm = methods => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getValues, setValue } = methods;
  const { isNavigate, route } = useSelector(state => state.user);
  const { action, parents, tierFieldState } = useSelector(state => state.tier);
  const { requestLanguage } = useSelector(state => state.base);
  const { field: fields } = useSelector(state => state.staticData[`data_${trimLowerCase(
    requestLanguage)}`]);
  const tier = useSelector(state => state.tier) 
  const { code: currentTierCode  } = tier
  const timeForParentCondition = (action === paramsEdit) ? 20000 : 12000

  useEffect(() => {
    if (isNavigate) {
      dispatch(setNavigate(false));
      navigate(route);
    }
  }, [dispatch, isNavigate, navigate, route]);

  useEffect(() => {
    if (action === paramsEdit || action === paramsAdd) {
      dispatch(setLoader(true));
      setTimeout(() => {
        dispatch(setLoader(true));
        if (parents.length > 0) {
          Array.from(parents).forEach(item => {
            const [field] = Array.from(tierFieldState).filter(
              elem => trimLowerCase(elem.id) === trimLowerCase(item));
            const data = (field?.storage_data_key) ? JSON.parse(
              field.storage_data_key) : null;
            if (field?.unique_name && data?.show !== 'false') {
              const uniqueName = `${field.unique_name}__id__${field.id}`;
              const element = fieldAttribute('name', uniqueName);
              if (element) {
                const value = trimLowerCase(getValues(element.name));
                if (value !== '' && notUndefinedNull(value) && value !== hiddenField && value !== hiddenNumber && value !== hiddenCode && field) {
                  parentConditionEdit(element, field, methods);
                }
              }
            }
          });

        }
      }, 1000);
      setTimeout(() => {
        // Function commented since it seems to perform the same function that parentConditionEdit already does.
        // caseEditAction(parents, methods);
        if (action === paramsEdit || action === paramsAdd) {
          // * Checking if any field in the group of the 'names to check' fields (those with 'storage_data_key.editing:[{'type':'check'}]') has a value.
          // If they are all empty, set them all to 'hiddenField'.
          const namesToCheck = [];
          const currentTierFields = tier?.tiers[currentTierCode]?.fields
          currentTierFields?.forEach(field => {
            const data = (field?.storage_data_key) ? JSON.parse(field.storage_data_key) : null;
            if (notUndefinedNull(data?.editing) && data?.editing[0]?.type === 'check') {
              namesToCheck.push(field.unique_name + '__id__' + field.id);
            }
          });
          namesToCheck.forEach(name => {
            const [group] = name.split('__');
            let groupFields = currentTierFields.filter(field => field.unique_name.includes(`${group}__`));
            groupFields = groupFields.map(field => `${field.unique_name}__id__${field.id}`);
            let groupFieldsValues = [];
            groupFields.forEach(groupField => {
              groupFieldsValues.push(getValues(groupField));
            });
            const areAllEmpty = !groupFieldsValues.some(groupFieldsValue => {
              return (
                groupFieldsValue &&
                groupFieldsValue !== hiddenCode &&
                groupFieldsValue !== hiddenField &&
                groupFieldsValue !== hiddenNumber &&
                groupFieldsValue !== sOption &&
                notUndefinedNull(groupFieldsValue)
              );
            });
            if (areAllEmpty) {
              groupFields.forEach(groupField => {
                setValue(groupField, hiddenField);
              });
            }
          });

        }
      }, 1000);

      setTimeout(() => {
        fields.filter(field => JSON.parse(field?.action)?.type === 'hide')
          // We will also check for the condition of the hide/show fields (those with an action.type === 'hide')
          .forEach(field => {
          const [parent] = fields.filter(innerField => innerField.id === field.parent)
          const parentElement = fieldUniqueName(parent.unique_name)
          const resultCondition = getCondition(
            JSON.parse(field.parent_condition),
            parentElement,
            field.data_type
          )
          if(resultCondition.isCondition){
            let uniqueName = `${field.unique_name}__id__${field.id}`
            setValue(uniqueName, hiddenField)
          }
        })
      }, 2000)

      setTimeout(() => {
        dispatch(setLoader(false));
      }, 2000);
    }
  }, [action, parents]);

  useEffect(() => {
    // Calling parentConditionsEdit, after a delay
    if(action === paramsEdit || action === paramsAdd){
      setTimeout(() => {
        if (parents.length > 0) {
          Array.from([...parents]).forEach(item => {
            const [field] = Array.from(tierFieldState).filter(
              elem => trimLowerCase(elem.id) === trimLowerCase(item));
            const data = (field?.storage_data_key) ? JSON.parse(
              field.storage_data_key) : null;
            if (field?.unique_name && data?.show !== 'false') {
              const uniqueName = `${field.unique_name}__id__${field.id}`;
              const element = fieldAttribute('name', uniqueName);
              if (element) {
                const value = trimLowerCase(getValues(element.name));
                if (notUndefinedNull(value) && value !== hiddenField && value !== hiddenNumber && value !== hiddenCode && field) {
                  parentConditionEdit(element, field, methods);
                }
              }
            }
          });

        }
      }, timeForParentCondition);
    }
  }, [action, parents])

};

/**
 * Use to get info current and last tiers
 * @returns
 */
export const useInfoTiers = () => {
  const { requestLanguage } = useSelector(state => state.base);
  const { action, lastTier, nextTier, tier } = useSelector(state => state.tier);
  const [infoTier, setInfoTier] = useState({});
  const { tier: tiers } = useSelector(
    state => state.staticData[`data_${trimLowerCase(requestLanguage)}`]);

  useEffect(() => {
    let index;
    if (action === paramsAdd) {
      index = tiers?.findIndex(elem => parseInt(
        elem.verification_level) === (parseInt(nextTier?.verification_level) + 1));
    } else {
      index = tiers?.findIndex(elem => parseInt(
        elem.verification_level) === (parseInt(tier?.verification_level) + 1));
    }
    setInfoTier(tiers[index]);
  }, [tiers]);

  return { nextTier: infoTier, lastTier };
};

/**
 * use to get categories and fields for tier
 */
export const useTiers = () => {
  const dispatch = useDispatch();
  const { requestLanguage } = useSelector(state => state.base);
  const {
    field_category: categories,
    tier: allTiers,
    tier_field: tiersFields,
    field: fields
  } = useSelector(state => state.staticData[`data_${trimLowerCase(
    requestLanguage)}`]);
  const { tiers } = useSelector(state => state.tier);

  useEffect(() => {
    if (isUndefinedNull(tiers)) {
      const response = {};
      Array.from(allTiers).forEach(item => {
        if (item.regular && item.active) {
          const uniqueName = trimLowerCase(item.unique_name);
          const tierCategoriesFields = setCategoriesTierFields(
            categories, item, tiersFields);
          const tierFields = setTierFields(fields, item, tiersFields);
          const fieldsCategories = setFieldsCategories(
            tierCategoriesFields, tierFields, item);
          response[uniqueName] = {};
          response[uniqueName]['fields'] = tierFields;
          response[uniqueName]['data'] = item;
          response[uniqueName]['categories'] = {};
          response[uniqueName]['categories']['data'] = tierCategoriesFields;
          response[uniqueName]['categories']['fields'] = fieldsCategories;
        }
      });
      dispatch({ type: TIER_SET_TIERS, payload: response });
    }
  }, [tiers, allTiers, categories, tiersFields, fields]);

  useEffect(() => {
    dispatch({ type: TIER_CLEAR_CATEGORY });
    dispatch({ type: USER_FILES, payload: { files: null } });
  }, []);
};

/**
 * use to Category tier
 * @param {Object} element
 * @returns
 */
export const useCategory = element => {
  const dispatch = useDispatch();
  const { action, code, tiers } = useSelector(state => state.tier);
  const { data } = useSelector(state => state.personData);
  const { dataProductRequest } = useSelector(state => state.user);
  const { getValues } = useFormContext();
  const [categoryFields, setCategoryFields] = useState([]);
  const [tierFieldsEdit, setTierFieldEdit] = useState(null);
  const values = getValues();

  useEffect(() => {
    if (!dataProductRequest) dispatch(getProductRequest(data.id));
  }, []);

  useEffect(() => {
    const dataTier = tiers[trimLowerCase(code)];
    let categories = [];
    let boolStatus = false;

    if (dataProductRequest) {
      boolStatus = dataProductRequest.some(
        item => item.status.unique_description === statusRequest.sent);
      if (boolStatus) {
        categories = dataTier.categories.fields[element.unique_name].map(cat => {
          return {
            ...cat,
            editable_after_migrate: false
          };
        });
      }
    }
    setCategoryFields(boolStatus ? categories : dataTier.categories.fields[element.unique_name]);
    dispatch({
      type: TIER_CATEGORY,
      payload: boolStatus ? categories : dataTier.categories.fields[element.unique_name]
    });
  }, [code, tiers, element, dataProductRequest]);

  useEffect(() => {
    if (action === paramsEdit && isUndefinedNull(tierFieldsEdit) && Object.keys(
      values).length > 0) {
      dispatch({ type: TIER_VALUES_EDIT, payload: values });
      setTierFieldEdit(values);
    }
  }, [action, values]);

  return { categoryFields };
};