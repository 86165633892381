import { additionalPhonesCategory, beneficiaryCategory } from '../constants';
import { useSelector } from 'react-redux';
import { fieldUniqueName, getUniqueName } from '../helpers';
import { useFormContext } from 'react-hook-form';
import { parentCondition } from '../helpers';
import { hiddenField } from '../constants';

/**
 * @component
 * @param {Function} setEntries
 * @param {Number}   entryNumber
 * @param {String}   field_category
 * @param {Function} setCanAddPhone
 * @returns
 */
export const useRemoveEntry = (setEntries, entryNumber, category, setCanAddPhone) => {
  const { setValue, getValues } = useFormContext();
  const tiers = useSelector(state => state.tier.tiers)
  let fields
  if(category === beneficiaryCategory) {
    fields = tiers.advanced.categories.fields[category]
  } else { // Aditional phones
    fields = tiers.basic.categories.fields[category]
  }

  /* Function for removing both; Beneficiaries and additional phones */
  const handleRemove = id => {
    /* Resetting values on RHF when a block is deleted */
    const currentFields = fields.filter(field => field.unique_name.includes(
      entryNumber) && !field.unique_name.includes('button'));
    const bothFields = {
      domElements: {},
      adminFields: {}
    };

    currentFields.forEach(currentField => {
      let field = fieldUniqueName(currentField.unique_name);
      if (field) {
        bothFields.domElements[`${field.dataset.uniqueName}__id__${field.id}`] = field;
        bothFields.adminFields[`${field.dataset.uniqueName}__id__${field.id}`] = currentField;
      }
    });

    const domFieldsNames = Object.keys(bothFields.domElements);
    domFieldsNames.forEach(domFieldName => {
      const domField = bothFields.domElements[domFieldName];
      const adminField = bothFields.adminFields[domFieldName];

      if (category === additionalPhonesCategory) {

        // In order to detect **only** phone_number and phone_number_country, but not similar named fields. (and hide them, if required)
        const phoneNumberRegex = /^block\d+__user_phone_number\d+.*$/
        const matchesPhoneNumber = phoneNumberRegex.test(domFieldName)
        const phoneCountryRegex = /^block\d+__user_phone_number_country\d+.*$/
        const matchesCountryNumber = phoneCountryRegex.test(domFieldName)
        if (matchesPhoneNumber || matchesCountryNumber) {
          setValue(domFieldName, hiddenField);
        } else {
          setValue(domFieldName, '');
        }
      }

      if (category === beneficiaryCategory) {
        if (domFieldName.includes('code')) {
          setValue(domFieldName, hiddenField);
        } else {
          setValue(domFieldName, '');
          parentCondition(domField, adminField, setValue);
        }
      }

    });

    // If all remaining 'main' fields are false, set the first one to 'true'
    if(category === additionalPhonesCategory){
      // WIP
      setCanAddPhone(true)
      const remainingMainFields = fields.filter(field => !field.unique_name.includes(entryNumber) && !field.unique_name.includes('button') && field.unique_name.includes('main'))
      const areAllFalse = !remainingMainFields.some(mainField => {
        const uniqueName = mainField.unique_name + '__id__' + mainField.id
        const value = getValues(uniqueName)
        return value === true
      })
      if(areAllFalse){
        const firstMain = remainingMainFields[0]
        const firstMainUniqueName = firstMain.unique_name + '__id__' + firstMain.id
        setValue(firstMainUniqueName, true)
      }
    }
 
    /* Removing entry */
    setEntries(prevEntries => {
      const newEntries = prevEntries.slice().filter(entry => entry.id !== id);
      return newEntries;
    });
  };

  return { handleRemove };
};