import { appsOrigin, envSetting } from '../../constants';
import {
  USER_APP_ORIGIN,
  USER_APPLICATIONS,
  USER_CLEAR,
  USER_CODE_APP,
  USER_FILES,
  USER_LOGIN,
  USER_LOGOUT,
  USER_NAVIGATE,
  USER_PROFILE,
  USER_SAVE_REQUEST_TOKEN,
  USER_SET_HELP_SECTION_OPEN,
  USER_SET_PASSWORD,
  USER_VALIDATED_PHONE,
  USER_VERIFICATION,
  USER_DATA_SUMMARY,
  USER_SELECTED_PRODUCT,
  USER_INFO_PRODUCT_REQUEST,
  USER_TIME_REFRESH,
  USER_PRODUCT_REQUEST,
  USER_CURRENTLY_VALIDATING_PHONE
} from '../types';

const initState = {
  applications: null,
  appOrigin: appsOrigin.default,
  code: '',
  dataSummary: null,
  dataProductRequest: null,
  files: null,
  isAuthenticated: false,
  isHelpOpen: true,
  isNavigate: false,
  isPassword: false,
  isValidatedPhone: false,
  infoProductRequest: null,
  lastProductRequestId: null,
  firstProductRequestId: null,
  level: 0,
  moduleProfile: '',
  requestToken: '',
  route: '',
  srcAvatarImage: null,
  selectedProduct: {
    image_ads:  envSetting.logoAdsWisenroll,
    image_main: envSetting.bgLogoWisenroll
  },
  timeRefresh: null,
  token: '',
  userIdBifrost: null,
  username: null,
  currentlyValidatingPhone: null
};

/**
 * Reducer to user
 * @param {Object} state
 * @param {Object} action
 * @returns
 */
export const userReducer = (state = initState, action) => {
  switch (action.type) {
    case USER_CURRENTLY_VALIDATING_PHONE:
      return {
        ...state,
        currentlyValidatingPhone: {
          submitting: action.payload.submitting,
          phone: action.payload.phone
        }
      };

    case USER_APP_ORIGIN:
      return {
        ...state,
        appOrigin: action.payload,
      };

    case USER_APPLICATIONS:
      return {
        ...state,
        applications: action.payload,
      };

    case USER_CLEAR:
      return initState;

    case USER_CODE_APP:
      return {
        ...state,
        code: action.payload,
      };

    case USER_FILES:
      return {
        ...state,
        ...action.payload,
      };

    case USER_LOGIN:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
      };

    case USER_LOGOUT:
      return {
        ...state,
        appOrigin: appsOrigin.default,
        isAuthenticated: false,
      };

    case USER_NAVIGATE:
      return {
        ...state,
        ...action.payload
      };

    case USER_PROFILE:
      return {
        ...state,
        moduleProfile: action.payload,
      };

    case USER_SAVE_REQUEST_TOKEN:
      return {
        ...state,
        requestToken: action.payload,
      };

    case USER_SET_HELP_SECTION_OPEN:
      return {
        ...state,
        isHelpOpen: action.payload,
      };

    case USER_SET_PASSWORD:
      return {
        ...state,
        isPassword: action.payload,
      };

    case USER_TIME_REFRESH:
      return {
        ...state,
        timeRefresh: action.payload,
      };

    case USER_VALIDATED_PHONE:
      return {
        ...state,
        isValidatedPhone: action.payload,
      };

    case USER_VERIFICATION:
      return {
        ...state,
        level: action.payload,
      };

    case USER_DATA_SUMMARY:
      return {
        ...state,
        dataSummary: action.payload,
      };

    case USER_SELECTED_PRODUCT:
      return {
        ...state,
        selectedProduct: action.payload,
      };

    case USER_INFO_PRODUCT_REQUEST:
      return {
        ...state,
        infoProductRequest: action.payload,
      };

    case USER_PRODUCT_REQUEST:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};