import React from 'react'
import { FormControlLabel, Switch } from '@mui/material'
import i18n from '../../../../../translations/i18n'


/**
 * Switch component for the profile, when editing additional phones.
 * @param {{ phone: any; }} props
 * @param {Object} props.entry
 * @returns {React.ReactElement}
 */
const ProfileSwitch = ({ entry, setEntries, isDisabled = false, entries, setIsTouched }) => {

  const handleSwitchMain = () => {
    if (entry?.main) {
      return
    } else {
      setEntries(prevEntries => {
        return prevEntries.map(prevEntry => {
          if (prevEntry.key === entry.key){
            return {
              ...prevEntry,
              main: !prevEntry.main
            }
          } else {
            return {
              ...prevEntry,
              main: false
            }
          }
        
        })
      })
      setIsTouched(true)
    }
  }

  return (
    <FormControlLabel 
      className='col-12 col-xl-6 mx-0'
      control={<Switch 
        onChange={!isDisabled ? handleSwitchMain : null}
        checked={entry?.main === true}
      />}
      label={<small><strong>{i18n.t('profile.mainPhoneNumber')}</strong></small>}
    />
  )
}

export default ProfileSwitch