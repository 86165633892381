import React, { useState } from 'react';
import { useInformation } from '../../../hooks';
import { useTranslation } from 'react-i18next';
import { CardModal } from './CardModal';
import { Col, Row } from 'react-bootstrap';
import { Section } from './Section';
import { theme } from '../../../theme';
import { profile } from '../../../constants';
import { useProfileBlock } from '../../../hooks';

/**
 * Component with information to profile
 * @component
 * @returns
 */
export const Information = () => {
  const { t } = useTranslation();
  const { email, general, phone } = useInformation();
  const { first, third, fourth } = theme;
  const [requestEdit, setRequestEdit] = useState(false);
  const [requestTokenForce, setRequestTokenForce] = useState(false);
  const { 
    editRequest, isEditing, cancelEditRequest,
    setIsEditing, isOpenValidateSms, setIsOpenValidateSms,
    entries, setEntries, phones
  } = useProfileBlock(setRequestEdit, profile.cardPhone, phone)

  const [isTouched, setIsTouched] = React.useState(false)
  /* Make sure that there's always at least 1 main */
  React.useEffect(() => {
    let main = entries?.some(entry => entry.main)
    if (!main && entries?.length > 0) {
      setEntries(prevEntries => {
        const newEntries = [...prevEntries]
        newEntries[0].main = true
        return newEntries
      })
    }
    if (entries?.length !== phones?.length) setIsTouched(true)
  }, [entries, phones?.length])

  return (
    <Row className="mb-5 border border-primary">
      {(general.length > 0) && (
        <Col xs={12}>
          <Section
            codeCard={ profile.cardGeneral }
            color={ first }
            data={ general }
            title={ t("profile.cardGeneral") }
          />
        </Col>
      )}
      {(email.length > 0) && (
        <Col xs={12}>
          <Section
            codeCard={ profile.cardEmail }
            color={ fourth }
            data={ email }
            setRequestEdit={ setRequestEdit }
            setRequestTokenForce={ setRequestTokenForce }
            title={ t("profile.cardEmail") }
          />
        </Col>
      )}
      {(phone.length > 0) && (
        <Col xs={12}>
          <Section
            codeCard={ profile.cardPhone }
            color={ third }
            data={ phone }
            setRequestEdit={ setRequestEdit }
            setRequestTokenForce={ setRequestTokenForce }
            title={ t("profile.cardPhone") }
            isEditing={isEditing}
            cancelEditRequest={cancelEditRequest}
            editRequest={editRequest}
            setIsOpenValidateSms={setIsOpenValidateSms}
            phones={phones}
            setEntries={setEntries}
            entries={entries}
            setIsTouched={setIsTouched}
            isTouched={isTouched}
          />
        </Col>
      )}
      {(email.length > 0 || phone.length > 0) && (
        <>
          {/* Modal for requesting change */}
          <CardModal
            codeModal="requestEdit"
            modalOpen={ requestEdit }
            setModalOpen={ setRequestEdit }
            setIsEditing={setIsEditing}
          />
          {/* Modal for confirming change */}
          <CardModal
            codeModal="requestTokenForce"
            modalOpen={ requestTokenForce }
            setModalOpen={ setRequestTokenForce }
          />
          {/* Modal for validating SMS Tokens in Profile */}
          <CardModal 
            codeModal={profile.validateOne}
            modalOpen={isOpenValidateSms}
            setModalOpen={setIsOpenValidateSms}
            setEntries={setEntries}
            setIsTouched={setIsTouched}
          />
        </>
      )}
    </Row>
  );
};