import React from 'react';
import PropTypes from 'prop-types';
import SignatureCanvas from 'react-signature-canvas';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Col } from 'react-bootstrap';
import {
  FileButton,
  FileInputContainer,
  SignatureClearButton
} from '../../user/styled';
import {
  useEdit,
  useEditFiles,
  useSignature,
  useTextLanguage
} from '../../../hooks';
import { Error, Label, TooltipIcon } from '../../shared/simple';

/**
 * Component text input to signature
 * @component
 * @param {Object} field
 * @returns
 */
export const SignatureInput = ({ field }) => {
  const uniqueName = `${field.unique_name || field.file_name}__id__${field.id}`;
  const { description, languageText } = useTextLanguage();
  const { formState: { errors }, setValue } = useFormContext();
  const { t } = useTranslation();
  const { clear, dataBase, handleEnd, signature } = useSignature(
    setValue, uniqueName);
  const { colSize } = useEdit(field);
  useEditFiles(field, null, clear);

  return (
    <>
      {field && (
        <>
          {(colSize?.includes('col-before')) && (
            <Col xs={12} key={`col_before_${field.id}`}></Col>
          )}
          <Col
            className={ `mt-4 ${colSize}` }
            data-module={ `module-${field?.category?.field_category}` }
            data-name={ uniqueName }
            id={ `group_field_${field.id}` }
            key={ field.id }
            xs={12}
          >
            <FileInputContainer
              data-media={ dataBase }
              data-media-name={ uniqueName }
            >
              <Label htmlFor={ field.name || field.id }>
                { field[description] }{ field.required && (
                  <strong className="text-danger">&nbsp;*</strong>)}{ field?.[
                  `tooltip${languageText}`] && (<TooltipIcon tooltipText={ field[
                  `tooltip${languageText}`] } />) }
              </Label>
              <FileButton>
                <SignatureCanvas
                  canvasProps={{
                    width: 300,
                    height: 200,
                  }}
                  onEnd={ handleEnd }
                  name={ uniqueName }
                  penColor='black'
                  ref={ signature }
                />
                <SignatureClearButton type="button" onClick={ clear} >
                  { t("fileInput.clear") }
                </SignatureClearButton>
              </FileButton>
              {errors?.[uniqueName]?.message && (
                <Error message={ errors[uniqueName].message } />
              )}
            </FileInputContainer>
          </Col>
        </>
      )}
    </>
  );
};

SignatureInput.propTypes = {
  field: PropTypes.object.isRequired
};