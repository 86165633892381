import React from 'react';
import PropTypes from 'prop-types';
import { timeMinutes } from '../../helpers';
import { useChronometer } from '../../hooks';

/**
 * Chronometer component
 * @component
 * @param {Object} expiryTimestamp
 * @param {Function} onExpired
 * @param {String} numberOfMinutes
 * @returns
 */
const Chronometer = ({ expiryTimestamp, onExpired, numberOfMinutes }) => {
  const { seconds, minutes, resetTime } = useChronometer(
    expiryTimestamp, onExpired, numberOfMinutes);

  return (
    <div style={{ textAlign: 'center' }}>
      <div style={{ fontSize: '50px' }}>
        <span>{ minutes }</span>:
        <span>
          { seconds.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          }) }
        </span>
        <button
          className="d-none"
          id="reset_timer"
          onClick={ resetTime }
          type="button"
          name="btn_reset_time"
        ></button>
      </div>
    </div>
  );
};

/**
 * Timer component
 * @component
 * @param {String} numberOfMinutes
 * @param {Function} onExpired
 * @returns
 */
export const Timer = ({ numberOfMinutes=5, onExpired }) => {
  const time = timeMinutes(numberOfMinutes);

  return (
    <>
      <Chronometer
        expiryTimestamp={ time }
        onExpired={ onExpired }
        numberOfMinutes={ Number(numberOfMinutes) }
      />
    </>
  );
};

Chronometer.propTypes = {
  expiryTimestamp: PropTypes.object,
  onExpired: PropTypes.func,
  numberOfMinutes: PropTypes.number
};

Timer.propTypes = {
  numberOfMinutes: PropTypes.number,
  onExpired: PropTypes.func
};