import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useFormContext } from 'react-hook-form';
import {
  defaultValuesToken,
  envSetting,
  hiddenField,
  paramsNext,
  profile,
  routes,
  schemaToken,
  siteMap
} from '../constants';
import {
  getValidateSms,
  tierMigratable,
  trimLowerCase,
  validateRequestType, 
  notUndefinedNull
} from '../helpers';
import {
  getPersonData,
  sendEditionToken,
  sendEmailToken,
  sendSmsToken,
  setLoader,
  setLogout,
  setMessage,
  setDelay,
  smsToken,
  validateEditionToken,
  validateEmailToken,
  validateSmsToken,
  migrateBifrost,
  updatePersonData,
  sendCheckUserTier,
  getRejectedFiles,
  setNavigate,
  personData,
  setTier
} from '../redux/actions';
import { TIER_CATEGORY, USER_VALIDATED_PHONE } from '../redux/types';
import IdleTimer from '../helpers/IdleTimer';

/**
 * Hook to get the values to CardModal
 * @param {String} codeModal
 * @param {Object} setModalOpen
 * @returns
 */
export const useTokenProfile = (codeModal, setModalOpen, setIsEditing = null, setEntries = null, setIsTouched = null) => {
  const dispatch = useDispatch();
  const minutesToken = envSetting.minutesToken;
  const { t } = useTranslation();
  const { moduleProfile, userIdBifrost, lastProductRequestId, currentlyValidatingPhone } = useSelector(state => state.user);
  const { loading } = useSelector(state => state.base);
  const { register, formState: { errors }, handleSubmit, reset } = useForm({
    resolver: yupResolver(schemaToken),
    defaultValues: defaultValuesToken
  });
  const [message, setMessageToken] = useState('');

  useEffect(() => {
    if (codeModal === profile.requestEdit) {
      setMessageToken(t("profile.requestEdit"));
    }
    if (codeModal === profile.requestTokenForce || codeModal === profile.validateOne) {
      if (moduleProfile === profile.cardPhone) {
        setMessageToken(t("profile.requestTokenForcePhone"));
      }
      if (moduleProfile === profile.cardEmail) {
        setMessageToken(t("profile.requestTokenForceEmail"));
      }
    }
  }, [codeModal, moduleProfile]);

  const messageExpired = () => {
    setModalOpen(false);
    dispatch(setMessage(t("common.tokenExpired"), 'error'));
  };

  const resendToken = () => {
    reset();
    if (codeModal === profile.requestEdit) {
      dispatch(setLoader(true));
      dispatch(sendEditionToken({
        'bifrost_user_id': userIdBifrost
      }))
        .then(response => (response) && document.getElementById(
          profile.resetTimer).click())
        .catch(error => console.error('resendToken in useTokenProfile: ', error));
      dispatch(setLoader(false));
    }

    if (codeModal === profile.requestTokenForce) {
      if (moduleProfile === profile.cardPhone) {
        dispatch(setLoader(true));
        const phoneNumber = document.querySelector(
          `[name="${profile.phoneNumber}"]`).value;
        const phoneNumberCountry = document.querySelector(
          `[name="${profile.phoneNumberCountry}"]`).value;
        dispatch(smsToken(phoneNumberCountry, phoneNumber, userIdBifrost))
          .then(response => (response) && document.getElementById(
            profile.resetTimer).click())
          .catch(error => console.error('resendToken in useTokenProfile: ', error));
        dispatch(setLoader(false));
      }
      if (moduleProfile === profile.cardEmail) {
        dispatch(setLoader(true));
        const email = document.querySelector(`[name="${profile.email}"]`).value;
        dispatch(sendEmailToken({
          email,
          bifrost_user_id: userIdBifrost
        }))
          .then(response => (response) && document.getElementById(
            profile.resetTimer).click())
          .catch(error => console.error('resendToken in useTokenProfile: ', error));
        dispatch(setLoader(false));
      }
    }

    if (codeModal === profile.validateOne) {
      const phone = currentlyValidatingPhone.phone
      let phoneNumber = phone.replaceAll('-', '').replaceAll('_', '')
      dispatch(sendSmsToken({
        'phone_number': phoneNumber,
        'country_code': phone?.country_code,
        'bifrost_user_id': userIdBifrost,
        'main': phone?.main
      }))
        .then(response => (response) && document.getElementById(
          profile.resetTimer).click())
        .catch(error => console.error('resendToken in useTokenProfile: ', error))
    }
  };

  const handleFields = valid => {
    let moduleForm, editModule, editModulePhones;

    if (moduleProfile === profile.cardEmail) {
      moduleForm = document.getElementById(profile.moduleFormEmail);
      editModule = document.getElementById(profile.editModuleEmail);
    }
    if (moduleProfile === profile.cardPhone) {
      moduleForm = document.getElementById(profile.moduleFormPhone);
      editModule = document.getElementById(profile.editModulePhone);
      editModulePhones = document.getElementById(profile.editModulePhones)
    }

    if (valid) {
      if (codeModal === profile.requestEdit) {
        if (moduleProfile === profile.cardPhone) editModulePhones.classList.add('d-none')
        editModule.classList.add('d-none');
        moduleForm.classList.remove('d-none');
      }
      if (codeModal === profile.requestTokenForce) {
        moduleForm.classList.add('d-none');
        editModule.classList.remove('d-none');
        if (moduleProfile === profile.cardPhone) editModulePhones.classList.remove('d-none')
        dispatch(getPersonData(userIdBifrost, lastProductRequestId));
      }
      setModalOpen(false);
    } else {
      if (codeModal === profile.requestEdit) {
        moduleForm.classList.add('d-none');
        editModule.classList.remove('d-none');
        if (moduleProfile === profile.cardPhone) editModulePhones.classList.remove('d-none')
      }
      if (codeModal === profile.requestTokenForce) {
        if (moduleProfile === profile.cardPhone) editModulePhones.classList.add('d-none')
        editModule.classList.add('d-none');
        moduleForm.classList.remove('d-none');
      }
    }
  };

  const onSubmit = async data => {
    if (codeModal === profile.requestEdit) {
      dispatch(setLoader(true));
      reset();
      dispatch(validateEditionToken({
        'token': data.token,
        'bifrost_user_id': userIdBifrost
      }))
      // * IMPORTANT
        .then(({ error }) => {
          if (setIsEditing && !error && (moduleProfile === profile.cardPhone)) setIsEditing(true)
          handleFields(!error) 
        })
        .catch(error => handleFields(error));
      dispatch(setLoader(false));
    }
    if (codeModal === profile.requestTokenForce) {
      reset();
      dispatch(setLoader(true));
      if (moduleProfile === profile.cardPhone) {
        dispatch(validateSmsToken({
          'token': data.token,
          'bifrost_user_id': userIdBifrost
        }))
          .then(({ error }) => handleFields(!error))
          .catch(error => handleFields(error));
      }
      if (moduleProfile === profile.cardEmail) {
        dispatch(validateEmailToken({
          'token': data.token,
          'bifrost_user_id': userIdBifrost
        }))
          .then(({ error }) => handleFields(!error))
          .catch(error => handleFields(error));
      }
      dispatch(setLoader(false));
    }
    //* When validating an individual phone number in Profile
    if (codeModal === profile.validateOne) {
      reset()
      dispatch(setLoader(true))
      const phone = currentlyValidatingPhone.phone
      let phoneNumber = phone?.description?.replaceAll('-', '')?.replaceAll('_', '')
      const response = await dispatch(validateSmsToken({
        'bifrost_user_id': userIdBifrost,
        'country_code': phone?.country_code,
        'phone_number': phoneNumber,
        'main': phone?.main,
        'token': data?.token
      }));
      if(response.errors) {
        dispatch(setLoader(false));
        return
      } else{
        setModalOpen(false);
        setIsTouched(true);
        setEntries(prevEntries => {
          return prevEntries.map(entry => {
            if (entry?.key === currentlyValidatingPhone.phone?.key) {
              return {
                ...entry,
                validated: true
              }
            } else return entry
          })
        })
        if(currentlyValidatingPhone?.submitting){
          //* If we were submitting, before validating, continue submitting
          dispatch(setLoader(false))
          await new Promise(resolve => setTimeout(resolve, 333))
          const submitBtn = document.getElementById('btn_save_additional_phones') 
          if (submitBtn) submitBtn.click()
        }
      }
      dispatch(setLoader(false))
    }
  };

  return {
    errors,
    handleSubmit,
    loading,
    message,
    messageExpired,
    minutesToken,
    onSubmit,
    register,
    resendToken
  };
};

/**
 * Token to tier
 * @param {Boolean}  openModal
 * @param {Object}   tier
 * @param {Object}   tierFieldState
 * @param {Function} setOpenModal
 * @returns
 */
export const useTokenTier = (openModal, tier, tierFieldState, setOpenModal, additionalPhones, setAdditionalPhones) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getValues, setValue } = useFormContext();
  const { t } = useTranslation();
  const { requestLanguage, location } = useSelector(state => state.base);
  const { nextTier } = useSelector(state => state.tier);
  const { field: fields, country: countries } = useSelector(state => state.staticData[
    `data_${trimLowerCase(requestLanguage)}`]);
  const { data: { id: userId } } = useSelector(state => state.personData);
  const { isValidatedPhone, lastProductRequestId, userIdBifrost } = useSelector(state => state.user);
  const [loading, setLoading] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const tokenSms = fields.filter(item =>
    trimLowerCase(item.unique_name) === 'modal__token').pop();
  const [mainValidated, setMainValidated] = useState(false)
  const [mainPhoneNumber, setMainPhoneNumber] = useState(null)

  const postPersonData = async () => {
    dispatch({ type: USER_VALIDATED_PHONE, payload: true });
    const values = getValues()
    const formObject = validateRequestType(values, tierFieldState, userId);
    const migratable = tierMigratable(formObject.migratable, tier, false);
    if (formObject.isElement || formObject.isMedia) {
      dispatch(setLoader(true));
      formObject.elements['country_code'] = location;
      const response = await dispatch(personData(
        formObject, userIdBifrost, lastProductRequestId));
      if (response && !response.error) {
        if (nextTier?.verification_level) {
          if (tier.verification_level > nextTier.verification_level) dispatch(
            setTier(tier));
        } else {
          dispatch(setTier(tier));
        }
        let result = await dispatch(migrateBifrost(
          userIdBifrost, migratable, userId, lastProductRequestId));
        if (result && !result.error) {
          if (await dispatch(updatePersonData(userIdBifrost, lastProductRequestId))) {
            await dispatch(sendCheckUserTier());
            dispatch(getRejectedFiles());
            dispatch(setLoader(false));
            dispatch(setDelay(false));
            setLoading(false);
            setShowTimer(false);
            navigate(routes.completed(paramsNext));
          }
        } else {
          dispatch(setNavigate(false));
          dispatch(setLoader(false));
          dispatch(setDelay(false));
        }
      } else {
        if (!nextTier?.verification_level) dispatch(setTier([]));
        dispatch(setLoader(false));
        dispatch(setDelay(false));
      }
    } else {
      navigate(routes.completed(paramsNext));
    } 
  }

  /* useEffect in charge of iteratingand validating the additional phones */
  useEffect(() => {

    let values = getValues();
    const nameToken = Array.from(Object.keys(values)).filter(
      elem => elem.includes('token')).pop();
    if(nameToken) setValue(nameToken, '')

    const sendNewAdditionalPhoneToken = async () => {
      setShowTimer(true);
      if(additionalPhones?.length){
        try {
          let currentPhoneToValidate = additionalPhones?.[0]
          const [uniqueName, phoneNumber] = currentPhoneToValidate
          const values = getValues()
          // In order to make the validation call, we must find the corresponding country code
          const entryNumber = uniqueName.split('__id')[0].match(/\d+/)[0]
          const countryCode = Object.keys(values).filter(value => value.includes(`country${entryNumber}`))
          const countryId = values[countryCode]
          const country = countries.filter(country => Number(country.country_id) === Number(countryId))[0]
          let phone = phoneNumber.replaceAll('-', '').replaceAll('_', '')
          const response = await dispatch(sendSmsToken({
            'phone_number': phone,
            'country_id': countryId,
            'bifrost_user_id': userIdBifrost
          }));
          if (response && !response.error) {
            setShowTimer(true)
            setLoading(false)
            dispatch(setLoader(false))
          } else {
            setModalIsOpen(false)
            setShowTimer(false)
            setLoading(false)
            dispatch(setLoader(false))
          } 
        } catch (error) {
          console.error(error)
          dispatch(setMessage(t("message.errorSendToken"), 'error'))
          setShowTimer(false)
          setLoading(false)
          dispatch(setLoader(false))
          dispatch(setDelay(false))
          setModalIsOpen(false)
        }
      } else if (notUndefinedNull(additionalPhones) && Object.entries(additionalPhones)?.length === 0){
        await postPersonData()
        setModalIsOpen(false)
        setOpenModal(1)
        setShowTimer(false)
        dispatch(setLoader(false))

      }
    }

    setTimeout(() => {
      if(additionalPhones?.length > 0) sendNewAdditionalPhoneToken()
    }, 1500);

  }, [additionalPhones])

  useEffect(() => {
    if (openModal > 1) setModalIsOpen(true);
  }, [openModal])

  /* Gets the value for the main phone number to display in the modal. */
  useEffect(() => {
    let values = getValues();
    const mainPhone = Object.keys(values)?.filter(elem => elem.includes('main'))?.find(mainPhone => values[mainPhone] === true)
    const mainPhoneEntry = mainPhone?.match(/\d+/)[0]
    const mainFields = Object.keys(values)?.filter(elem => elem.split('__id')[0].includes(mainPhoneEntry))
    const mainPhoneNumber = mainFields?.filter(elem => elem.split('__id')[0].includes(`number${mainPhoneEntry}`))[0]
    setMainPhoneNumber(values[mainPhoneNumber])
  }, [loading, getValues])

  const submitModal = async () => {
    try {
      dispatch(setLoader(true));
      let values = getValues();
      const nameToken = Array.from(Object.keys(values)).filter(
        elem => elem.includes('token')).pop();

      if (values[nameToken]) {
        setLoading(true);
        let response
        if(!mainValidated){
          const mainPhone = Object.keys(values).filter(elem => elem.includes('main'))?.find(mainPhone => values[mainPhone] === true)
          const mainPhoneEntry = mainPhone.match(/\d+/)[0]
          const mainFields = Object.keys(values).filter(elem => elem.split('__id')[0].includes(mainPhoneEntry))
          const mainPhoneNumber = mainFields.filter(elem => elem.split('__id')[0].includes(`number${mainPhoneEntry}`))[0]
          const mainCountry = mainFields.filter(elem => elem.split('__id')[0].includes('country'))[0]
          const countryId = values[mainCountry]
          const country = countries.filter(country => Number(country.country_id) === Number(countryId))[0]
          let phoneNumber = values[mainPhoneNumber].replaceAll('-', '').replaceAll('_', '')
          response = await dispatch(validateSmsToken({
            'token': values[nameToken],
            'bifrost_user_id': userIdBifrost,
            'phone_number': phoneNumber,
            'country_code': country?.code,
            'validated': true
          }));
          if (response.errors){
            dispatch(setLoader(false));
            setLoading(false);

            return
          }
        } else{
          const entryNumber = additionalPhones?.[0]?.[0].match(/\d+/)[0]
          const currentCountry = Object.keys(values).filter(name => name.split('__id')[0].includes(entryNumber) && name.split('__id')[0].includes('country'))
          const countryId = values[currentCountry]
          const country = countries.filter(country => Number(country.country_id) === Number(countryId))[0]
          let phoneNumber = additionalPhones[0][1].replaceAll('-', '').replaceAll('_', '')
          response = await dispatch(validateSmsToken({
            'token': values[nameToken],
            'bifrost_user_id': userIdBifrost,
            'country_code': country?.code,
            'phone_number': phoneNumber,
            'validated': true
          }));
        }
        if (!response?.errors) {
          let phonesToValidate = {}
          if (!mainValidated){
            const mainPhones = Object.keys(values).filter(elem => elem.includes('main'))
            const mainPhone = mainPhones?.find(mainPhone => values[mainPhone] === true)
            const mainPhoneEntry = mainPhone.match(/\d+/)[0]
            const validatedPhones = Object.keys(values).filter(elem => elem.includes('validated'))
            const validatedPhone = validatedPhones.filter(phone => phone.split('id')[0].includes(mainPhoneEntry))[0]
            setValue(validatedPhone, true)
            setShowTimer(true);
            // Getting all secondary phone names 
            phonesToValidate = Object.keys(values).filter(value => (value.split('__id')[0].match(/phone_number\d+/) && !value.split('__id')[0].includes(mainPhoneEntry)))
            // Getting those that actually hold a value (number to validate)
            phonesToValidate = Object.keys(values).filter(value => phonesToValidate.includes(value))
              .reduce((prevObj, key) => {
                if(values[key] && values[key] !== hiddenField) prevObj[key] = values[key]
                return prevObj
              }, {})
          } else {
            const entryNumber = additionalPhones[0][0].match(/\d+/)
            const currentValidated = Object.keys(values).filter(name => name.split('__id')[0].includes(entryNumber) && name.split('__id')[0].includes('validated'))[0]
            setValue(currentValidated, true)
          }
          if (!Object.keys(phonesToValidate)?.length && ((additionalPhones === null) || (additionalPhones?.length === 1))) {
            //* If there are no more cellphone to validate, then we POST
            await postPersonData()
          } else {
            // * If there are remaining phonesToValidate
            setOpenModal(openModal + 1)
            setValue(`${tokenSms.unique_name}__id__${tokenSms.id}`, '');
            if (!mainValidated) { 
              setAdditionalPhones(Object.entries(phonesToValidate))
              setMainValidated(true)
            } else { 
              setAdditionalPhones(prevPhones => {
                let copy = [...prevPhones]
                if(prevPhones?.length) copy?.shift()
                return copy
              })
            }
          }
        }
        setLoading(false);
        dispatch(setLoader(false));
        dispatch(setDelay(false));
      } else {
        dispatch(setMessage(t("common.mustToken"), 'error'));
        dispatch(setLoader(false));
        dispatch(setDelay(false));
      }
    } catch (error) {
      console.error('submitModal in useTokenTier: ', error);
      dispatch(setMessage(t("message.errorSubmitToken"), 'error'));
      setLoading(false);
      dispatch(setLoader(false));
      dispatch(setDelay(false));
      setModalIsOpen(false);
    }
    setShowTimer(false)
  }

  const sendToken = async () => {
    dispatch(setLoader(true));
    setLoading(true);
    setShowTimer(false);
    const values = getValues();
    try {
      if (!isValidatedPhone){
        if (!mainValidated) {
          const { phoneNumber, countryCode, countryId } = getValidateSms(values);
          if (phoneNumber && countryCode) {
            let phone = phoneNumber.replaceAll('-', '').replaceAll('_', '')
            const response = await dispatch(sendSmsToken({
              'phone_number': phone,
              'country_id': countryId,
              'bifrost_user_id': userIdBifrost
            }));
            if (response && !response.error) {
              dispatch({ type: TIER_CATEGORY, payload: [] });
              setShowTimer(true);
              setLoading(false);
              dispatch(setLoader(false));
            } else {
              setModalIsOpen(false);
              setShowTimer(false);
              setLoading(false);
              dispatch(setLoader(false));
            }
          } else {
            if (!phoneNumber) console.error('Error: Missing phone number')
            if (!countryCode) console.error('Error: Missing phone country code')  
            dispatch(setMessage(t("message.errorSendSmsToken"), 'error'));
            setLoading(false);
            dispatch(setLoader(false));
            setModalIsOpen(false);
          }
        } else { 
          if (additionalPhones?.length > 0) {
            let values = getValues()
            const entryNumber = additionalPhones[0][0].match(/\d+/)
            const currentCountry = Object.keys(values).filter(name => name.split('__id')[0].includes(entryNumber) && name.split('__id')[0].includes('country'))
            const countryId = values[currentCountry]
            const country = countries.filter(country => Number(country.country_id) === Number(countryId))[0]
            let phoneNumber = additionalPhones[0][1].replaceAll('-', '').replaceAll('_', '')
            try {
              await dispatch(sendSmsToken({
                'country_id': countryId,
                'phone_number': phoneNumber,
                'bifrost_user_id': userIdBifrost
              }));
              dispatch(setLoader(false));
              setShowTimer(true);
              setLoading(false)
            } catch (error) {
              console.error(error)
              dispatch(setMessage(t("message.errorSendSmsToken"), 'error'));
              dispatch(setLoader(false));
              setShowTimer(true);
              setLoading(false)
            }

          }
        }
      } else{
        await postPersonData()
      }

    } catch (error) {
      setShowTimer(false);
      setLoading(false);
      dispatch(setLoader(false));
      setModalIsOpen(false);
      dispatch(setMessage(t("message.errorSendSmsToken"), 'error'));
      console.error('sendToken in useTokenTier: ', error);
    }
  }

  const skipButton = async () => {
    let values = getValues();
    dispatch(setLoader(true));
    setLoading(true);
    setShowTimer(false);
    const entryNumber = additionalPhones[0][0].match(/\d+/)
    const currentCountry = Object.keys(values).filter(name => name.split('__id')[0].includes(entryNumber) && name.split('__id')[0].includes('country'))
    const countryId = values[currentCountry]
    const country = countries.filter(country => Number(country.country_id) === Number(countryId))[0]
    let phoneNumber = additionalPhones[0][1].replaceAll('-', '').replaceAll('_', '')
    try {
      await dispatch(validateSmsToken({
        'validated': false,
        'country_code': country?.code,
        'phone_number': phoneNumber,
        'bifrost_user_id': userIdBifrost
      }));
    } catch (error) {
      console.error(error)
      dispatch(setMessage(t("message.errorSubmitToken"), 'error'));
    }

    if(additionalPhones?.length > 1){
      setAdditionalPhones(prevPhones => {
        let copy = [...prevPhones]
        if(prevPhones?.length) copy?.shift()
        return copy
      })
    } else{
      await postPersonData()
    }
  }

  return {
    loading,
    modalIsOpen,
    sendToken,
    setModalIsOpen,
    showTimer,
    submitModal,
    tokenSms,
    skipButton,
    additionalPhones,
    mainValidated,
    mainPhoneNumber
  }
};

/**
 * Hook to auto logout
 */
export const useAutoLogout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (localStorage.getItem('_expiredTime')) localStorage.removeItem('_expiredTime');
    const timer = new IdleTimer({
      timeout: envSetting.expirationTime, // expire after amount of setted seconds
      onTimeout: () => {
        dispatch(setLogout());
        navigate(siteMap.login.path2);
        dispatch(setMessage(t("common.loginToken"), 'error'));
      },
      onExpired: () => {
        // do something if expired on load
        dispatch(setLogout());
        navigate(siteMap.login.path2);
        dispatch(setMessage(t("common.loginToken"), 'error'));
      },
      onExpiredSession: () => {
        // show modal 1 minute before session expiress
        dispatch(setMessage(t("common.sessionExpired"), 'warning', true));
      }
    });

    return () => {
      timer.cleanUp();
    };
  }, []);
};