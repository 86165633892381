import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDisabled, useEdit, useSelect, useTextLanguage } from '../../../hooks';
import { Col } from 'react-bootstrap';
import { Error, Label, OptionItem, TooltipIcon } from '../../shared/simple';
import { hiddenField, sOption } from '../../../constants';
import { StyledGroup } from '../../shared/Input/styled';
import { StyledSelect } from '../styled';

/**
 * Component select
 * @component
 * @param {Object} field
 * @returns
 */
export const Select = ({ field, entryProps }) => {
  const uniqueName = `${field.unique_name}__id__${field.id}`;
  const { description, languageText } = useTextLanguage();
  const { register, formState: { errors } } = useFormContext();
  const { t } = useTranslation();
  const { defaultValue, idValue, options, handleChange} = useSelect(field, uniqueName, entryProps);
  const { isDisabled } = useDisabled(field);
  const { colSize } = useEdit(field);

  return (
    <>
      {field && (
        <>
          {(colSize?.includes('col-before')) && (
            <Col xs={12} key={`col_before_${field.id}`}></Col>
          )}
          <Col
            className={ colSize }
            data-module={ `module-${field?.category?.field_category}` }
            data-name={ uniqueName }
            id={ `group_field_${field.id}` }
            key={ field.id }
            xs={12}
          >
            <StyledGroup className="mb-3">
              <Label htmlFor={ uniqueName }>
                { field[description] }{(field.required) && (
                  <strong className="text-danger">&nbsp;*</strong>)}
                {(field?.[`tooltip${languageText}`]) && (
                  <TooltipIcon tooltipText={ field[`tooltip${languageText}`] } />)}
              </Label>
              <StyledSelect
                { ...register(uniqueName) }
                data-action={ field.action }
                data-category={ `category-${field?.category?.field_category }`}
                data-condition={ field.parent_condition }
                data-parent={ field.parent }
                data-id-value={ idValue }
                data-unique-name={ field.unique_name }
                disabled={ isDisabled }
                id={ field.id }
                name={ uniqueName }
                onChange={ handleChange }
              >
                <option
                  value={sOption}
                  data-code={sOption}
                >
                    { t("common.optionDefault") }
                </option>
                <option
                  className="d-none"
                  id={ hiddenField }
                  value={ hiddenField }
                >
                </option>
                <OptionItem
                  defaultValue={ defaultValue }
                  languageText={ languageText }
                  options={ options }
                  type="tier"
                  field={field}
                />
              </StyledSelect>
              {errors?.[uniqueName]?.message && (
                <Error message={ errors[uniqueName].message } />
              )}
            </StyledGroup>
          </Col>
        </>
      )}
    </>
  );
};

Select.propTypes = {
  field: PropTypes.object.isRequired
};