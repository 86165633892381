import React from 'react'
import ProfileEntry from './ProfileEntry'
import { profile, sOption, statusRequest } from '../../../../../constants'
import { Col } from 'react-bootstrap'
import { Button } from '../../../../shared/simple'
import { LoaderSpinner } from '../../../../shared/simple'
import i18n from '../../../../../translations/i18n'
import { useSelector } from 'react-redux'
import { MdOutlineAdd } from "react-icons/md";
import { getNextEntryId, trimLowerCase } from '../../../../../helpers'
import { t } from 'i18next'
import { useDispatch } from 'react-redux'
import { USER_CURRENTLY_VALIDATING_PHONE } from '../../../../../redux/types'
import { saveAdditionalPhones, sendSmsToken, setLoader, setMessage , updateAdditionalPhones} from '../../../../../redux/actions'
import { ButtonCircle, Form } from './styled'

const ProfileBlock = ({ data, isEditing, cancelEditRequest: cancelRequest, setIsOpenValidateSms, entries, setEntries, isTouched, setIsTouched, phones }) => {
  const blockField = data?.[0]?.fields?.filter(field => field?.unique_name?.includes('block__'))[0]
  const maxLength = blockField.max_length
  const { loading, requestLanguage } = useSelector(state => state.base)
  const { 
    country: countries,
    contact_type
  } = useSelector(state => state.staticData[`data_${trimLowerCase(requestLanguage)}`]);
  const { id: selfNominId } = useSelector(state => state?.personData?.data )
  const { userIdBifrost, dataProductRequest } = useSelector(state => state?.user)
  const dispatch = useDispatch()

  /* Validation before allowing to create a new entry (or to save form) */
  let isEmpty = entries.some(entry => {
    return entry?.description?.length <= 6 || entry?.country_code === '' || entry?.country_code === sOption
  })

  const cancelEditRequest = () => {
    setEntries(phones.map((phone) => {
      return {
        key: phone.order,
        main: phone?.main,
        description: phone?.description,
        contact_type: phone?.contact_type,
        country_code: phone?.country_code,
        country_id: phone?.country_id,
        validated: phone?.validated
      }
    }))
    setIsTouched(false)
    cancelRequest()
  }

  const closeEditRequest = () => {
    setIsTouched(false)
    cancelRequest()
  }

  const removeEntry = (entry) => {
    if (entry?.validated) {
      if(window.confirm(t('block.confirmDelete'))){
        setEntries(prevEntries => {
          return prevEntries.filter(prevEntry => entry?.key !== prevEntry?.key)
        })
      }
    } else {
      setEntries(prevEntries => {
        return prevEntries.filter(prevEntry => entry?.key !== prevEntry?.key)
      })
    }
  }

  const addEntry = () => {
      const celularContactType = contact_type.filter(type => trimLowerCase(type.unique_description) === 'cellphone')[0]
      setEntries(prevEntries => {
        return [
          ...prevEntries,
          {
            key: getNextEntryId(entries, 'key'),
            main: false,
            description: '',
            contact_type: celularContactType?.contact_type_id,
            country_code: '',
            validated: false
          }
        ]
      })
  }

  const savePhones = async (data) => {
    dispatch(setLoader(true))
    const res = await dispatch(saveAdditionalPhones(data))
    if(res?.error){
      dispatch(setLoader(false))
      console.error(res.error)
      cancelEditRequest()
    } else {
      //* If a request was 'approved' (success) or 'rejected'.
      const isMigrated = dataProductRequest.some(product => product.status.unique_description === statusRequest.success 
        || product.status.unique_description === statusRequest.rejected)
      if (isMigrated){
        dispatch(updateAdditionalPhones(userIdBifrost))
      } 
      dispatch(setMessage(t('profile.saved'), 'success'))
      dispatch(setLoader(false))
      closeEditRequest()
    }
  }

  const onSubmit = async () => { 
    if (!isTouched) { 
      closeEditRequest()
    } else {
      const mainEntry = entries.filter(entry => entry?.main)[0]
      //* If the main entry is not validated 
      if (!mainEntry?.validated) {
        try {
          const response = await dispatch(sendSmsToken({
            'phone_number': mainEntry?.description,
            'country_id': mainEntry?.country_id,
            'bifrost_user_id': userIdBifrost,
            'main': mainEntry?.main
          }))
          if(!response.error){
            if (response.token_status === 'sent') { 
              setIsOpenValidateSms(true)
              dispatch({type: USER_CURRENTLY_VALIDATING_PHONE, payload: {
                submitting: true,
                phone: mainEntry
              }})
            }
            if (response.token_status === 'validated') {
              const data = entries.map(entry => { 
                const {key, country_id, country_code,  ...rest} = entry
                const country = countries.filter(country => trimLowerCase(country?.code) === trimLowerCase(country_id) || trimLowerCase(country?.code) === trimLowerCase(country_code))[0]
    
                return {
                  ...rest,
                  user: selfNominId,
                  country_code: country?.code,
                  country_id: country?.country_id,
                  validated: (entry?.id === mainEntry?.id) ? true : entry?.validated
                } 
              })
              savePhones(data)
            }
          }
        } catch (error) {
          console.error(error)
          setIsOpenValidateSms(false)
        }
      } else {
        try {
          const data = entries.map(entry => { 
            const {key, country_id, country_code,  ...rest} = entry
            const country = countries.filter(country => trimLowerCase(country?.code) === trimLowerCase(country_id) || trimLowerCase(country?.code) === trimLowerCase(country_code))[0]

            return {...rest, user: selfNominId, country_code: country?.code, country_id: country?.country_id} 
          })
          savePhones(data)
        } catch (error) {
          console.error(error)
        }
      }
    }
  }

  const canAdd = entries.length < maxLength
  return (
    <>
      {/* EDITION HAPPENS HERE */}
      <Form
        className="d-none p-0 m-0 w-100 min-w-0 max-w-full position-relative"
        id={ profile.moduleFormPhone }
      >

        {/* Add button */}
        {
          (canAdd && !isEmpty)  && (
            <ButtonCircle
              id={ profile.editModulePhone }
              className="float-end position-absolute"
              color="first"
              onClick={ addEntry }
              title={ i18n.t("common.edit") }
              name="btn_edit_email"
            >
              <MdOutlineAdd color='white' size='2em' />
            </ButtonCircle>
          )
        }
        {/* Abb button */}

        <div>
          { 
            entries.map((phone, idx) => {
              return (
                <ProfileEntry 
                  key={phone?.id}
                  phone={phone}
                  data={data}
                  isEditing={isEditing}
                  entries={entries}
                  setEntries={setEntries}
                  entry={entries[idx]}
                  removeEntry={removeEntry}
                  idx={idx}
                  setIsTouched={setIsTouched}
                  setIsOpenValidateSms={setIsOpenValidateSms}
                />
              )
            })
          } 

          <div className="d-flex gap-2 px-2">
            {loading
              ? (
                <Col className="pt-1" xs={12}>
                  <LoaderSpinner />
                </Col>
              ) : (
                <>
                  <Col className="pt-1 col-xs-12 col-xl-6">
                    <Button
                      className="btn mb-1"
                      disabled={ loading || isEmpty }
                      color="first"
                      type="button"
                      onClick={onSubmit}
                      id='btn_save_additional_phones'
                      name="btn_save_additional_phones"
                    >
                      { i18n.t("common.submit") }
                    </Button>
                  </Col>
                  <Col className="pt-1 col-xs-12 col-xl-6">
                    <Button
                      className="btn mb-1"
                      disabled={ loading }
                      onClick={ cancelEditRequest }
                      color="fourth"
                      type="button"
                      name="btn_cancel_additional_phones"
                    >
                      { i18n.t("common.cancel") }
                    </Button>
                  </Col>
                </>
              )
            }
          </div>
        </div>
      </Form>
      {/* EDITION HAPPENS HERE */}

      <section 
        className=''
        id={ profile.editModulePhones }
      >
        { 
          phones.map(phone => {
            return (
              <ProfileEntry 
                key={phone?.id}
                phone={phone}
                data={data}
                isEditing={isEditing}
              />
            )
          })
        }     
      </section>
    </>
  )
}

export default ProfileBlock