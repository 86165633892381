import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { beneficiaryCategory, additionalPhonesCategory } from '../constants';
import { useFormContext } from 'react-hook-form';
import { trimLowerCase } from '../helpers';

/**
 * Component Entry's custom hook
 * @component
 * @param {Number} id        - Entry's unique ID
 * @param {String} category  - Category we are currently in (either beneficiaryCategory or additionalPhonesCategory)
 * @param {Array}  fields    - Array containing all of the fields for this current category
 * @param {Object} entry     - Object representing the current entry.
 * @returns
 */
export const useEntry = (id, category, fields, entry) => {
  const { actualLanguage } = useSelector(state => state.base)
  /* To control button's availability */
  const [isBlockDisabled, setIsBlockDisabled] = useState(true);
  const [entryNumber, setEntryNumber] = useState('');
  /* In order for our button to be enable by default, when editing */
  const { action } = useSelector(state => state.tier);
  const { watch, getValues, setValue } = useFormContext(); 

  useEffect(() => {
    if (category === beneficiaryCategory){
      if (id !== 1) {
        setEntryNumber(`${id}`);
      }
    }

    if (category === additionalPhonesCategory){
      setEntryNumber(`${id + 1}`);
    }
  }, [id]);

  useEffect(() => {
    if (action === 'edit') {
      setIsBlockDisabled(false);
    }
  }, [action]);

  /* Additional phones
  ** 'Choosing a main additional phone' funcitonality 
  */
  // Current entry
  const entryPhoneFields = fields.filter(field => field.unique_name.includes(entry.id+1))
  // 'Main' field of the current Entry
  const [mainPhoneField] = entryPhoneFields?.filter((field) => field.data_type === 'checkbox')
  const mainPhoneUniqueName = mainPhoneField ? mainPhoneField?.unique_name + '__id__' + mainPhoneField.id : ''
  // We watch their values
  const watchMainPhoneField = watch(mainPhoneUniqueName, '')
  const mainPhoneFieldDescription = mainPhoneField?.[`description_${trimLowerCase(actualLanguage)}`]
  const mainPhoneClasslist = (watchMainPhoneField === true) ? 'active' : 'inactive'
  // Since there can only be one Favorite/Main additional phone number...
  // We find all 'Main' fields
  const mains = fields?.filter(field => field.unique_name.includes('main'))
  // and exclude the current
  const rest = mains?.filter(field => !field.unique_name.includes(entry.id+1))

  /* Toggles the value for the current 'Main' checkbox field. Also turns the rest to false */
  const handleMainPhoneNumber = () => {
    let bool = (getValues(mainPhoneUniqueName) !== true) ? false : true
    if(!bool){
      setValue(mainPhoneUniqueName, !bool)
      rest.forEach(element => {
        const name = element.unique_name + '__id__' + element.id
        if (getValues(name) === true || getValues(name) === 'true'){
          setValue(name, false)
        } 
      });
    }
  }


  return {
    entryNumber,
    isBlockDisabled,
    setIsBlockDisabled,
    action,
    handleMainPhoneNumber,
    mainPhoneClasslist,
    mainPhoneFieldDescription,
    getValues
  };
};