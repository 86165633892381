import React, {useRef, useState} from 'react';
import { Label } from '../../../../shared/simple';
import { StyledGroup } from '../../../../shared/Input/styled';
import { StyledSelect } from '../../../styled';
import { Col } from 'react-bootstrap';
import { sOption, hiddenField } from '../../../../../constants';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { editionStatus } from '../../../../../constants';
import { blockCases } from '../../../../../constants';
import { useTextLanguage } from '../../../../../hooks';
import { notUndefinedNull, trimLowerCase } from '../../../../../helpers';

/**
 * Component select
 * @component
 * @param {Object} props                  - Props for Select component
 * @param {string} props.className        - Additional CSS class for styling
 * @param {string} props.label            - Label for the component
 * @param {booleaArrayn} props.countries  - Array of all countries
 * @returns
 */
export const Select = (props) => {
  const { blockCase } = props
  const requestLanguage = useSelector(state => state.base.requestLanguage);
  const { languageText } = useTextLanguage();
  const staticData = useSelector(state => state?.staticData[`data${languageText}`])
  let countries
  if (notUndefinedNull(staticData)) countries = staticData?.country


  const [beneficiaryOptions] = useState(useSelector(
    state => state.staticData[`data_${requestLanguage.toLowerCase()}`].account_link_types));
  const selectRef = useRef('');
  let options, name
  if (blockCase === blockCases.beneficiary) {
    options = beneficiaryOptions
    name = "client_account_link_type_id"
  }
  if (blockCase === blockCases.phones) {
    const countriesWE = props.countries.filter(country => country.active_for_wisenroll)
    options = countriesWE
  }

  const { t } = useTranslation();
  const handleChange = () => {
    if (blockCase === blockCases.beneficiary) {
      const { entry, setEntries, setWisenrollUser } = props
      if ((selectRef.current.selectedIndex === 0)) setWisenrollUser({});
      setEntries(prevEntries => {
        return prevEntries.map(prevEntry => {
          if (prevEntry.order === entry.order) {
            return {
              ...prevEntry,
              client_account_link_type_id: selectRef.current.selectedIndex,
              related_user_wisenroll_code: (selectRef.current.selectedIndex === 0) ? '' : prevEntry.related_user_wisenroll_code,
              authorized_signature: false,
              isValid: (selectRef.current.selectedIndex === 0) ? false : (prevEntry.isValid || false)
            };
          } else {
            return prevEntry;
          }
        });
      });
    }

    if (blockCase === blockCases.phones) {
      const { entry, setEntries, setIsTouched } = props
      const updateEntries = () => {
        setEntries(prevEntries => {
          return prevEntries.map(prevEntry => {
            if (Number(prevEntry?.key) === Number(entry?.key)) {
              let country = countries.filter(country => trimLowerCase(country.code) === trimLowerCase(selectRef.current.value))[0]
              return {
                ...prevEntry,
                validated: false,
                country_code: selectRef.current.value,
                country_id: country?.country_id
              }
            }
            else return prevEntry
          })
        })
        setIsTouched(true)
      }
      if (entry?.validated) { 
        if (window.confirm(t('block.confirmModification'))){
          updateEntries()
        } else {
          selectRef.current.value = entry.country_code
        }
      } else updateEntries()
    }
  };

  const isDisabled = () => {
    if (blockCase === blockCases.beneficiary) {
      const { selectedProduct } = props
      return selectedProduct.status === editionStatus.pending
    }
    return false
  }

  return (
    <>
      <Col
        className={props?.className}
        xs={12}
      >
        <StyledGroup className="mb-3">
          <Label>
            {/* Label for beneficiaries */}
            {(blockCase === blockCases.beneficiary) && props.label}
            {/* Label for additional phones */}
            {(blockCase === blockCases.phones && (
              <small className="text-muted" xs={12}>
                <strong>{props?.label}</strong>
              </small>
            ))}
          </Label>
          <StyledSelect
            ref={selectRef}
            name={name || ''}
            onChange={ handleChange }
            disabled={isDisabled()}
          >
            <option
              value={sOption}
              data-code={sOption}
            >
              { t("common.optionDefault") }
            </option>
            <option
              className="d-none"
              id={ hiddenField }
              value={ hiddenField }
            >
            </option>
            {options?.map(option => {
              let isSelected
              if (blockCase === blockCases.beneficiary) {
                const { entry } = props
                isSelected = option.account_link_type_id === entry.client_account_link_type_id;
              }
              if (blockCase === blockCases.phones) { 
                const { entry } = props
                isSelected = trimLowerCase(option?.country_id) === trimLowerCase(entry?.country_id)
              }
              return (
                <option
                  key={ option.code }
                  value={ option.code }
                  id={ option.country_id }
                  selected={ isSelected }
                >
                  {(blockCase === blockCases.beneficiary) && (option?.[`description`])
                    ? (option[`description`])
                    : option.account_link_type_id }

                  {(blockCase === blockCases.phones) && (option?.[`name`])
                    ? (option?.[`name`])
                    : option?.demonym }
                </option>
              );
            })}
          </StyledSelect>
        </StyledGroup>
      </Col>
    </>
  );
};
