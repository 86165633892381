import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { getShowField, trimLowerCase } from './../../../helpers';
import { CheckSelect, Select } from './../select';
import { TextInput } from './../input';
import { useEntry } from './../../../hooks/useEntry';
import { beneficiaryCategory, blockRemove, additionalPhonesCategory, paramsAdd, blockAdd } from './../../../constants';
import { Add, Remove, Validate } from './buttons';
import PropTypes from 'prop-types';
import { useRemoveEntry } from '../../../hooks/useRemoveEntry';
import { useCheck } from '../../../hooks/useCheck';
import { AiTwotoneDelete } from 'react-icons/ai'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Typography } from '@mui/material';
import { Switch } from '@mui/material';
import { useDisabled } from '../../../hooks';
import { RemovePhone, StyledEntry } from './styled';

/**
 * Component Entry
 * @component
 * @param {Array<Object>} fields    - An array of fields.
 * @param {Function} setEntries     - React setter function. to set the entries.
 * @param {Object} entry            - Current entry.
 * @param {Number} maxLength        - Max number of entries allowed.
 * @param {Array<Objetc>} entries   - An array of entries.
 * @param {Boolean} canContinue     - Variable that controls if the user can advance on the steps or create a new Entry
 * @param {Function} setCanContinue - canContinue setter function.
 * @param {Number} minEntryId       - id of the new min entry, in case the first one (entry.id === 1) has been deleted. Required for the responsive behaviour. 
 * @returns {JSX.Element}
 */
export const Entry = ({
  fields,
  setEntries,
  entry,
  maxLength,
  entries,
  canContinue,
  setCanContinue, 
  handleAdd,
  category,
  minEntryId,
  canAddPhone,
  setCanAddPhone
}) => {
  const { t } = useTranslation();
  const [wisenrollUser, setWisenrollUser] = useState({
    error: false,
    user: null
  });
  const { 
    entryNumber, isBlockDisabled, setIsBlockDisabled, action, 
    handleMainPhoneNumber, mainPhoneClasslist, mainPhoneFieldDescription, getValues 
  } = useEntry(entry.id, category, fields, entry);
  const { handleRemove } = useRemoveEntry(setEntries, entryNumber, category, setCanAddPhone);
  const entryFields = fields?.filter(field => trimLowerCase(field.unique_name).includes(`block${entryNumber}__`));
  const { handleCheck } = useCheck({entryFields, setWisenrollUser, setEntries, entryNumber});
  const mainField = entryFields.filter(field => field?.unique_name.includes('main') && field?.unique_name.includes(entry?.id+1))[0]
  const { isDisabled } = useDisabled(mainField);
  const removeBtnField = fields?.filter(field => trimLowerCase(field?.unique_name).includes('remove') && trimLowerCase(field?.unique_name).includes(entryNumber))

  if(category === beneficiaryCategory){
    return (
      <StyledEntry className="row mb-4 py-2 mx-2">
        {fields?.map(field => {
          if (field.active && getShowField(field)) {
            if ((trimLowerCase(field.unique_name).includes(`block${entryNumber}__`))) {
              if (trimLowerCase(field.data_type) === 'selection') {
                return (
                  <Select
                    field={field}
                    key={field.id}
                    entryProps={{
                      setWisenrollUser,
                      setIsBlockDisabled,
                      setCanContinue,
                      setEntries,
                      entry,
                      category: beneficiaryCategory
                    }}
                  />
                );
              }
              if (trimLowerCase(field.data_type) === 'text') {
                return(
                  <TextInput
                    field={field}
                    key={field.id}
                  />
                );
              }
              if (trimLowerCase(field.data_type) === 'checkbox') {
                return(
                  <CheckSelect
                    field={field}
                    key={field.id}
                  />
                );
              }
              if (trimLowerCase(field.data_type) === 'button_check') {
                return(
                  <Validate
                    field={field}
                    key={field.id}
                    isBlockDisabled={isBlockDisabled}
                    action={action}
                    handleCheck={handleCheck}
                    entry={entry}
                  />
                );
              }
            }
            if (entry.id === 1 && entries.length < maxLength && action !== 'edit' && trimLowerCase(
              field.data_type) === blockAdd && (entries[0].isValid) && (canContinue === true)) {
              return(
                <Add
                  key={field.id}
                  handleAdd={() => handleAdd(setEntries)}
                />
              );
            }
            if (trimLowerCase(field.data_type) === blockRemove && entry.id !== 1  && action !== 'edit') {
              return(
                <Remove
                  key={field.id}
                  entry={entry}
                  handleRemove={handleRemove}
                />
              );
            }
          }
          return null;
        })}
        {(wisenrollUser?.user && !wisenrollUser?.error) && (
          <>
            <hr className="mt-1"></hr>
            <div className="alert alert-success d-flex flex-column" role="alert">
              <label className="text-muted">
                {t("block.name")}: <b>{wisenrollUser?.user?.firstName}</b>
              </label>
              <label className="text-muted">
                {t("block.lastName")}: <b>{wisenrollUser?.user?.lastName}</b>
              </label>
              <label className="text-muted">
                {t("block.docType")}: <b>{wisenrollUser?.user?.docType}</b>
              </label>
              <label className="text-muted">
                {t("block.docNumber")}: <b>{wisenrollUser?.user?.docNumber}</b>
              </label>
            </div>
          </>
        )}
        {(wisenrollUser?.error === true) && (
          <>
            <hr className="mt-1"></hr>
            <div className="alert alert-danger" role="alert">
              {t("block.error")}
            </div>
          </>
        )}
      </StyledEntry>
    );
  }
  if(category === additionalPhonesCategory){
    return (
      <>
        {
          /* Space needed before the first existant additional phone entry  */
          (((entry.id === minEntryId) && !entries.some(entry => entry.id === 1) ))
          ? <div className=' d-none d-xl-block col-xl-4'></div> 
          : null
        }
        {fields?.map(field => {
          if (field.active && getShowField(field)) {
            if (trimLowerCase(field.unique_name).includes(entryNumber)) {
              if (trimLowerCase(field.data_type) === 'selection') {
                return (
                  <Select
                    field={field}
                    key={field.id}
                    entryProps={{
                      canAddPhone,
                      setCanAddPhone,
                      category,
                      fields: entryFields,
                      getValues,
                      category: additionalPhonesCategory
                    }}
                  />
                );
              }
              if (trimLowerCase(field.data_type) === 'text') {
                return(
                  <TextInput
                    field={field}
                    key={field.id}
                    entryProps={{
                      canAddPhone,
                      setCanAddPhone,
                      category,
                      fields: entryFields,
                      getValues
                    }}
                  />
                );
              }
              if (trimLowerCase(field.data_type) === 'checkbox'){
                return (
                  <CheckSelect
                    field={ field }
                    key={ field.id }
                  />)
              }
            }
          }
          return null;
        })}

        <div className=' d-flex col-md-12 col-xl-8 align-items-center '>
          <div className='col-6'>
            <FormControlLabel 
              control={<Switch 
                onChange={!isDisabled ? handleMainPhoneNumber : null}
                checked={mainPhoneClasslist === 'active'}
              />}
              label={<Typography style={{ fontSize: '12px' }}>{mainPhoneFieldDescription}</Typography>}
            />
          </div>
          
          {
            removeBtnField.map(btnField => {
              if(entry?.id !== 1 && btnField.data_type === 'block_remove' && action === paramsAdd) {
                return (
                  <RemovePhone
                    key={btnField.unique_name}
                    data-unique-name={ btnField.unique_name }
                    color='first'
                    onClick={() => handleRemove(entry?.id)}
                    type='button'
                    className='offset-1 col-5'
                  >
                    <AiTwotoneDelete />
                  </RemovePhone>
                )
              }
              return null
            })
          }

        </div>
        <div className=' d-none d-md-block col-xl-10'>

        </div>
          
      </>
    );
  }
};

Entry.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  setEntries: PropTypes.func.isRequired,
  entry: PropTypes.object.isRequired,
  maxLength: PropTypes.number.isRequired,
  entries: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleAdd: PropTypes.func.isRequired
};