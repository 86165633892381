import { PERSON_DATA_CLEAR, PERSON_DATA_SET_DATA, PERSON_DATA_DATA_SENT, PERSON_DATA_CLEAR_DATA_SENT, PERSON_DATA_UPDATE_USER_CONTACT } from '../types';

const initState = {};

/**
 * Reducer to person data
 * @param {Object} state
 * @param {Object} action
 * @returns
 */
export const personDataReducer = (state=initState, action) => {
  switch (action.type) {
    case PERSON_DATA_CLEAR:
      return initState;

    case PERSON_DATA_SET_DATA:
      return {
        ...state,
        ...action.payload,
      };

    case PERSON_DATA_DATA_SENT:
      return {
        ...state,
        data_sent: {
          ...state.data_sent,
          ...action.payload
        }
      };

    case PERSON_DATA_UPDATE_USER_CONTACT:
      return {
        ...state,
        data: {
          ...state.data,
          user_contact: action.payload
        }
      }

    case PERSON_DATA_CLEAR_DATA_SENT:
      return {
        ...state,
        data_sent: {}
      }

    default:
      return state;
  }
};