import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  defaultValuesNewProductRequest,
  paramsAdd,
  paramsEdit,
  routes,
  schemaNewProductRequest,
  statusRequest,
  uniqueNameProduct
} from '../constants';
import {
  defaultChecked,
  isActualRequestSuccessOrRejected,
  isUndefinedNull,
  notUndefinedNull,
  searchStaticData,
  sortElement,
  trimLowerCase,
  userTier
} from '../helpers';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  getAppData,
  getBase,
  getProductRequest,
  getUserApplications,
  newProductRequest,
  setLoader,
  setMessage,
  updateRequest
} from '../redux/actions';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { theme } from '../theme';
import {
  USER_APP_ORIGIN,
  USER_INFO_PRODUCT_REQUEST,
  USER_PRODUCT_REQUEST,
  USER_VERIFICATION
} from '../redux/types';

/**
 * Hook to get the appOrigin and the product
 * @returns
 */
export const useDescription = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data: { product_request:productRequest } } = useSelector(state => state.personData);
  const { data } = useSelector(state => state.personData);
  const { dataProductRequest, lastProductRequestId, level } = useSelector(state => state.user);
  const { loading } = useSelector(state => state.base);
  const { code } = useParams();
  const [product, setProduct] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [flagRequest, setFlagRequest] = useState(false);
  const [actualRequestSuccessOrRejected, setActualRequestSuccessOrRejected] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const appOrigin = productRequest?.application_origin?.unique_name;
  const { register, formState: { errors }, handleSubmit, reset } = useForm({
    resolver: yupResolver(schemaNewProductRequest),
    defaultValues: defaultValuesNewProductRequest
  });
  const optionsTiers = defaultChecked(userTier());
  const [tier, setTier] = useState(null);

  useEffect(() => {
    const nextTier = optionsTiers.find(
      (tier) => (tier.show && tier.unique_name === 'basic'));
    setTier(nextTier);
  }, [optionsTiers, product]);

  useEffect(() => {
    const boolStatus = dataProductRequest?.some(
      item => (((
        item.status.unique_description === statusRequest.processing || item.status.unique_description === statusRequest.sent) && (
          item.application_origin.unique_name === code)) || item.status.unique_description === statusRequest.sent));
    setFlagRequest(boolStatus);

    setActualRequestSuccessOrRejected(isActualRequestSuccessOrRejected(dataProductRequest, lastProductRequestId))
  }, [dataProductRequest, lastProductRequestId, code]);

  const awaitSearchStaticData = async () => {
    let awaitedProduct = await searchStaticData('application', 'unique_name', code)
    setProduct(awaitedProduct)
  }

  useEffect(() => {
    if (notUndefinedNull(code)) awaitSearchStaticData()
  }, [code]);

  useEffect(() => {
    if (isUndefinedNull(product)) navigate(routes.productsServices(
      'product_and_services'));
  }, [product]);

  const onRequest = async () => {
    const body = {
      'user_id': data.id,
      'application': product.application.unique_name
    };
    dispatch({ type: USER_INFO_PRODUCT_REQUEST, payload: product });

    try {
      dispatch(setLoader(true));
      await dispatch(newProductRequest(body));
      dispatch(getBase(product.application.unique_name));
      dispatch({ type: USER_APP_ORIGIN, payload: product.application.unique_name });
      const newLevel = (level > product.application.tier) ? product.application.tier : level;
      dispatch({ type: USER_VERIFICATION, payload: newLevel });
      navigate(routes.tier(tier.unique_name, tier.edit ? paramsEdit : paramsAdd));
      dispatch(setLoader(false));
      reset();
      setModalIsOpen(false);
    } catch (error) {
      console.error('onRequest: error in new product request', error);
      if (!loading) dispatch(setLoader(false));
      dispatch(setMessage(t("message.errorRequestSuccess"), 'error'));
    }
  };

  const onModalRequest = async () => {
    reset();
    setModalMessage(t("message.modalConfirmationRequest"));
    setModalIsOpen(true);
  };

  return {
    appOrigin,
    product,
    onRequest,
    modalMessage,
    onModalRequest,
    modalIsOpen,
    setModalIsOpen,
    flagRequest,
    actualRequestSuccessOrRejected,
    errors,
    register,
    handleSubmit
  };
};

/**
 * Hook to list products
 * @returns
 */
export const useProductList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { applications, dataProductRequest } = useSelector(state => state.user);
  const { data } = useSelector(state => state.personData);
  const { actualLanguage, requestLanguage } = useSelector(state => state.base);
  const { application, tier } = useSelector(state => state.staticData[
    `data_${trimLowerCase(requestLanguage)}`]);
  const [currentStep, setCurrentStep] = useState(0);
  const optionsTiers = defaultChecked(userTier());

  useEffect(() => {
    optionsTiers.map((tier) => {
      if (tier.checked) {
        setCurrentStep(tier.id);
      }
      return null;
    });
  }, [optionsTiers]);

  useEffect(() => {
    if (!dataProductRequest) dispatch(getProductRequest(data.id));
  }, [dataProductRequest, dispatch]);

  const openProductDetails = product => navigate(routes.productDescription(
    product.unique_name));

  const tiers = sortElement(tier.filter(
    element => element.regular && element.active && (trimLowerCase(
      element.unique_name) !== trimLowerCase(uniqueNameProduct))).map(item => {
        const tiers = application.filter((app => app.tier === item.id));
        const products =  applications.filter(app => tiers.find(
          item => item.unique_name === app.application.unique_name && app.image_main));
        return ({
          title: item[`description_${trimLowerCase(actualLanguage)}`],
          id: item.id,
          products: products.map((app, index) => {
              return {
                ...app,
                key: index,
                unique_name: app.application?.unique_name,
                src: app.image_main
              };
            })
        });
      }), 'verification_level');

  return { openProductDetails, tiers, currentStep, actualLanguage };
};


/**
 * Hook to list requests
 * @param {boolean} bool
 * @returns
 */
export const useRequestList = bool => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { third, first, eighth, seventh } = theme;
  const { dataProductRequest, applications } = useSelector(state => state.user);
  const { data } = useSelector(state => state.personData);
  const { actualLanguage } = useSelector(state => state.base);
  const [requests, setRequests] = useState([]);
  const [tier, setTier] = useState(null);
  const [status, setStatus] = useState(null);
  const optionsTiers = defaultChecked(userTier());

  useEffect(() => {
    const nextTier = optionsTiers.find((tier) => (
      tier.show && tier.unique_name === 'basic'));
    setTier(nextTier);
  }, [optionsTiers]);

  useEffect(() => {
    if (!dataProductRequest && bool) dispatch(getProductRequest(data.id));
  }, [dataProductRequest, dispatch, bool]);

  useEffect(() => {
    if (dataProductRequest){
      setRequests(dataProductRequest);
      const boolStatus = dataProductRequest.some(
        item => item.status.unique_description === statusRequest.sent);
      setStatus(boolStatus);
    }
  }, [dataProductRequest]);

  const routeProducts = () =>
    navigate(routes.productsServices('product_and_services'));

  const getColorStatus = status => {
    switch (status) {
      case statusRequest.processing:
        return third;
      case statusRequest.sent:
        return first;
      case statusRequest.canceled:
        return seventh;
      case statusRequest.success:
        return eighth;
      case statusRequest.rejected:
        return seventh;
      default:
        break;
    }
  };

  const routeTier = id => {
    localStorage.setItem('idRequest', id);
    navigate(routes.tier(tier.unique_name, tier.edit ? paramsEdit : paramsAdd));
  };

  const onUpdateRequest = async (id, newStatus) => {
    const body = {
      'product_request_id': id,
      'new_status': newStatus
    };
    let allRequests = [];
    try {
      dispatch(setLoader(true));
      await dispatch(updateRequest(body, data.id));
      if (newStatus === statusRequest.canceled) {
        allRequests = Array.from(requests).filter(item => trimLowerCase(
          item.id) !== trimLowerCase(id));
        const { appOriginData, level } = getAppData(
          { product_request: allRequests }, null);
        dispatch({ type: USER_APP_ORIGIN, payload: appOriginData });
        dispatch({ type: USER_VERIFICATION, payload: level });
      } else {
        if (!status) {
          [allRequests] = Array.from(requests).filter(item => trimLowerCase(
            item.id) === trimLowerCase(id));
          dispatch({ type: USER_APP_ORIGIN, payload: allRequests.application_origin.unique_name });
        }
      }
      dispatch(setLoader(false));
    } catch (error) {
      console.error('onRequest: error in update request', error);
      dispatch(setLoader(false));
      dispatch(setMessage(t("message.errorRequestSuccess"), 'error'));
    }
  };

  const userData = useSelector(state => state.user)
  const onEditRequest = async(data) => {
    let productsAndServices = [];
    dispatch(getBase(data.application_origin.unique_name));
    dispatch({ type: USER_APP_ORIGIN, payload: data.application_origin.unique_name });

    if (!applications) {
      productsAndServices = await dispatch(getUserApplications());
    } else {
      productsAndServices = applications;
    }
    const [product] = productsAndServices.filter(
      product => product?.application?.unique_name === data.application_origin.unique_name);  
    const productRequest = dataProductRequest.find(request => request.application_origin.unique_name === product.application.unique_name)
    dispatch({ type: USER_INFO_PRODUCT_REQUEST, payload: product });
    dispatch({ type: USER_PRODUCT_REQUEST, payload: {
      lastProductRequestId: productRequest?.id
    }})
    routeTier(data.id);
  };

  return {
    routeProducts,
    requests,
    getColorStatus,
    routeTier,
    onUpdateRequest,
    status,
    actualLanguage,
    onEditRequest
  };
};