import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { beneficiaryCategory } from "../constants";
import { useFormContext } from "react-hook-form";
import { paramsAdd, paramsEdit } from "../constants";
import { getValue } from "../helpers";

const initialEntries = [
  {
    id: 1
  }
]


/**
 * Custom hook with logic requiered for the 'additional phones' feature.
 *
 * @param {String} category - string representing the current category
 * @returns {{ phoneEntries: Array; setPhoneEntries: Function; minEntryId: Number; }}
 */
export const useAdditionalPhones = (category) => {
  const { setValue } = useFormContext()
  const { action } = useSelector(state => state.tier);
  const blockEntries = useSelector(state => state.personData.data.user_contact);
  // Set currentTier, according to the current 'category'
  const currentTier = category === beneficiaryCategory ? 'advanced' : 'basic'
  let fields = useSelector(state => state.tier.tiers[currentTier]?.categories.fields[category]);

  /* Entries for the 'additional phones' */
  const [phoneEntries, setPhoneEntries] = useState(initialEntries)
  /* Whenever the entries are modifiesd (whenever a phone is added or deleted)
  ** it will calculate the new 'first' entry.
  */
  const [minEntryId, setMinEntryId] = useState(null)
  // Calculating the 'minId', that represents the id for the 'lower' entry.
  // This will be required for responsiveness.
  useEffect(() => {
    const phoneEntriesLength = phoneEntries?.length
    const lastEntry = phoneEntries?.[phoneEntriesLength-1]
    if(phoneEntriesLength && lastEntry?.id !== phoneEntriesLength) {
      const minId = Math.min(...phoneEntries.map(entry => entry.id))
      setMinEntryId(minId)
    }
  }, [phoneEntries])

  // * Autocomplete on edit (fill entries)
  useEffect(() => {
    if (action === paramsEdit || action === paramsAdd) {
      if (blockEntries) {
        const entriesLength = blockEntries?.length;
        const newEntries = []
        for (let i=1; i <= entriesLength; i++) {
          newEntries.push({id: i});
        }
        setPhoneEntries(newEntries);
      }
    }
  }, [action]);

  // * Logic for handling 'contact_type' value setting (in edit)
  useEffect(() => {
    if(action === paramsEdit){
      setTimeout(() => {
        /* Reseting all fields that exceed our blockEntries length */
        const contactTypeFields = fields.filter(field => field.unique_name.includes('contact_type')).filter(field => JSON.parse(field.action)?.isBlock)
        contactTypeFields.forEach((field, idx) => {
          const uniqueName = field?.unique_name + '__id__' + field?.id
          if(idx >= blockEntries.length) {
            setValue(uniqueName, '')
          }
        });
      }, 2000);
    }

  }, [phoneEntries])

  // * Logic for handling 'main' field value setting
  useEffect(() => {
    // If only one additional phone entry remains, it should be the 'Main'.
    const phoneEntriesLength = phoneEntries?.length
    if(phoneEntriesLength === 1 && (action === paramsAdd && !blockEntries)){
      const [uniqueEntry] = phoneEntries
      const currentEntry = fields.filter(field => field.unique_name.includes(uniqueEntry?.id+1))
      const [main] = currentEntry.filter(entry => entry.unique_name.includes('main'))
      const uniqueName = main?.unique_name + '__id__' + main?.id
      setValue(uniqueName, true)
    }
  }, [phoneEntries, action, blockEntries, fields, setValue])

  // * Logic for handling 'contact_type' value setting (in add)
  useEffect(() => {
    /* Reseting all except the first contact_type field at first render */
    // filtering all contact_type fields
    const contactTypeFields = fields.filter(field => field.unique_name.includes('contact_type')).filter(field => JSON.parse(field.action)?.isBlock)
    if(action === paramsAdd){
      setTimeout(() => {
        contactTypeFields.forEach((field, idx) => {
          const uniqueName = field?.unique_name + '__id__' + field?.id
          if(idx === 0){
            const value = getValue(field)
            setValue(uniqueName, value)
          }else{
            setValue(uniqueName, '')
          }
        });
      }, 1000)
    }
  }, [action, fields, setValue])

  // * State for disabling adding new phone numbers until all previous are filled
  const [canAddPhone, setCanAddPhone] = useState(false) 


  return {
    phoneEntries,
    setPhoneEntries,
    minEntryId,
    canAddPhone,
    setCanAddPhone
  }
}