/**
 * Endpoint list
 */
export const endpoints = {
  changePasswordService: '/change-password/',
  changePasswordNoHash: '/change-password-no-hash/',
  getApplications: '/extra-product-info/',
  login: '/login-otp/',
  loginToken: '/login-fe/',
  getOtpToken: '/send-otp-token/',
  migrate: '/migrate/',
  recoverPassword: '/forgot-password/',
  sendEditionToken: '/send-edition-token/',
  sendEmailToken: '/send-email-token/',
  sendSmsToken: '/send-sms-token/',
  signUp: '/register/',
  staticData: '/static-data',
  updateBifrost: '/update-bifrost-data/',
  validateEditionToken: '/validate-edition-token/',
  validateEmailToken: '/validate-email-token/',
  validateSmsToken: '/validate-sms-token/',
  verifyUser: '/validate-user-hash/',
  checkUserTier: '/check-user-tier-by-app/',
  downloadFile: (fileDownloadLink) => `${fileDownloadLink}`,
  getUserFiles: (id) => `/user-files?user__bifrost_user_id=${id}`,
  saveUserFile: (id) => `/user_files/${id}`,
  saveUserFileFull: (id) => `/user_files_full/${id}`,
  personData: (id, productRequestId) => `/person_data/${id}/${productRequestId}`,
  companyDataByCode: (code) => `/company-data-by-code/${code}`,
  allowedCountries: '/allowed-countries',
  availableAssistants: '/available-assistants',
  notifyAssistant: '/notify-assistant',
  verifyAssistant: '/verify-assistant-token',
  getDataSummary: '/static-data-summary',
  registerClientEntity: '/register-previous-user/',
  getProductRequest: (id) => `/product-requests?user__id=${id}`,
  newProductRequest: '/new-product-request/',
  updateRequest: '/update-product-request-status/',
  validateWisenrollCode: '/user-data-by-wc/',
  createInternetBankingUser: '/create-ebanking-user/',
  requestEditionToken: (id) => `/send-edition-token/${id}`,
  requestEditionTokenValidation: '/validate-edition-token/',
  postNewBeneficiaries: '/remote-related-account-requests',
  clientProducts: (id) => `/client-products/${id}`,
  relatedAccountsPerProduct: (id) => `/remote-related-accounts/${id}`,
  ipAddressInfo: '/ip-address-info/',
  userContacts: '/user-contacts/',
  updateContacts: '/update-customer-contact/'
};