import React from 'react'
import { PhoneEntry } from '../../../../../hooks'
import i18n from '../../../../../translations/i18n'
import { trimLowerCase } from '../../../../../helpers'
import { useSelector } from 'react-redux'
import { FormControlLabel, Switch } from '@mui/material'
import { TextInput } from '../input/TextInput'
import { Select } from '../select/Select'
import { blockCases, sOption } from '../../../../../constants'
import ProfileSwitch from './ProfileSwitch'
import { Col } from 'react-bootstrap'
import { Button } from '../../../../shared/simple'
import { LuTrash } from "react-icons/lu";
import classNames from 'classnames'
import { useDispatch } from 'react-redux'
import { sendSmsToken } from '../../../../../redux/actions'
import { USER_CURRENTLY_VALIDATING_PHONE } from '../../../../../redux/types'
import { setLoader, saveAdditionalPhones } from '../../../../../redux/actions'

const ProfileEntry = ({
  data,
  isEditing,
  phone,
  entries,
  setEntries,
  entry,
  removeEntry,
  idx,
  setIsTouched,
  setIsOpenValidateSms
}) => {
  const { actualLanguage } = useSelector(state => state.base)
  const { userIdBifrost } = useSelector(state => state.user)
  const { [`data_${trimLowerCase(actualLanguage)}`] : staticData } = useSelector(state => state.staticData)
  const countries = staticData.country
  const country = countries.filter(country => trimLowerCase(country.country_id) === trimLowerCase(phone?.country_id))[0]

  const classNamesBtn = classNames('pt-1 col-12 d-flex justify-content-between gap-2', {
    'col-xl-6': !entry?.validated,
    'col-xl-3 offset-xl-3': entry?.validated
  })
  const dispatch = useDispatch()

  const sendSms = async () => {
    const response = await dispatch(sendSmsToken({
      'phone_number': entry?.description,
      'country_id': entry?.country_id,
      'bifrost_user_id': userIdBifrost,
      'main': entry?.main
    }));

    if (!response?.error) {
      if (response?.token_status === 'sent') {
        setIsOpenValidateSms(true)
        dispatch({type: USER_CURRENTLY_VALIDATING_PHONE, payload: {
          submitting: false,
          phone: entry
        }})
      }
      if (response?.token_status === 'validated') {
        setEntries(prevEntries => {
          return prevEntries.map(prevEntry => {
            
            return {
              ...prevEntry,
              validated: (prevEntry?.id === entry?.id) ? true : prevEntry.validated
            }
          })
        })
      }
    }

  }

  const buttons = (
    <>
      <Col className={classNamesBtn}>
        {
          !entry?.validated && (
          <Button
            className="btn mb-1"
            disabled={ entry?.description?.length <= 6 || entry?.country_code === '' || entry?.country_code === sOption }
            color="third"
            type="button"
            onClick={sendSms}
          > 
            <small>
              { i18n.t("block.blockCheck") }
            </small>
          </Button>
          )
        }
        {
          entries?.length > 1 && (
          <Button
            className="btn mb-1 d-flex justify-content-center "
            onClick={ () => removeEntry(entry) }
            color="seventh"
            type="button"
            name="btn_cancel_additional_phones"
          >
            <small>
              <LuTrash />
            </small>
          </Button>
          )
        }
      </Col>
    </>
  )

  const classNamesEntry = classNames('mb-2 row mx-1', {
    'mt-3': idx === 0 && isEditing
  })

  return (
    <PhoneEntry 
      className={classNamesEntry}
      isEditing={isEditing}
    >
      {/* Country */}
      {!isEditing ? (
        <>
          <div className='col-12 col-xl-6 mb-1'>
            <small className="text-muted" xs={12}>
            <strong>{i18n.t('profile.cellPhoneCountry')}</strong>
            </small>
            <div>
            { country?.name }
            </div>
          </div>
        </>
      ) : 
        <div className='col-12 col-xl-6 mb-1'>
          <Select 
            label={i18n.t('profile.cellPhoneCountry')}
            countries={countries}
            blockCase={blockCases.phones}
            entry={entry}
            entries={entries}
            setEntries={setEntries}
            setIsTouched={setIsTouched}
          />
        </div>
      }
      {/* Number */}
      {!isEditing ? (
        <>
          <div className='col-12 col-xl-6 mb-1'>
            <small className="text-muted" xs={12}>
              <strong>{ data?.[0].name }</strong>
            </small>
            <div>
              {phone.description}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='col-12 col-xl-6 mb-1'>
            <TextInput 
              label={ data?.[0].name }
              blockCase={ blockCases.phones }
              entries={entries}
              setEntries={setEntries}
              entry={entry}
              setIsTouched={setIsTouched}
            />
          </div>
        </>
      )}
      {/* Main */}
      {!isEditing ? (
        <>
          <FormControlLabel 
            className='col-12 col-xl-6'
            disabled
            control={<Switch 
              checked={phone?.main}
            />}
            label={<small><strong>{i18n.t('profile.mainPhoneNumber')}</strong></small>}
          />
        </>
      ) : 
        <ProfileSwitch 
          phone={phone}
          entry={entry}
          setEntries={setEntries}
          entries={entries}
          setIsTouched={setIsTouched}
        />
      }
      {/* Delete Button */}
      {!isEditing ? null : 
        buttons
      }
    </PhoneEntry>
  )
}

export default ProfileEntry