import { getRequest, postRequest } from '../helpers';

/**
 * Return helpers
 * @param {String} endpoint
 * @param {Object} params
 * @returns
 */
export const sendGetEndpoint = async (endpoint, params='') => {
  try {
    const response = await getRequest(endpoint, params);
    return response;
  } catch (error) {
    console.error(`sendGetEndpoint - ${endpoint}`, error);
  }
};

/**
 * Call request
 * @param {Object}  data
 * @param {Object}  String
 * @param {Boolean} isRefreshToken - Will refresh token, if true
 * @returns
 */
export const sendPostRequest = async (data, endpoint, isRefreshToken = false) => {
  try {
    const response = await postRequest(endpoint, data, isRefreshToken);
    return response;
  } catch (error) {
    console.error(`sendPostRequest - ${endpoint}`, error);
  }
};