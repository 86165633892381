import { useSelector } from 'react-redux';
import { baseField, hiddenField, paramsAdd, sOption, statusRequest, userCitizenshipCountryField } from '../constants';
import { store } from '../redux/store/store';
import { theme } from '../theme';
import Inputmask from 'inputmask';
import { useParams } from 'react-router-dom';

/**** Export functions  ****/
/**
 * Receives a number and returns a string hiding all but the last N numbers
 * @param {Number} acctNumber
 * @param {Number} n
 * @returns {String}
 */
export function hideAccountNumber(acctNumber, n = 4) {
  return acctNumber?.toString().split('').fill('*', 0, acctNumber.toString().length - n).join('') || 'n/a';
}

/**
 * Sort element to integer
 * @param {Object} elements
 * @param {String} item
 * @returns
 */
export const sortElementInt = (elements, item) => {
  elements.sort(function(a, b) {
    return parseInt(a[item]) - parseInt(b[item]);
  });

  return elements;
};

/**
 * Sort element
 * @param {Object} elements
 * @param {String} item
 * @returns
 */
export const sortElement = (elements, item) => {
  elements.sort(function(a, b) {
    if (a[item] < b[item]) return -1;
    if (a[item] > b[item]) return 1;
    return 0;
  });

  return elements;
};

/**
 * Add or remove mask
 * @param {Object} field
 * @param {String} type
 * @param {Object} mask
 */
export const setMask = (field, type, mask) => {
  if (field && !field?.unique_name) {
    const maskField = getMask(mask);

    Inputmask.remove(field);
    if (maskField.valid && maskField.mask) {
      if (type === paramsAdd) {
        Inputmask({
          mask: maskField.mask,
          repeat: maskField?.repeat
        }).mask(field);
      } else {
        Inputmask({
          mask: maskField.unmask,
          repeat: maskField?.unrepeat
        }).mask(field);
      }
    }
  }
};

/**
 * Remove mask to field
 * @param {Object} field
 * @param {String} valueItem
 * @returns
 */
export const removeMask = (field, valueItem) => {
  try {
    const maskField = (field?.mask) ? getMask(field.mask) : '';

    if (!isEmpty(maskField) && valueItem) {
      if (maskField?.replace) {
        valueItem = valueItem.replaceAll(maskField.replace, '');
      }
    }

    return valueItem;
  } catch (error) {
    console.error('removeMask: ', error);
    return valueItem;
  }
};

/**
 * Get field with data-unique-name and second attr
 * @param {String} uniqueName
 * @param {String} secondAttr
 * @returns
 */
export const fieldUniqueName = (
  uniqueName,
  secondAttr=''
) => document.querySelector(`[data-unique-name="${uniqueName}"]${secondAttr}`);

/**
 * Get field with data-unique-name and second attr
 * @param {String} uniqueName
 * @param {String} secondAttr
 * @returns
 */
export const fieldsUniqueName = (
  uniqueName,
  secondAttr=''
) => document.querySelectorAll(`[data-unique-name="${uniqueName}"]${secondAttr}`);

/**
 * Get field with attribute
 * @param {String} attributeName
 * @param {String} value
 * @param {String} firstOption
 * @returns
 */
export const fieldAttribute = (
  attributeName,
  value,
  firstOption=''
) => document.querySelector(`${firstOption}[${attributeName}="${value}"]`);

/**
 * Get fields with attribute
 * @param {String} attributeName
 * @param {String} value
 * @param {String} firstOption
 * @returns
 */
export const fieldsAttribute = (
  attributeName,
  value,
  firstOption=''
) => document.querySelectorAll(`${firstOption}[${attributeName}="${value}"]`);

/**
 * Return value in string and lower
 * @param {String} value
 * @returns
 */
export const trimLowerCase = value => String(value).trim().toLowerCase();

/**
 * Return value in string and lower
 * @param {String} value
 * @returns
 */
export const trimUpperCase = value => String(value).trim().toUpperCase();

/**
 * Return typeof value is not undefined and null
 * @param {String} value
 * @returns
 */
export const notUndefinedNull = value => (
  value !== null && typeof value !== 'undefined');

/**
 * Return typeof value is undefined o null
 * @param {String} value
 * @returns
 */
export const isUndefinedNull = value => (
  value === null || typeof value === 'undefined');

/**
 * Return typeof value is empty
 * @param {String} value
 * @returns
 */
export const isEmpty = value => (value === '');

/**
 * Return boolean signaling whether or not 'obj' is iterable
 * @param {Object} obj
 * @returns
 */
export const isIterable = (obj) => {
  // checks for null and undefined
  if (obj == null) {
    return false;
  }
  return typeof obj[Symbol.iterator] === 'function';
}

/**
 * Create date depends format
 * @param {Object} field
 * @param {Object} fieldFormat
 * @param {Object} selectedDay
 * @returns
 */
export const sendFormatDate = (field, fieldFormat, selectedDay) => {
  let text = '';
  const fieldMask = (notUndefinedNull(field.mask)) ? JSON.parse(
    field.mask) : null;

  if (trimLowerCase(field.data_type) === 'date') {
    const format = fieldMask[fieldFormat];

    text = format.replace(fieldMask.day, (selectedDay.getDate() < 10) ? `0${
      selectedDay.getDate()}` : selectedDay.getDate());
    text = text.replace(fieldMask.month,((selectedDay.getMonth() + 1) < 10) ? `0${
      selectedDay.getMonth() + 1}` : selectedDay.getMonth() + 1);
    text = text.replace(fieldMask.year, selectedDay.getFullYear());
  }

  return text;
};

/**
 * Assign date depending of restriction
 * @param {Object} field
 * @param {String} typeSet
 * @returns
 */
export const assignDate = (field, typeSet) => {
  let todayDate = new Date();

  if (notUndefinedNull(field.mask)) {
    const fieldMask = JSON.parse(field.mask);

    if (fieldMask.set_min && isUndefinedNull(
      fieldMask.min_date) && typeSet === baseField.startDate) return todayDate;
    if (fieldMask.set_max && isUndefinedNull(
      fieldMask.max_date) && typeSet === baseField.endDate) return todayDate;

    if (fieldMask.set_min && notUndefinedNull(
      fieldMask.min_date) && typeSet === baseField.startDate) {
      todayDate.setMonth(todayDate.getMonth() - fieldMask.min_date);
      return todayDate;
    }
    if (fieldMask.set_max && notUndefinedNull(
      fieldMask.max_date) && typeSet === baseField.endDate) {
      todayDate.setMonth(todayDate.getMonth() - fieldMask.max_date);
      return todayDate;
    }
    if (fieldMask.set_min && typeSet === baseField.startDate) return todayDate;
    if (fieldMask.set_max && typeSet === baseField.endDate) return todayDate;
  }

  return null;
};

/**
 * Check if value is correct URL
 * @param {String} value
 * @returns
 */
export const validURL = value => {
  const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(value);
};

/**
 * Check if email is correct
 * @param {String} email
 * @returns
 */
export const validateEmail = email => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

/**
 * Validate length
 * @param {String} name
 * @param {String} id
 * @returns
 */
export const validateLength = (name, id) => {
  const field = fieldAttribute('name', `${name}__id__${id}`);
  const spanName = fieldAttribute('name', field?.name, 'span');

  if (spanName) spanName.remove();
  if (field) {
    if (field.dataset?.validate) {
      if (field.dataset?.validate === 'length') {
        const fieldValue = (field?.inputmask?.maskset?.mask) ? field.value.replaceAll('_', '') : field.value;
        if (parseInt(fieldValue.length) !== parseInt(field.dataset?.length) &&
          parseInt(fieldValue.length) !== parseInt(field.dataset?.length)) {
          const spanField = baseField.spanFieldLength(
            field?.name, field.dataset?.length);
          field.insertAdjacentHTML('afterend', spanField);
          return true;
        }
      }
      if (field.dataset?.validate === 'minLength'){
        let fieldValue = field?.value
        if (field?.inputmask?.maskset?.mask) {
          if (field?.value?.includes('_')) fieldValue = fieldValue.replaceAll('_', '')
          if (field?.value?.includes('-')) fieldValue = fieldValue.replaceAll('-', '')
        }
        if (parseInt(fieldValue?.length) < parseInt(field.dataset?.minLength)) {
          return true;
        }
      }
    }
  }

  return false;
};

/**
 * Return time to Timer
 * @param {String} numberOfMinutes
 * @returns
 */
export const timeMinutes = numberOfMinutes => {
  const time = new Date();
  time.setSeconds(time.getSeconds() + (Number(numberOfMinutes) * 60));

  return time;
};

/**
 * Get color
 * @param {String} option
 * @returns
 */
export const optionColor = option => {
  const { general, first, second, third, fourth, fifth, sixth, seventh } = theme;

  if (isUndefinedNull(option) || isEmpty(option)) return general;
  if (option === 'first') return first;
  if (option === 'second') return second;
  if (option === 'third') return third;
  if (option === 'fourth') return fourth;
  if (option === 'fifth') return fifth;
  if (option === 'sixth') return sixth;
  if (option === 'seventh') return seventh;
  return option;
};

/**
 * Get color
 * @param {String} option
 * @returns
 */
export const optionColorHover = option => {
  const {
    generalHover,
    firstHover,
    secondHover,
    thirdHover,
    fourthHover,
    fifthHover,
    sixthHover,
    seventhHover
  } = theme;

  if (isUndefinedNull(option) || isEmpty(option)) return generalHover;
  if (option === 'first') return firstHover;
  if (option === 'second') return secondHover;
  if (option === 'third') return thirdHover;
  if (option === 'fourth') return fourthHover;
  if (option === 'fifth') return fifthHover;
  if (option === 'sixth') return sixthHover;
  if (option === 'seventh') return seventhHover;
  return option;
};

/**
 * Get field
 * @param {String} attribute
 * @param {String} value
 * @returns
 */
export const searchField = (attribute, value) => {
  const { actualLanguage } = store.getState().base;
  const { field: fields } = store.getState().staticData[`data_${trimLowerCase(
    actualLanguage)}`];
  let field;

  const index = Array.from(fields).findIndex(item => trimLowerCase(item[
    attribute]) === trimLowerCase(value));
  if (index >= 0) {
    field = fields[index];
  }

  return field;
};

/**
 * Get value to field
 * @param {String} field
 * @param {String} value
 * @returns
 */
export const equalField = (field, action) => {
  const { requestLanguage } = store.getState().base;
  const { [`data_${trimLowerCase(
    requestLanguage)}`]:staticData } = store.getState().staticData;
  const { field: fields } = staticData;
  let valueItem = '';
  let fieldEqual = Array.from(fields).filter(
    item => item.unique_name === action.field);
  fieldEqual = fieldEqual.pop();
  const elementEqual = fieldUniqueName(fieldEqual.unique_name);

  if (elementEqual) {
    valueItem = (notUndefinedNull(
      elementEqual.value)) ? elementEqual.value : valueItem;
    valueItem = ((fieldEqual.data_type === 'selection') && (
      elementEqual.selectedIndex >= 0)) ? ((!isEmpty(elementEqual.children[
        elementEqual.selectedIndex].value)) ? elementEqual.children[
          elementEqual.selectedIndex].text : valueItem) : valueItem;
  }

  if (action?.value) {
    const valuesData = (notUndefinedNull(field.function_get_values)) ? JSON.parse(
      field.function_get_values) : null;
    let newValue = Array.from(staticData[valuesData?.name]).filter(elem =>
      trimLowerCase(elem[action.code]) === trimLowerCase(valueItem));
    newValue = newValue?.pop();
    valueItem = (newValue) ? newValue[valuesData[action.value]] : valueItem;
  }

  return valueItem;
};

/**
 * Check if certain minutes passed
 * @param {Date} param String Date Format
 * @param {Number} minutes Number
 * @returns
 */
export const checkMinutesPassed = (param, minutes = 1) => {
  if (isUndefinedNull(param)) return false

  if (Math.round((((new Date() - new Date(param)) % 86400000) % 3600000) / 60000) >= minutes) return true;
  return false;
};

/**
 * Receives a selection field name (partialName) and a value (selectValue) representing a selected option.
 * If the actual selected option matches this value (selectValue), deletes an element (elementName) from
 * the form object (formObject)
 * 
 * @param {String} partialName Unique_name of the field before adding the __id__
 * @param {Number} selectValue Code value representing the selected option to evaluate
 * @param {Object} methods methods Object from RHF
 * @param {Object} formObject Form object to be sent to POST personData
 * @param {String} elementName Name of the element to delete. (formObject.elements.elementName)
 * @returns 
 */
export const removeFromFormObject = (partialName, selectValue, methods, formObject, elementName) => {
  let values = methods.getValues()
  let uniqueName = ''
  // Find uniqueName's field to compare
  for (const key in values) {
    if (Object.hasOwnProperty.call(values, key)) {
      if(key.startsWith(partialName)){
        uniqueName = key
        break
      }
    }
  }
  // Deletes elementName from formObject if truthy
  if(Number(methods.getValues(uniqueName)) === selectValue || methods.getValues(uniqueName) === sOption){
    for (const key in formObject.elements) {
      if (Object.hasOwnProperty.call(formObject.elements, key)) {
        if(key === elementName){
          delete formObject.elements[key]
        }
      }
    }
  }

  return values
}

/**
 * Stop audio and video tracks, when recording.
 * @param {*} stream
 */
export const stopBothVideoAndAudio = (stream) => {
  stream?.getTracks().forEach((track) => {
    track.stop()
})}


/**
 * Deeply merge two different objects.
 *
 * @param {Object} obj1 main object; to be persisted
 * @param {Object} obj2 secondary object; to be merged
 * @returns {Object}
 */
export const deepMerge = (obj1, obj2) => {
  for (let key in obj2) {
    if (obj2.hasOwnProperty(key)) {
      if (obj2[key] instanceof Object && obj1[key] instanceof Object) {
        obj1[key] = deepMerge(obj1[key], obj2[key]);
      } else {
        obj1[key] = obj2[key];
      }
    }
  }
  return obj1;
}

/**
   * Receives the current array of entries and determines the 
   * next corresponding id, in sequential order.
   * 
   * e.g. 
   * let entries = [{id: 1}, {id: 3}, {id: 4}]
   * console.log(getNextEntryId(entries)) => 2
   * 
   * @param {Array}  entries
   * @param {String} compare - String representing the name of the property to compare with
   * @returns {Number}
   */
export const getNextEntryId = (entries, compare='id') => {
  const ids = entries.map(entry => entry?.[compare])
  if (Math.max(ids) === entries.length) {
    return (entries.length + 1)
  } else {
    let missingNumber;
    for (let i = 1; 1 <= entries.length; i++) {
      if (ids.includes(i)) continue;
      if (ids[i] !== i) {
        missingNumber = i;
        break;
      }
    }
    return missingNumber
  }
}


/**
 * Set and reload document conditions to if document__operator have a different country, not US.
 * 
 * @param {Function} setValue Function to set value 
 * @param {String}   baseCode String representing the country's code
 * @returns {*}
 */
export const changeCitizenByOperator = (setValue, baseCode) => {
  const { actualLanguage } = store.getState().base;
  const { country: countries } = store.getState().staticData[
    `data_${trimLowerCase(actualLanguage)}`];

  //obtener id de pais por code.
  let documentOperatorValue = countries.filter(item => item.code === baseCode);
  if (documentOperatorValue) {
    documentOperatorValue = documentOperatorValue[0];
    const userCitizenshipCountry = fieldAttribute(
      'data-unique-name', userCitizenshipCountryField);
    
    if (notUndefinedNull(userCitizenshipCountry)) {
      userCitizenshipCountry.click();

      setTimeout(async () => {
          let option;
          const parentUserCitizenshipCountry = userCitizenshipCountry.parentElement;
          await activeAdditionalOptions();
          option = parentUserCitizenshipCountry.querySelector(`div a option[data-code-id="${documentOperatorValue.idValue}"]`);
          if (option) {
            option.click();
            setValue(userCitizenshipCountry.name, documentOperatorValue.idValue);
            userCitizenshipCountry.parentElement.parentElement.parentElement.classList.remove('d-none');
          }
      }, 30);
    }
  }
}


/**
 * Click additional options in AutocompleteTypeahead select components
 * (It is a recursive function)
 * 
 * @returns {*}
 */
const activeAdditionalOptions = async () => {
  // Await 20 miliseconds
  await new Promise(r => setTimeout(r, 20));
  // Get checkbox US citizenship
  const userCitizenshipCountry = fieldAttribute(
    'data-unique-name', userCitizenshipCountryField);

  const parentUserCitizenshipCountry = userCitizenshipCountry.parentElement;
  // get additional results option
  const additionalResultsOption = parentUserCitizenshipCountry.querySelector(`div a.dropdown-item.rbt-menu-pagination-option`);
  if (additionalResultsOption) {
    // click additional result option and call again the function until select have not more additional results option.
    additionalResultsOption.click();
    activeAdditionalOptions(parentUserCitizenshipCountry);
  }
}


/**
 * Receives a field object and return its uniqueName with the id added.
 *
 * @param {Object} field
 * @returns {String}
 */
export const getUniqueName = (field) => field?.unique_name + '__id__' + field?.id

/**
 * Check if a request was sent and is evaluating.
 *
 * @param {Object} dataProductRequest List of requests
 * @param {Object} code               Code of product requested (Online Banking, Credirroll, etc)
 * @returns {Boolean}
 */
export const checkIsSentRequest = (dataProductRequest, code) => {
  const boolStatus = dataProductRequest?.some(
    item => (((
      item.status.unique_description === statusRequest.processing || item.status.unique_description === statusRequest.sent) && (
        item.application_origin.unique_name === code)) || item.status.unique_description === statusRequest.sent));
  return boolStatus;
}

/**
 * Check if a request was success or rejected.
 *
 * @param {Object} dataProductRequest List of requests
 * @param {Object} productRequestId   Product Request ID of actual request.
 * @returns {Boolean}
 */
export const isActualRequestSuccessOrRejected = (dataProductRequest, productRequestId) => {
  const productRequest = dataProductRequest.find(item => item.id === productRequestId);
  return (productRequest?.status?.unique_description === statusRequest.success || productRequest?.status?.unique_description === statusRequest.rejected);
}

/**** Local functions  ****/
/**
 * Get the mask of a field
 * @param {String} mask
 * @returns
 */
const getMask = mask => {
  const { actualLanguage } = store.getState().base;
  const { defaultValuesTier } = store.getState().tier;
  const { [`data_${trimLowerCase(
    actualLanguage)}`]:staticData } = store.getState().staticData;
  const maskField = JSON.parse(mask);
  let response = { valid: false };

  if (['text', 'value'].includes(maskField?.type)) {
    response = maskField;
    response.valid = true;
  }

  if (maskField?.type === 'static_data') {
    if (maskField?.fields) {
      let data = staticData[maskField.name];

      Array.from(maskField.fields).forEach(item => {
        const field = fieldUniqueName(item.name);
        let valueField = field?.value;
        if (field?.dataset?.action) {
          const action = JSON.parse(field.dataset.action);
          valueField = (action?.isTypeahead) ? field.dataset.codeId : valueField;
        }
        if (notUndefinedNull(valueField)) {
          if (valueField && valueField !== hiddenField) {
            data = data.filter(element => trimLowerCase(element[
              item.value]) === trimLowerCase(valueField));
          }
        }
      });

      if (data.length !== 1) {
        Array.from(maskField.fields).forEach(item => {
          const field = fieldUniqueName(item.name);
          if (notUndefinedNull(field?.name)) {
            if (defaultValuesTier?.[field.name] && defaultValuesTier?.[
              field.name] !== hiddenField) {
              data = data.filter(element => trimLowerCase(element[
                item.value]) === trimLowerCase(defaultValuesTier[field.name]));
            }
          }
        });
      }

      if (data.length === 1) {
        data = data.pop();
        if (data.mask) {
          response = data.mask;
          response.valid = true;
        }
      }
    }
  }

  return response;
};



