/**
 * Types to user
 */
export const USER_APP_ORIGIN = '[USER] APP ORIGIN';
export const USER_APPLICATIONS = '[USER] APPLICATIONS';
export const USER_CLEAR = '[USER] CLEAR';
export const USER_CODE_APP = '[USER] CODE APP';
export const USER_FILES = '[USER] FILES';
export const USER_LOGIN = '[USER] LOGIN';
export const USER_LOGOUT = '[USER] LOGOUT';
export const USER_NAVIGATE = '[USER] NAVIGATE';
export const USER_PROFILE = '[USER] MODULE PROFILE';
export const USER_SAVE_REQUEST_TOKEN = '[USER] SAVE REQUEST TOKEN';
export const USER_SET_HELP_SECTION_OPEN = '[USER] SECTION OPEN';
export const USER_SET_PASSWORD = '[USER] SET PASSWORD';
export const USER_VALIDATED_PHONE = '[USER] VALIDATED PHONE';
export const USER_VERIFICATION = '[USER] VERIFICATION';
export const USER_DATA_SUMMARY = '[USER] DATA SUMMARY';
export const USER_SELECTED_PRODUCT = '[USER] SELECTED PRODUCT';
export const USER_INFO_PRODUCT_REQUEST = '[USER] INFO PRODUCT REQUEST';
export const USER_TIME_REFRESH = '[USER] INFO TIME REFRESH';
export const USER_PRODUCT_REQUEST = '[USER] PRODUCT REQUEST';
export const USER_CURRENTLY_VALIDATING_PHONE = '[USER] VALIDATING PHONE'