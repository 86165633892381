import i18n from '../translations/i18n';
import * as yup from 'yup';
import { baseField, hiddenField, hiddenNumber, hiddenCode, paramsAdd, validateSMS, fileStatus } from '../constants';
import { sendGetEndpointData, setLoader, setMessage } from '../redux/actions';
import {
  fieldUniqueName,
  trimLowerCase,
  fieldAttribute,
  notUndefinedNull,
  isEmpty,
  isUndefinedNull
} from './shared';
import { getShowField, sendData } from './base';
import { store } from '../redux/store/store';
import { addValue } from './edit';

/**** Export functions  ****/
/**
 * Get country code to send sms
 * @param {Object} values
 * @returns
 */
export const getValidateSms = values => {
  const { actualLanguage } = store.getState().base;
  const { country:countries, field:fields } = store.getState().staticData[
    `data_${trimLowerCase(actualLanguage)}`];

  const validateSmsElements = [];
  Array.from(document.querySelectorAll('[data-action]')).forEach(elem => {
    let action = (elem.dataset?.action) ? JSON.parse(elem.dataset?.action) : '';
    if (action?.validate === validateSMS) {
      validateSmsElements.push(elem);
    }
  });
  // Finding 'main' phone to validate
  let mainPhone = Array.from(validateSmsElements).filter(elem =>
    trimLowerCase(elem.name).includes('main') && !trimLowerCase(
      elem.name).includes('country') && trimLowerCase(elem.name).includes('number') );
  mainPhone = mainPhone.find(field => values[field.name] === true).name.match(/\d+/)[0]
  mainPhone = `block${mainPhone}`

  let nameCountryCode = Array.from(validateSmsElements).filter(elem =>
    trimLowerCase(elem.name).includes('country') && trimLowerCase(elem.name).split('__')[0].includes(mainPhone));
  let phoneNumber = Array.from(validateSmsElements).filter(elem =>
    trimLowerCase(elem.name).includes('phone') && !trimLowerCase(
      elem.name).includes('main') && !trimLowerCase(
        elem.name).includes('country') && trimLowerCase(elem.name).split('__')[0].includes(mainPhone));

  nameCountryCode = nameCountryCode.pop();
  const idValue = nameCountryCode.dataset?.idValue;
  nameCountryCode = values[nameCountryCode.name];
  phoneNumber = phoneNumber.pop().name;

  let maskField = fields.filter(
    elem => `${trimLowerCase(elem.unique_name)}__id__${trimLowerCase(
      elem.id)}` === trimLowerCase(phoneNumber));
  maskField = maskField?.pop();
  maskField = (maskField.mask) ? JSON.parse(maskField.mask) : '';
  maskField = (maskField?.replace) ? maskField?.replace : '';
  phoneNumber = values[phoneNumber];
  phoneNumber = phoneNumber.replaceAll(maskField, '');

  let countryCode = countries.filter(country => {
    return (trimLowerCase((country[idValue])) === trimLowerCase(nameCountryCode) || trimLowerCase(country['code']) === trimLowerCase(nameCountryCode))
  })
  let country = (countryCode.length > 0) ? countryCode?.pop() : null;
  countryCode = country?.code
  let countryId = country?.country_id

  return { phoneNumber, countryCode, countryId };
};

/**
 * Get value with type is static-data
 * @param {Object} field
 * @param {Object} data
 * @param {String} type
 * @param {Boolean} isValue
 * @param {Object} fieldValue
 * @returns
 */
export const handleData = (field, data, type, isValue, fieldValue=null) => {
  let valuesData, defaultValue;

  if (isValue) {
    valuesData = (notUndefinedNull(field.function_get_values)) ? JSON.parse(
      field.function_get_values) : null;
    defaultValue = (notUndefinedNull(field.default_value)) ? JSON.parse(
      field.default_value) : null;
  } else {
    if (type === 'base' || type === 'person_data') {
      valuesData = (notUndefinedNull(field.default_value)) ? JSON.parse(
        field.default_value) : null;
      defaultValue = valuesData;
    }
    if (type === 'static_data') {
      defaultValue = (notUndefinedNull(field.action)) ? JSON.parse(
        field.action) : null;
      if (defaultValue?.field?.search) {
        defaultValue = defaultValue.field.search;
        defaultValue.search = fieldValue;
        valuesData = (notUndefinedNull(field.function_get_values)) ? JSON.parse(
          field.function_get_values) : null;
      } else {
        valuesData = (notUndefinedNull(field.action)) ? JSON.parse(
          field.action) : null;
        defaultValue = valuesData;
      }
    }
  }

  const newValue = getValueData(valuesData, defaultValue, type, data, field);

  return newValue;
};

/**
 * Get action to text
 * @param {Object} field
 * @param {Object} element
 * @param {String} value
 * @param {Function} setValue
 */
export const actionTextInput = async (field, element, value, setValue) => {
  let response;
  const action = JSON.parse(field.action);
  action.compare = value;
  const base = store.getState().base;
  const { actualLanguage } = base;
  const description = `description_${trimLowerCase(actualLanguage)}`

  if (action?.validate !== 'endpoint') {
    response = await sendData(action?.validate, action, false, setValue);
    const allFieldsEmpty = Object.values(response).every(res => isUndefinedNull(res?.value) || res?.value?.length === 0)
    if (!response || (allFieldsEmpty && action?.put)) {
      store.dispatch(setMessage(
        i18n.t("common.dataIncorrect", {name: field?.[description]}), 'error'));
    } else {
      Array.from(response).forEach(item => {
        if (item.uniqueName) {
          setValue(item.uniqueName, item.value);
        }
      });
    }
  } else {
    store.dispatch(setLoader(true));
    response = await sendGetEndpointData(action);
    store.dispatch(setLoader(false));
    const spanElement = fieldAttribute('name', element?.name, 'span');
    if (spanElement) spanElement.remove();
    if (response[action.error]) {
      const spanField = baseField.spanFieldError(
        element?.name, i18n.t('common.routingNumberNotFound'));
      element.insertAdjacentHTML('afterend', spanField);
      const actionResponse = action.response
      const responseField = fieldUniqueName(actionResponse?.field)
      const uniqueName = responseField.name
      setValue(uniqueName, '')
      if (actionResponse?.attributes) {
        Array.from(actionResponse.attributes).forEach(item => {
          if (item.name === 'disabled') {
            responseField.disabled = true;
          }
        });
      }
    } else {
      const result = response[action.success];
      if (action?.response?.type === 'select') {
        addSelectOption(action.response, result, setValue);
      }
    }
  }
};

/* Given an 'admin' field Object, returns a boolean determining whether or not its module is hidden */
export const isModuleHidden = (field) => {
  const htmlField = fieldUniqueName(field?.unique_name)
  const module = htmlField?.parentElement?.parentElement

  if (!module) return false

  if (module?.id?.includes('group') && module?.className?.includes('d-none')){
    return true
  } else {
    return false
  }
}

/**
 * Create schema objects for useForm
 * @param {String}   action
 * @param {Object}   tierFieldState
 * @returns
 */
export const getSchemaTier = (action, tierFieldState, codeTier) => {
  const schemaTier = {};
  const defaultValues = {};
  const { tierCategories } = store.getState().tier

  tierFieldState?.forEach(field => {
    const group = field?.unique_name?.split('__').shift();
    const data = (field?.storage_data_key) ? JSON.parse(field.storage_data_key) : null;
    const mask = (field?.mask) ? JSON.parse(field?.mask) : null

    const [currentCategory] = tierCategories?.filter(category => category?.id === field.category.field_category) || []
    if (group !== 'modal' && getShowField(field) && (!isModuleHidden(field) || codeTier === fileStatus.rejected || codeTier === fileStatus.expired) && field.data_type !== 'block' && currentCategory?.active) {
      const uniqueName = `${field.unique_name}__id__${field.id}`;
      const dataType = trimLowerCase(field.data_type);
      const isObjectSelection = dataType.includes('_selection');
      const isText = (dataType === 'text' || dataType === 'selection' ||
        dataType === 'textarea' || dataType === 'media_upload');
      let value;

      if (action === paramsAdd ) {
        // Trying to get 'previous' value, if any.
        value = addValue(field, false);
        // If there's previous value, and the field belongs to the 'autosave' category use it
        if(value && (value !== hiddenCode) && (value !== hiddenNumber) && (value !== hiddenField) && (currentCategory?.autosave === true)){
          const storage = (field?.storage_data_key) ? JSON.parse(field.storage_data_key) : null;
          value = (dataType === 'checkbox' && value === hiddenField && trimLowerCase(storage?.take) !== 'storage') ? false : value;
        } else { 
        // Else, get default value
          value = getValue(field, false);
        }
      } else {
        const storage = (field?.storage_data_key) ? JSON.parse(
          field.storage_data_key) : null;
        value = addValue(field);
        value = (dataType === 'checkbox' && value === hiddenField && trimLowerCase(
          storage?.take) !== 'storage') ? false : value;
      }
      value = (field.col_size?.includes(
        'd-none') && isText && isEmpty(value)) ? hiddenField : value;
      let requestType = JSON.parse(field.request_type);
      let isNullable = requestType?.letSetNull ? requestType?.letSetNull : false;
      defaultValues[uniqueName] = (isNullable && (isUndefinedNull(value) || isEmpty(value))) ? null : value;

      let isSchema = false;
      let schemaField = (isObjectSelection) ?
          yup.array()
          .test('isArray', 'fieldRequired', (value) => Array.isArray(value))
          .of(yup.string().required()).nullable() :
          yup.string();

      if (notUndefinedNull(field.max_length)) {
        isSchema = true;
        if (dataType !== 'number') {
          schemaField = schemaField.max(
            field.max_length, `fieldMax__${field.max_length}`);
        } else {
          schemaField = schemaField.test(
            'is-number',
            'integer',
            (value => !Number.isNaN(Number(value)))
          );
        }
      }

      if (notUndefinedNull(field.min_length) || notUndefinedNull(mask?.min_length)) {    
        isSchema = true;
        if (dataType !== 'number') {
          if (notUndefinedNull(field.min_length)){
            schemaField = schemaField.min(field.min_length,`fieldMin__${field.min_length}`);
          } 
          if (notUndefinedNull(mask?.min_length)){
            schemaField = schemaField.min(Number(mask?.min_length),`fieldMin__${Number(mask?.min_length)}`);
          }
        }
      }

      if (dataType === 'email') {
        isSchema = true;
        schemaField = schemaField.email('emailInvalid');
      }

      if (dataType === 'url') {
        isSchema = true;
        const re = /^((ftp|http|https):\/\/)?(www\.)?(?!.*(ftp|http|https|www\.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]*)*(\/?\w*\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/gmi;
        if (field.required) {
          schemaField = schemaField.matches(re, 'urlInvalid');
        } else {
          schemaField = schemaField.matches(re, {message: 'urlInvalid', excludeEmptyString: true});
        }
        if (isNullable === 'true') {
          schemaField = schemaField.nullable();
        }
      }

      if (field.required) {
        isSchema = true;
        if (isObjectSelection) {
          schemaField = schemaField.min(1, 'fieldRequired');
        } else {
          schemaField = schemaField.required('fieldRequired');
        }
      }

      if (isSchema) {
        schemaTier[uniqueName] = schemaField;
      }
    }
  });

  return { schemaTier, defaultValues };
};

/**
 * Get value
 * @param {Object} field
 * @returns
 */
export const getValue = field => {
  let value = (notUndefinedNull(field?.default_value)) ? JSON.parse(
    field.default_value) : '';
  let isValue = false;

  if (!value?.fields) {
    if (value?.type === 'static' || value?.type === 'equal') {
      value = (value?.boolean) ? (trimLowerCase(
        value.boolean) === 'true') : value;
      value = (value?.text) ? value.text : value;
      isValue = true;
    }

    if (value?.type === 'static_data') {
      const { requestLanguage } = store.getState().base;
      value = handleData(
        field, store.getState().staticData[`data_${trimLowerCase(
          requestLanguage)}`], value.type, true);
      isValue = true;
    }

    if (value?.type === 'base') {
      value = handleData(field, store.getState().base, value.type, false);
      isValue = true;
    }

    if (value?.type === 'person_data') {
      value = handleData(
        field, store.getState().personData.data, value.type, false);
      isValue = true;
    }
  }

  value = (isValue) ? value : '';

  return value;
};

/**** Local functions  ****/
/**
 * Add option in select
 * @param {Object} element
 * @param {Object} result
 * @param {Function} setValue
 */
const addSelectOption = (element, result, setValue) => {
  const selectParent = fieldUniqueName(element?.field);
  if (selectParent) {
    const newOption = document.createElement('option');
    newOption.id = result[element?.id];
    newOption.value = result[element?.id];
    newOption.text = result[element?.text];
    newOption.setAttribute('data-type', 'new');
    selectParent.appendChild(newOption);
    setValue(selectParent.name, newOption.id);
    selectParent.selectedIndex = selectParent.lastChild.index;
    if (element?.attributes) {
      Array.from(element.attributes).forEach(item => {
        if (item.name === 'disabled') {
          selectParent.disabled = true;
        }
      });
    }
  }
};

/**
 * Get value to action or default value
 * @param {Object} valuesData
 * @param {Object} defaultValue
 * @param {String} type
 * @param {Object} data
 * @param {Object} field
 * @returns
 */
const getValueData = (valuesData, defaultValue, type, data, field) => {
  let newValue = '';

  if (notUndefinedNull(valuesData) && notUndefinedNull(
    defaultValue) && type === 'static_data') {
    if (data[valuesData?.name]) {
      newValue = Array.from(data[valuesData?.name]).filter(elem =>
        trimLowerCase(elem[valuesData?.code]) === trimLowerCase(
          defaultValue?.search));
      newValue = newValue?.pop();
      newValue = (newValue) ? newValue[defaultValue?.value] : newValue;
    }
  }

  if (notUndefinedNull(valuesData) && notUndefinedNull(
    defaultValue) && type === 'base') {
    newValue = (data[valuesData?.name]) ? data[valuesData?.name][
      valuesData?.value] : newValue;
  }

  if (notUndefinedNull(valuesData) && notUndefinedNull(
    defaultValue) && type === 'person_data') {
    if (defaultValue?.name) {
      let newData = data[defaultValue.name];
      valuesData = (notUndefinedNull(field.function_get_values)) ? JSON.parse(
        field.function_get_values) : null;

      if (defaultValue?.parent) {
        newData = newData[defaultValue.parent];
      }
      newValue = newData[defaultValue?.value];

      if (notUndefinedNull(valuesData)) {
        if (valuesData.type === 'static_data') {
          const aux = { 'search': String(newValue), 'value': valuesData.value };
          const { requestLanguage } = store.getState().base;
          newValue = getValueData(
            valuesData, aux, valuesData.type, store.getState().staticData[
              `data_${trimLowerCase(requestLanguage)}`], field);
        }
      }
    }
  }

  return newValue;
};