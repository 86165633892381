import { store } from '../redux/store/store';
import { dataURItoBlob, getShowField } from './base';
import {
  hiddenCode,
  hiddenField,
  hiddenNumber,
  mainDocument,
  paramsAdd,
  paramsEdit,
  sOption
} from '../constants';
import {
  fieldAttribute,
  fieldUniqueName,
  isEmpty,
  isUndefinedNull,
  notUndefinedNull,
  removeMask,
  trimLowerCase,
} from './shared';

/**** Export functions  ****/
/**
 * Validate type media to request
 * @param {Object} values
 * @param {Object} fields
 * @param {String} userId
 * @returns
 */
export const validateRequestType = (values, fields, userId) => {
  const { actualLanguage } = store.getState().base;
  const { document_type: documentTypes } = store.getState().staticData[
    `data_${trimLowerCase(actualLanguage)}`];
  const optionMedia = new Set(['media_video', 'media_upload', 'media_signature']);
  const formMedia = new FormData();
  const fieldAction = getFieldEdit(fields, values);
  let groups = '';
  let elements = {};
  let groupRequest = [];
  let isMedia = false;
  let isElement = false;

  if (fieldAction.isSave) {
    Array.from(fields).forEach(item => {
      if (Array.from(groupRequest).indexOf(item.group) < 0 && item.group !== 'modal') {
        groupRequest.push(item.group);
      }
    });
    // 'groupRequest' is an array holding all of the current groups
    groupRequest.sort();
    Array.from(groupRequest).forEach(group => {
      // 'fields' for the current group
      let fieldsRequest = Array.from(fields).filter(field =>
        trimLowerCase(field.group) === trimLowerCase(group) && trimLowerCase(
          field.group) !== 'modal');
      fieldsRequest = sortObjectLength(fieldsRequest, 'request_type', 'type');
      let index = 0;

      // Iterate over fields
      Array.from(fieldsRequest).forEach(field => {
        let requestTypes = JSON.parse(field.request_type);
        let isNullable = requestTypes?.letSetNull ? requestTypes?.letSetNull : false
        let valueItem = values[`${field.unique_name}__id__${field.id}`];
        const dataType = field.data_type;
        const actions = (field.action) ? JSON.parse(field.action) : '';
        const defaultValue = (notUndefinedNull(field.default_value)) ? JSON.parse(
          field.default_value) : '';

        if (defaultValue?.type === 'equal') {
          let fieldEqual = Array.from(fields).filter(
            item => item.unique_name === defaultValue.field);
          fieldEqual = fieldEqual.pop();
          const elementEqual = fieldUniqueName(fieldEqual.unique_name);
          if (elementEqual) {
            valueItem = (defaultValue?.type === 'equal') ? values[
              `${fieldEqual.unique_name}__id__${fieldEqual.id}`] : valueItem;
            valueItem = ((fieldEqual.data_type === 'selection') && (
              elementEqual.selectedIndex >= 0)) ? ((!isEmpty(elementEqual.children[
                elementEqual.selectedIndex].value)) ? elementEqual.children[
                  elementEqual.selectedIndex].text : valueItem) : valueItem;
          }
        }

        if (trimLowerCase(valueItem) !== hiddenField && trimLowerCase(
          valueItem) !== hiddenNumber && (notUndefinedNull(valueItem) || isNullable) && !isEmpty(String(
            valueItem)) && (!actions?.send || actions.send === 'false') && trimLowerCase(
              valueItem) !== hiddenCode && field.data_type !== 'block' && getShowField(
                field) && field.request_type && valueItem !== 
                  hiddenField && valueItem !== sOption) {
          const uniqueName = requestTypes.name;
          if (!optionMedia.has(dataType)) {
            let value = {};
            requestTypes = requestTypes?.type?.split(';');
            valueItem = (trimLowerCase(valueItem) === 'false') ? false : valueItem;
            valueItem = (trimLowerCase(valueItem) === 'true') ? true : valueItem;
            valueItem = removeMask(field, valueItem);

            if (trimLowerCase(dataType) === 'checkbox_selection' ||
              trimLowerCase(dataType) === 'media_selection') {
              const imagesSelect = [];
              const imageName = field.unique_name.split(
                '__id__').shift().split('__').pop();
              Array.from(valueItem).forEach(item => {
                const newObject = {
                  user: userId
                };
                newObject[imageName] = item;
                imagesSelect.push(newObject);
              });
              valueItem = imagesSelect;
            }

            if (trimLowerCase(field.data_type) === 'date') {
              try {
                valueItem = JSON.parse(valueItem);
              } catch (error) {
                console.info('JSON with date in files');
              }
            }

            if (notUndefinedNull(actions?.parse_to_float) && actions?.parse_to_float) {
              try {
                valueItem = valueItem.replaceAll(',', '');
                valueItem = Number.parseFloat(valueItem);
              } catch (error) {
                console.info("Value can't be parsed to float");
              }
            }

            value[uniqueName] = valueItem;
            if (isUndefinedNull(requestTypes)) {
              elements[uniqueName] = valueItem;
              isElement = true;
            } else {
              const newGroup = field.unique_name.split('__').shift();
              const [oneRequestTypes] = requestTypes;
              let key = oneRequestTypes.split(':').shift();
              let subObject = {};
              if (requestTypes.length > 1) {
                Array.from(requestTypes).forEach((elem, index) => {
                  if (index > 0) {
                    const obj = {};
                    let typeElem = elem.split(':').pop();
                    elem = elem.split(':').shift();
                    const isUser = elem.includes('__user');
                    elem = elem.replace('__user', '');
                    typeElem = typeElem.replace('__user', '');
                    if (requestTypes[index+1]) {
                      subObject[elem] = {};
                      subObject[elem][uniqueName] = valueItem;
                    } else {
                      const keyElem = Object.keys(subObject).pop();
                      if (isUser) obj.user = userId;
                      obj[uniqueName] = valueItem;
                      subObject.type = typeElem;
                      if (keyElem) {
                        subObject[keyElem][elem] = obj;
                      } else {
                        subObject[elem] = obj;
                      }
                    }
                  }
                });
              }
              const typeElem = oneRequestTypes.split(':').pop().replace(
                '__user', '');
              if (typeElem === 'array') {
                const result = getArrayElements(dataType, elements, groups,
                  index, key, newGroup, requestTypes, subObject, uniqueName,
                  userId, value, valueItem);
                elements = result.elements;
                groups = result.groups;
                index = result.index;
                isElement = true;
              }
            }
          } else {
            const file = fieldAttribute(
              'name', `${field.unique_name}__id__${field.id}`);
            if (file) {
              const [files] = file?.files;
              if (files?.name) {
                const extension = files.name.split('.').pop();
                const nameFile = `${uniqueName}_${userId}.${extension}`;
                formMedia.append(uniqueName, files, nameFile);
                isMedia = true;
              }
            } else {
              const digitalSign = fieldAttribute(
                'data-media-name', `${field.unique_name}__id__${field.id}`);
              if (digitalSign) {
                const dataUrl = digitalSign.dataset?.media;
                if (dataUrl) {
                  const nameFile = `${uniqueName}_${userId}.png`;
                  const fileSign = dataURItoBlob(dataUrl, nameFile);
                  formMedia.append(uniqueName, fileSign, nameFile);
                  isMedia = true;
                }
              }
            }
          }
        }
      });
    });

    if (elements?.commercial_references){
      let arrayCommercial = [];
      elements?.commercial_references.map((ref, index) => {
        if (ref.full_name && ref.phone && ref.client_residence_country_id && ref.client_reference_type_id){
          arrayCommercial.push({
            ...ref,
            relationship: "Comercial",
          });
        } else if (ref.full_name && ref.phone && ref.client_residence_country_id) {
          const newReference = {
            ...ref,
            relationship: "Comercial",
            full_name: ref.full_name || "",
            phone: ref.phone || "",
            client_residence_country_id: ref.client_residence_country_id || "",
            client_reference_type_id: elements?.commercial_references[index + 1].client_reference_type_id,
          };
          arrayCommercial.push(newReference);
        }
        return null;
      });
      elements['commercial_references'] = arrayCommercial;
    }

    if (elements?.documents) {
      elements.documents = Array.from(elements?.documents).filter(
        item => notUndefinedNull(item.description));
      let isMain = false;
      Array.from(mainDocument).forEach(item => {
        const [documentType] = Array.from(documentTypes).filter(
          elem => trimLowerCase(elem.code) === trimLowerCase(item));
        if (documentType?.code) {
          const index = Array.from(elements.documents).findIndex(
            elem => trimLowerCase(elem.client_document_type_id) === trimLowerCase(
              documentType.document_type_id));
          if (index >= 0) {
            elements.documents[index].main = false;
            if (!isMain) {
              elements.documents[index].main = true;
              isMain = true;
            }
          }
        }
      });
    }
  }

  return {
    elements,
    formMedia,
    isMedia,
    isElement,
    migratable: fieldAction.migratable
  };
};

/**** Local functions  ****/
/**
 * Get array to send object
 * @param {String} dataType      - Field datatype. e.g. 'text'
 * @param {Object} elements      - elements that will be send in the request payload
 * @param {String} groups        - String containing all of the groups that have been attached to the 'elements' object so far (e.g. "" || ";jobs" || ";jobs;transactionalities")
 * @param {String} index         - Index; always comes as 0
 * @param {String} key           - String representing the final 'key' for the value of this input in the 'elements' object. (e.g. 'transactionalities')
 * @param {String} newGroup      - String representing the 'group' for the field.  (e.g. 'transactionalities')
 * @param {Array}  requestTypes  - Array of Strings representing the 'request types' types.  (e.g. ['transactionalities:array_user'])   
 * @param {Object} subObject     - Object representing a subobject to be nested inside the main 'key'. Empty if 'requestTypes.length === 1'.
 * @param {String} uniqueName    - Unique name that will receive the current value in the 'elements' object.
 * @param {String} userId        - User's id
 * @param {Object} value         - Object with the key name and value for the current field (e.g. { "address": "new zeland street" }) 
 * @param {String} valueItem     - String representing the value for the field              (e.g. 'new zeland street')
 * @returns
 */
const getArrayElements = (
  dataType,
  elements,
  groups,
  index,
  key,
  newGroup,
  requestTypes,
  subObject,
  uniqueName,
  userId,
  value,
  valueItem
) => {
  const [oneRequestTypes] = requestTypes;
  const isUserKey = oneRequestTypes.includes('__user');
  const obj = {};
  const optionSelection = new Set(['image_selection', 'checkbox_selection']);
  if (isUndefinedNull(elements[key])) {
    if (optionSelection.has(dataType)) {
      elements[key] = valueItem;
    } else {
      if (requestTypes.length > 1) {
        elements[key] = [subObject];
      } else {
        if (isUserKey) value.user = userId;
        elements[key] = [value];
      }
    }
    groups = `${groups};${newGroup}`;
  } else {
    index = elements[key].length - 1;
    if (!groups.includes(`${newGroup}`)) {
      groups = `${groups};${newGroup}`;
      if (Object.keys(subObject).length > 0) {
        elements = getSubObject(elements, index, key, requestTypes, subObject);
      } else {
        obj[uniqueName] = valueItem;
        if (isUserKey) obj.user = userId;
        elements[key].push(obj);
      }
    } else {
      if (Object.keys(subObject).length > 0) {
        elements = getSubObject(elements, index, key, requestTypes, subObject);
      } else {
        if (isUserKey) elements[key][index].user = userId;
        elements[key][index][uniqueName] = valueItem;
      }
    }
  }

  return { elements, groups, index };
};

/**
 * Get second nevel to object
 * @param {Object} elements
 * @param {String} index
 * @param {String} key
 * @param {String} requestTypes
 * @param {Object} subObject
 * @returns
 */
const getSubObject = (elements, index, key, requestTypes, subObject) => {
  const keyElem = Object.keys(subObject).filter(item => item !== 'type').pop();
  if (isUndefinedNull(elements[key][index][keyElem])) {
    if (subObject.type === 'array') {
      elements[key][index][keyElem] = [subObject[keyElem]];
    } else {
      elements[key][index][keyElem] = subObject[keyElem];
    }
  } else {
    if (requestTypes.length > 2) {
      // const keyObj = Object.keys(elements[key][index][keyElem]).pop() ->> Using the 'pop' method to get the keyObj would get the key value correctly 
      // only for the first field that runs into the 'getSubObject' function.

      // Now that we get the 'keyObj' out of our 'requestTypes', we can get the 'keyObj' correctly for all of the fields
      // e.g. requestTypes = ['person_jobs_data:array__user', 'branch:object__user', 'company:object__user'])
      //            keyObj = 'company'
      const keyObj = [...requestTypes].pop().split(':')[0]

      if (subObject.type === 'array') {
        // Adding value to 'keyObj' (third 'requestTypes' parameter) 
        elements[key][index][keyElem][keyObj] = [
          ...elements[key][index][keyElem][keyObj],
          ...subObject[keyElem][keyObj]];
        // Adding value to 'keyElem' (second 'requestTypes' parameter)
        elements[key][index][keyElem] = [
          ...elements[key][index][keyElem],
          ...subObject[keyElem][keyObj]
        ]
      } else {
        // Adding value to 'keyObj' (third 'requestTypes' parameter) 
        elements[key][index][keyElem][keyObj] = {
          ...elements[key][index][keyElem][keyObj],
          ...subObject[keyElem][keyObj]};
        // Adding value to 'keyElem' (second 'requestTypes' parameter)
        elements[key][index][keyElem] = {
          ...elements[key][index][keyElem],
          ...subObject[keyElem][keyObj]
        }

      }
    } else {
      if (subObject.type === 'array') {
        let indexObj
        if (typeof elements[key][index][keyElem] === 'object') {
          indexObj = Object.keys(elements[key][index][keyElem]).length - 1;
          elements[key][index][keyElem][indexObj] = {...subObject[keyElem], ...elements[key][index][keyElem][indexObj]};
        } else {
          indexObj = elements[key][index][keyElem].length - 1;
          elements[key][index][keyElem][indexObj] = {
            ...elements[key][index][keyElem][indexObj], ...subObject[keyElem]};
        }
      } else {
        elements[key][index][keyElem] = {
          ...elements[key][index][keyElem], ...subObject[keyElem]};
      }
    }
  }

  return elements;
};

/**
 * Sort Object
 * @param {Object} elements
 * @param {String} item
 * @param {String} name
 * @returns
 */
const sortObjectLength = (elements, item, name) => {
  elements.sort(function(a, b) {
    if (a[item] && b[item]) {
      const groupA = JSON.parse(a[item]);
      const groupB = JSON.parse(b[item]);
      if (groupA[name] && groupB[name]) {
        if (groupA[name].split(';').length > groupB[name].split(';').length) {
          return 1;
        }
        if (groupA[name].split(';').length < groupB[name].split(';').length) {
          return -1;
        }
        // a must be equal to b
        return 0;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  });

  return elements;
};

/**
 * Get if fields is edit and change values
 * @param {Object} field
 * @param {Object} values
 * @param {Boolean} migratable
 * @returns
 */
const getFieldEdit = (fields, values, migratable) => {
  const { action, tierFieldsEdit } = store.getState().tier;
  const { route } = store.getState().base;
  let isSave = false;
  let isMigrate = false;

  Array.from(fields).forEach(field => {
    const uniqueName = `${field.unique_name}__id__${field.id}`;

    if (!isSave || !isMigrate) {
      if ((action === paramsEdit && field.editable_after_migrate)) {
        isSave = (trimLowerCase(tierFieldsEdit[uniqueName]) !== trimLowerCase(
          values[uniqueName]));
      }

      if (action === paramsAdd) isSave = true;
      isMigrate = (!migratable) ? (isSave && field.migratable) : migratable;

      if (route?.codeTier === 'rejected' || route?.codeTier === 'expired') {
        isSave = (trimLowerCase(tierFieldsEdit[uniqueName]) !== trimLowerCase(
          values[uniqueName]));
        isMigrate = true;
      }
    }
  });

  return { isSave, migratable: isMigrate };
};