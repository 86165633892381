import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import {
  defaultPhone,
  defaultValuesEmail,
  defaultValuesProfilePassword,
  profile,
  schemaEmail,
  schemaPhone,
  schemaProfilePassword,
  siteMap
} from '../constants';
import {
  sendEditionToken,
  smsToken,
  setModuleProfile,
  sendEmailToken,
  setPassword,
  setLoader,
  setLogout,
  changePasswordNoHash,
  setMessage
} from '../redux/actions';
import styled from 'styled-components';
import { isUndefinedNull, trimLowerCase } from '../helpers';

/**
 * Hook to get the values to Section
 * @param {String} codeCard
 * @param {String} editModule
 * @param {Function} reset
 * @param {String} moduleForm
 * @param {Object} setRequestEdit
 * @returns
 */
export const useSection = (
  codeCard,
  editModule,
  reset,
  moduleForm,
  setRequestEdit
) => {
  const dispatch = useDispatch();
  const { data: { bifrost_user_id: userIdBifrost } } = useSelector(
    state => state.personData);

  const editRequest = async () => {
    dispatch(setLoader(true));
    reset();
    dispatch(setModuleProfile(codeCard));
    dispatch(sendEditionToken({
      'bifrost_user_id': userIdBifrost
    }))
      .then(({ error }) => (!error) && setRequestEdit(true))
      .catch(error => console.error('editRequest in useSection: ', error));
    dispatch(setLoader(false));
  };

  const cancelEditRequest = () => {
    const formEdit = document.getElementById(profile[moduleForm]);
    const moduleEdit = document.getElementById(profile[editModule]);
    formEdit?.classList.add('d-none');
    moduleEdit?.classList.remove('d-none');
  };

  return { cancelEditRequest, editRequest };
};

/**
 * Hook to get the values to Section Phone
 * @param {Object} setRequestTokenForce
 * @returns
 */
export const usePhone = setRequestTokenForce => {
  const dispatch = useDispatch();
  const { data: { bifrost_user_id: userIdBifrost } } = useSelector(
    state => state.personData);
  const { loading } = useSelector(state => state.base);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue
  } = useForm({
    resolver: yupResolver(schemaPhone),
    defaultValues: defaultPhone
  });

  const onSubmit = values => {
    const {
      phone_number: phoneNumber,
      phone_number_country: phoneNumberCountry
    } = values;
    reset();
    dispatch(smsToken(phoneNumberCountry, phoneNumber, userIdBifrost))
      .then(({ error }) => (!error) && setRequestTokenForce(true))
      .catch(error => console.error('onSubmit in usePhone: ', error));
  };

  return { register, onSubmit, errors, handleSubmit, loading, reset, setValue };
};

/**
 * Hook to get the values to Section Phone
 * @param {Object} setRequestTokenForce
 * @returns
 */
export const useEmail = setRequestTokenForce => {
  const dispatch = useDispatch();
  const { data: { bifrost_user_id: userIdBifrost } } = useSelector(
    state => state.personData);
  const { loading } = useSelector(state => state.base);
  const { register, formState: { errors }, handleSubmit, reset } = useForm({
    resolver: yupResolver(schemaEmail),
    defaultValues: defaultValuesEmail
  });

  const onSubmit = async data => {
    dispatch(setLoader(true));
    reset();
    dispatch(sendEmailToken({
      email: data.email,
      bifrost_user_id: userIdBifrost
    }))
      .then(({ error }) => (!error) && setRequestTokenForce(true))
      .catch(error => console.error('onSubmit in useEmail: ', error));
    dispatch(setLoader(false));
  };

  return { errors, handleSubmit, loading, onSubmit, register, reset };
};

/**
 * Hook to get the values to Change Password
 * @returns
 */
export const useChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { loading } = useSelector(state => state.base);
  const { data: { bifrost_user_id } } = useSelector(state => state.personData);
  const { username, isPassword } = useSelector(state => state.user);
  const { register, formState: { errors }, handleSubmit, reset } = useForm({
    resolver: yupResolver(schemaProfilePassword),
    defaultValues: defaultValuesProfilePassword
  });

  useEffect(() => {
    if (isPassword) {
      navigate(siteMap.login.path2);
      dispatch(setPassword(false));
    }
  }, [isPassword]);

  const onSubmit = async values => {
    try {
      dispatch(setLoader(true));
      reset();
      const { current_password, new_password } = values;
      const data = {
        current_password,
        new_password,
        bifrost_user_id,
        username
      };

      const response = await dispatch(changePasswordNoHash(data));
      if (response && !response.error) {
        dispatch(setLogout());
      } else {
        dispatch(setLoader(false));
      }
    } catch (error) {
      if (!loading) dispatch(setLoader(false));
      console.error('onSubmit in useChangePassword: ', error);
      dispatch(setMessage(t("message.changePasswordNoHash"), 'error'));
    }
  };

  return { errors, handleSubmit, loading, onSubmit, register };
};

/* Hook for the profile's additional phones block */
export const useProfileBlock = ( setRequestEdit, codeCard, phone ) => {
  const dispatch = useDispatch();
  const { data: { bifrost_user_id: userIdBifrost } } = useSelector(
    state => state.personData);
  const { loading, requestLanguage } = useSelector(state => state.base);
  const { country: countries } = useSelector(state => state.staticData[`data_${trimLowerCase(requestLanguage)}`]);
  const phones = phone?.[0]?.phones
  // Here I will create entries, using 'phones' as a reference
  const [entries, setEntries] = useState(phones?.map((phone) => {
    const country = countries.filter(country => country?.country_id === phone?.country_id)[0]
    return {
      key: phone.order,
      main: phone?.main,
      description: phone?.description,
      contact_type: phone?.contact_type,
      country_id: phone?.country_id,
      country_code: country?.code,
      validated: phone?.validated
    }
  }))

  /* Variable that helps determine the 'editing' state for 'additional phones' */
  const [isEditing, setIsEditing] = useState(false)
  /* Variable that controls the 'open' state for the validate SMS Modal */
  const [isOpenValidateSms, setIsOpenValidateSms] = useState(false)
  

  const editRequest = async () => {
    dispatch(setLoader(true));
    dispatch(setModuleProfile(codeCard));
    dispatch(sendEditionToken({
      'bifrost_user_id': userIdBifrost
    }))
      .then(({ error }) => { 
          if(!error){
            setRequestEdit(true) 
            setEntries(phones.map((phone) => {
              const country = countries.filter(country => country?.country_id === phone?.country_id)[0]
              return {
                key: phone.order,
                id: phone?.id,
                main: phone?.main,
                description: phone?.description,
                contact_type: phone?.contact_type,
                country_id: phone?.country_id,
                country_code: country?.code,
                validated: phone?.validated
              }
            }))
          } 
        })
      .catch(error => console.error('editRequest in useProfileBlock: ', error));
    dispatch(setLoader(false));
  };

  const cancelEditRequest = () => {
    setIsEditing(false)
    const formEdit = document.getElementById(profile.moduleFormPhone);
    const moduleEdit = document.getElementById(profile.editModulePhone);
    const moduleEditBtn = document.getElementById(profile.editModulePhones);

    formEdit?.classList.add('d-none');
    moduleEdit?.classList.remove('d-none');
    moduleEditBtn?.classList.remove('d-none');
  }

  return {
    editRequest,
    isEditing,
    setIsEditing,
    cancelEditRequest,
    isOpenValidateSms,
    setIsOpenValidateSms,
    entries, 
    setEntries,
    phones
  }
}

export const PhoneEntry = styled.div`
  border: ${props => props.isEditing ? '1px solid black' : '1px solid gray'};
  border-radius: 4px;
  padding: 10px 4px;
`