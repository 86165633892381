import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar } from './Avatar';
import { Alert, Container, Row } from 'react-bootstrap';
import { StyledDiv, StyledCol, StyledLink, LogOutButton } from './styled';
import { useHomepage, useInfoTiers, useTiers } from '../../hooks';
import { theme } from '../../theme';
import { LinkLogo } from './LinkLogo';
import { useExistantProducts } from '../../hooks';

/**
 * Component with homepage
 * @component
 * @returns
 */
export const Homepage = () => {
  const { t } = useTranslation();
  const {
    code,
    expiredFiles,
    level,
    onLogout,
    rejectedFiles,
    routeExpired,
    routeProducts,
    routeProfile,
    routeRejected,
    username,
    routeRequests,
    routeCreatedProducts
  } = useHomepage();
  useTiers();
  const { seventh } = theme;
  const { lastTier } = useInfoTiers();
  const [show, setShow] = useState(true);
  const { clientProducts } = useExistantProducts();

  return (
    <StyledDiv>
      <LinkLogo />
      <LogOutButton onClick={ onLogout } name="link_logout">
        <h5>{ t("homepage.logout") }</h5>
      </LogOutButton>
      <Container>
        <Row>
          <StyledCol xs={12}></StyledCol>
          <StyledCol xs={12}>
            <Avatar />
          </StyledCol>
          <StyledCol xs={12}>
            <h1 className="mb-2">{ t("homepage.greeting", { username }) }</h1>
            <h4 className="mb-2">
              { t("homepage.code") }&nbsp;<strong>{ code }</strong>
            </h4>
            <h4 className="mb-1">
              { t("homepage.profile") }&nbsp;
              <StyledLink
                className="p-0"
                onClick={ routeProfile }
                color="first"
                xs={12}
                name="link_profile"
              >
                { t("profile.name") }
              </StyledLink>
            </h4>
            <h4 className="mb-1">
              { t("homepage.about") }&nbsp;
              <StyledLink
                className="p-0"
                onClick={ routeProducts }
                color="first"
                xs={12}
                name="link_products"
              >
                { t("common.productsServices") }
              </StyledLink>
            </h4>
            <h4 className="mb-1">
              <StyledLink
                className="p-0"
                onClick={ routeRequests }
                color="first"
                xs={12}
                name="link_products_request"
              >
                { t("common.productRequest") }
              </StyledLink>
            </h4>
            {
              (clientProducts?.length > 0) && (
              <h4 className="mb-1">
                <StyledLink
                  className="p-0"
                  onClick={ routeCreatedProducts }
                  color="first"
                  xs={12}
                  name=""
                >
                  { t("block.edition") }
                </StyledLink>
              </h4>)
            }
            {rejectedFiles && (
              <StyledLink
                className="p-0"
                onClick={ routeRejected }
                color="third"
                xs={12}
                name="link_rejected_files"
              >
                <p className="p-0 m-0" style={{ color: seventh }}>
                  { t("common.rejectedFiles") }
                </p>
              </StyledLink>
            )}
            {expiredFiles && (
              <StyledLink
                className="p-0"
                onClick={ routeExpired }
                color="third"
                xs={12}
                name="link_expired_files"
              >
                <p className="p-0 m-0" style={{ color: seventh }}>
                  { t("common.expiredFiles") }
                </p>
              </StyledLink>
            )}
          </StyledCol>
          {(show && parseInt(level) === parseInt(0)) && (
            <StyledCol xs={12} className="mt-1">
              <Alert
                key="info"
                dismissible
                variant="info"
                onClose={() => setShow(false)}
              >
                { t("homepage.startLevel") }
              </Alert>
            </StyledCol>
          )}
          {(show && parseInt(level) !== parseInt(0) && parseInt(
            level) < parseInt(lastTier?.verification_level)) && (
            <StyledCol xs={12} className="mt-1">
              <Alert
                key="warning"
                dismissible
                variant="warning"
                onClose={() => setShow(false)}
              >
                { t("homepage.finishLevel") }
              </Alert>
            </StyledCol>
          )}
        </Row>
      </Container>
    </StyledDiv>
  );
};