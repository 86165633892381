import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  dataMessageFile,
  fileStatus,
  hiddenField,
  hiddenNumber,
  labelMessageFile,
  messageFile,
  mimeType,
  paramsAdd,
  paramsEdit,
  pictureFileEdit,
  sOption,
  signatureFile,
  srcImgFile,
  srcVideoFile,
  statusRequest,
  videoFileEdit,
  waitingFile,
  waitingVideo
} from '../constants';
import {
  checkIsSentRequest,
  fieldAttribute,
  getCondition,
  isActualRequestSuccessOrRejected,
  isEmpty,
  isUndefinedNull,
  moduleCategory,
  notUndefinedNull,
  setMask,
  trimLowerCase
} from '../helpers';
import { useParams } from 'react-router-dom';


/**
 * Validation to className and show/hide field
 * @param {Object}  field
 * @param {Boolean} isVisible - Only to be used from the Fields component (in the rejected/expired document's view). Should default to true.
 * @returns
 */
export const useEdit = (field, isVisible = true) => {
  const uniqueName = `${field.unique_name}__id__${field.id}`;
  const { getValues, setValue } = useFormContext();
  const { action } = useSelector(state => state.tier);
  const data = (field?.storage_data_key) ? JSON.parse(
    field.storage_data_key) : null;
  const show = (field?.action) ? JSON.parse(field.action) : null;
  const [colSize, setColSize] = useState(field.col_size);
  const { codeTier } = useParams();

  useEffect(() => {
    setTimeout( () => { 
      if ((action === paramsEdit || action === paramsAdd || codeTier === fileStatus.rejected || codeTier === fileStatus.expired) 
          && field && !field?.unique_name?.includes('modal__')) {
        
        if (notUndefinedNull(data)) {
          const element = fieldAttribute('name', uniqueName);
          const value = getValues(uniqueName);

          if (data?.type !== 'default' && data?.type !== 'files' && (data?.show || show?.type)) {
            const group = fieldAttribute('id', `group_field_${field.id}`, 'div');
            let isChange = false;
  
            if ((isUndefinedNull(value) && data?.show === 'false') || (!isVisible && (codeTier === fileStatus.rejected || codeTier === fileStatus.expired))) {
              // hiding field and group
              if (!colSize?.includes('d-none')) setColSize(`${colSize} d-none`);
              if (group) group.classList.add('d-none');
              isChange = true;
            } else {
              if ((data?.show === 'true' || show?.type === 'show') && (
                value !== hiddenField && value !== hiddenNumber && value !== sOption && value) && (
                  element?.type !== 'checkbox')) {
                // 'resultCondition' will determine wether the field should be shown or not.
                // (e.g., current problematic case: 'user__citizenship_country')
                const resultCondition = getCondition(
                  JSON.parse(field.parent_condition),
                  element,
                  field.data_type
                )
                /* Separate conditions */
                const isDataShowFalse = data?.show === 'false'
                const isConditionFalse = !resultCondition.isCondition
                const isValueValid = value !== hiddenField && value !== hiddenNumber && value !== sOption && value;
                const isNotCheckbox = element?.type !== 'checkbox';
                /* Final condition */
                const shouldHide = (isDataShowFalse && isConditionFalse && isValueValid && isNotCheckbox) || isUndefinedNull(element)

                if (!shouldHide){
                  // Showing field and group
                  if (colSize) setColSize(colSize.replace('d-none', ''));
                  if (group) group.classList.remove('d-none');
                  isChange = true;
                }
              }
              if ((data?.show === 'true' || show?.type === 'show') && (
                value === hiddenField) && notUndefinedNull(group)) {
                if (!colSize?.includes('d-none') || !group.className.includes(
                  'd-none')) {
                  setValue(uniqueName, '');
                }
              }
              if (value === hiddenField && !colSize?.includes('d-none')) {
                setValue(uniqueName, '');
              }
            }
  
            if (isChange && element?.dataset) {
              const parent = element.dataset?.parent;
              if (parent) {
                moduleCategory(parent);
              }
            }
          } else {
            if (!field.required && value === hiddenField && !colSize?.includes(
              'd-none')) {
              setValue(uniqueName, '');
            }
          }
          if (field.mask) setMask(element, paramsAdd, field.mask);
        }
      }
    }, 100) 
  }, [colSize]);

  return { colSize, setColSize };
};

/**
 * Validation in files to show/hide
 * @param {Object} field
 * @returns
 */
export const useEditFiles = (field, onChange=null, clearSignature=null) => {
  const { t } = useTranslation();
  const { getValues, setValue } = useFormContext();
  const { action } = useSelector(state => state.tier);
  const { actualLanguage } = useSelector(state => state.base);
  const { dataProductRequest, lastProductRequestId } = useSelector(state => state.user);
  const { code } = useParams();
  const languageText = `_${trimLowerCase(actualLanguage)}`;
  const description = `description${languageText}`;

  useEffect(() => {
    setTimeout(async () => {
      if (action === paramsEdit && field) {
        const dataType = field.data_type;
        const uniqueName = `${field.unique_name}__id__${field.id}`;
        let value = getValues(uniqueName);
        /* Temporary 'http' repetition on URL fix */
        if(value && typeof value === "string"){
          const repeatedHttpIndex = value ? value?.indexOf('http', (value?.indexOf('http')+1)) : -1
          if(repeatedHttpIndex !== -1){
            value = value.slice(repeatedHttpIndex)
          }
        }
        const parent = fieldAttribute('id', `group_field_${field.id}`, 'div');
        const message = t("common.messageNullFiles");
        const haveRequestInProcess = checkIsSentRequest(dataProductRequest, code);
        const actualRequestSuccessOrRejected = isActualRequestSuccessOrRejected(dataProductRequest, lastProductRequestId);

        if (dataType === 'media_upload') {
          setValue(uniqueName, value);
          if (parent) {
            parent.classList.add('d-none');
            let blockMedia;
            if (haveRequestInProcess || actualRequestSuccessOrRejected) {
              if (trimLowerCase(value) === hiddenField) {
                blockMedia = waitingFile.replace(`##${dataMessageFile}##`, field.unique_name);
                blockMedia = blockMedia.replace(`##${labelMessageFile}##`, field[description]);
                parent.outerHTML = blockMedia.replace(`##${messageFile}##`, message);
              } else {
                blockMedia = pictureFileEdit.replace(`##${labelMessageFile}##`, field[description]);
                parent.outerHTML = blockMedia.replace(`##${srcImgFile}##`, value);
              }
            } else {
              if (onChange) {
                if (value && notUndefinedNull(value) && trimLowerCase(value) !== hiddenField) {
                  const imageResponse = await fetch(value).then(response => response.blob());
                  let imageName = value.split('/');
                  imageName = imageName[imageName.length - 1];
                  const imageExtension = imageName.split('.')[1];
  
                  const file  = new File([imageResponse], imageName, {type: mimeType[imageExtension]});
                  onChange({target: {files: [file]}});
                } else {
                    if (field.parent_condition) {
                      const parentCondition = JSON.parse(field.parent_condition);
                      const checkboxSelected = document.querySelector(`input[data-code="${parentCondition.value[0].toUpperCase()}"][type="checkbox"]`);
                      if (checkboxSelected && checkboxSelected.checked) {
                        parent.classList.remove('d-none');
                      }
                    }
                }
              }
            }
          }
        }
  
        if (dataType === 'media_signature') {
          setValue(uniqueName, value);
          if (parent) {
            parent.classList.add('d-none');
            let blockMedia;
            if (haveRequestInProcess || actualRequestSuccessOrRejected) {
              if (trimLowerCase(value) === hiddenField) {
                blockMedia = signatureFile.replace(`##${dataMessageFile}##`, field.unique_name);
                blockMedia = blockMedia.replace(`##${labelMessageFile}##`, field[description]);
                parent.outerHTML = blockMedia.replace(`##${messageFile}##`, message);
                setValue(uniqueName, hiddenField);
              } else {
                blockMedia = pictureFileEdit.replace(`##${labelMessageFile}##`, field[description]);
                parent.outerHTML = blockMedia.replace(`##${srcImgFile}##`, value);
              }
            } else {
              if (trimLowerCase(value) === hiddenField) {
                blockMedia = signatureFile.replace(`##${dataMessageFile}##`, field.unique_name);
                blockMedia = blockMedia.replace(`##${labelMessageFile}##`, field[description]);
                parent.outerHTML = blockMedia.replace(`##${messageFile}##`, message);
                setValue(uniqueName, hiddenField);
              } else {
                const inputSignature = fieldAttribute('data-name', uniqueName);
                if(notUndefinedNull(inputSignature)){
                  inputSignature.classList.remove('d-none');
                  if (notUndefinedNull(clearSignature)) {
                    clearSignature();
                  }
                }
              }
            }

          }
        }
  
        if (dataType === 'media_video') {
          setValue(uniqueName, value);
          if (parent) {
            let blockMedia;
            if (haveRequestInProcess || actualRequestSuccessOrRejected) {
              if (trimLowerCase(value) === hiddenField) {
                blockMedia = waitingVideo.replace(`##${dataMessageFile}##`, field.unique_name);
                blockMedia = blockMedia.replace(`##${labelMessageFile}##`, field[description]);
                parent.innerHTML = blockMedia.replace(`##${messageFile}##`, message);
              } else {
                blockMedia = videoFileEdit.replace(`##${labelMessageFile}##`, field[description]);
                parent.innerHTML = blockMedia.replace(`##${srcVideoFile}##`, value);
              }
            } else {
              if (trimLowerCase(value) === hiddenField) {
                blockMedia = waitingVideo.replace(`##${dataMessageFile}##`, field.unique_name);
                blockMedia = blockMedia.replace(`##${labelMessageFile}##`, field[description]);
                parent.innerHTML = blockMedia.replace(`##${messageFile}##`, message);
              } else {
                if (onChange) {
                  if (value) {
                    const imageResponse = await fetch(value).then(response => response.blob());
                    let imageName = value.split('/');
                    imageName = imageName[imageName.length - 1];
                    const imageExtension = imageName.split('.')[1];
    
                    const file  = new File([imageResponse], imageName, {type: mimeType[imageExtension]});

                    const input = fieldAttribute('data-unique-name', `${field.unique_name || field.file_name}`)
                    const transferer = new DataTransfer()
                    transferer.items.add(file)
                    input.files = transferer.files

                    const placeholderImage = fieldAttribute('data-image', `image-${uniqueName}`);
                    placeholderImage.classList.add('d-none');

                    onChange({target: {files: [file]}});
                  }
                }
              }
            }
          }
        }
      }
    }, 100)
  }, [action, field]);
};