import styled from "styled-components";
import { StyledButton } from "../../../../shared/simple";

export const Form = styled.form`
  background: transparent;
  margin: auto;
`;

export const ButtonCircle = ({ children, ...props }) => {
  return (
    <AddStyledButton { ...props }>{ children }</AddStyledButton>
  );
};

const AddStyledButton = styled(StyledButton)`
  right: 12px;
  top:-35px;
`