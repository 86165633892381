import { envSetting } from '../constants';
import { getRequestToken } from '../services/userApi';
import { BASE_ERROR_REQUEST, MESSAGE_SET } from '../redux/types';
import { isEmpty, notUndefinedNull, trimLowerCase } from './shared';
import { setLogout, setMessage } from '../redux/actions';
import { store } from '../redux/store/store';
import { Trans } from 'react-i18next';
import jwtDecode from 'jwt-decode';
import { error } from 'ajv/dist/vocabularies/applicator/dependencies';
import { t } from 'i18next';

const apiBaseUrl = envSetting.wisenrollApi;

/**** Export functions  ****/
/**
 * Get request
 * @param {String} endpoint
 * @param {Object} params
 * @param {Object} file
 * @returns
 */
export const getRequest = async (endpoint, params='', file=false) => {
  try {
    const headers = await headersFetch();
    const searchParams = (!isEmpty(params)) ? `?${new URLSearchParams(
      params).toString()}` : params;
    const url = `${apiBaseUrl}${endpoint}${searchParams}`;

    const requestInit = {
      method: 'GET',
      headers
    };

    const response = await fetch(url, requestInit);

    if (file) {
      return response;
    } else {
      const errors = handleResponse(response);
      const result = await response.json();
      result.error = errors.isError;
      result.code = errors.code;
      result.status = response.status;

      return result;
    }
  } catch (error) {
    showConexionError();
  }
};

/**
 * Post request
 * @param {String}  endpoint
 * @param {Object}  data
 * @param {Boolean} isRefreshToken - Will refresh token, if true
 * @returns
 */
export const postRequest = async (endpoint, data, isRefreshToken) => {
  try {
    const headers = await headersFetch(isRefreshToken);
    const url = `${apiBaseUrl}${endpoint}`;
    const requestInit = {
      method: 'POST',
      headers,
      body: JSON.stringify(data)
    };

    const response = await fetch(url, requestInit);
    const errors = handleResponse(response);
    const result = await response.json();
    result.error = errors.isError;
    result.code = errors.code;
    result.status = response.status;

    return result;
  } catch (error) {
    showConexionError();
  }
};

/**
 * Post file request
 * @param {String} endpoint
 * @param {Object} data
 * @param {Boolean} isAuth
 * @returns
 */
export const postFileRequest = async (endpoint, formData, isAuth=true) => {
  try {
    const token = store.getState().user.requestToken;
    const myHeaders = new Headers();
    myHeaders.append("Authorization", isAuth ? `Bearer ${token}` : "");

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formData,
      redirect: 'follow'
    };

    const url = `${apiBaseUrl}${endpoint}`;
    const response = await fetch(url, requestOptions);
    const errors = handleResponse(response);
    const result = await response.json();
    result.error = errors.isError;
    result.code = errors.code;
    result.status = response.status;

    return result;
  } catch (error) {
    showConexionError();
  }
};

/**
 * Handle request for message, variant and errors
 * @param {Object} actionRequest
 * @param {Boolean} isSuccess
 */
export const handleRequest = (response, isSuccess=true) => dispatch => {
  const errors = (notUndefinedNull(response?.errors)) ? response.errors : null;
  const clientErrors = (notUndefinedNull(response?.client_error)) ? response.client_error : null;
  const variant = (notUndefinedNull(response?.error)) ? ((
    response.error) ? 'error' : 'success') : 'error';
  let message = (notUndefinedNull(response?.message)) ? response.message : null;
  message = (notUndefinedNull(response?.success)) ? response.success : message;

  if (response?.error) {
    dispatch({ type: BASE_ERROR_REQUEST, payload: {
      error: response.error,
      code: 400
    }});
  }

  if (!clientErrors && !errors && notUndefinedNull(message)) {
    if (isSuccess && variant === 'success') {
      dispatch(setMessage(message, variant));
    } else {
      if (variant !== 'success') dispatch(setMessage(message, variant));
    }
  } else {
    if (!errors && notUndefinedNull(clientErrors)) {
      dispatch(setMessage(t("common.requestSubmmitedError"), 'error'));
    } else {
      message = (notUndefinedNull(errors?.message)) ? errors.message : null;
      message = (notUndefinedNull(response?.message)) ? response?.message : message;
      message = (typeof errors === 'string') ? errors : message;
      if (message && notUndefinedNull(message)) {
        dispatch(setMessage(message, variant));
      } else {
        if (notUndefinedNull(errors)) {
          const keys = Object.keys(errors);
          Array.from(keys).forEach(key => {
            Array.from(errors[key]).forEach(error => {
              dispatch(setMessage(error, 'error'));
            });
          });
        }
      }
    }
  }
};

/**** Local functions  ****/
/**
 * Header for fetch
 * @param {Boolean} isRefreshToken - refreshes token, if true
 * @returns
 */
const headersFetch = async (isRefreshToken) => {
  try {
    const { actualLanguage } = store.getState().base;
    const token = store.getState().user.requestToken;
    const isExpired = isTokenExpired(token);
    const header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Accept-Language': trimLowerCase(actualLanguage)
    };
    if ((token && isExpired) || !token || isRefreshToken) {
      const refreshToken = await getRequestToken();
      return {
        ...header,
        Authorization: `Bearer ${refreshToken}`,
      };
    }
    return {
      ...header,
      Authorization: `Bearer ${token}`,
    };
  } catch (error) {
    store.dispatch(setLogout());
    console.error(`headersFetch ${error}`);
    return;
  }
};

const showConexionError = () => {
  store.dispatch({
    type: MESSAGE_SET,
    payload: {
      variant: 'error',
      description: <Trans i18nKey="ERRORS.conexion" />
    }
  });
};

/**
 * Handle response for errors
 * @param {Object} response
 * @returns
 */
const handleResponse = response => {
  let code = response.status;

  if (!response.ok) {
    const { isAuthenticated, username } = store.getState().user;

    if ([401, 403, 404, 500, 502].includes(response.status)) {
      if (response.status === 401 && username) {
        code = '401';
      }
      if (response.status === 403 && username) {
        code = '403';
      }
      if (response.status === 404 && username) {
        code = '404';
      }
      if (response.status === 500 && isAuthenticated) {
        code = '500';
      }
      if (response.status === 502 && isAuthenticated) {
        code = '502';
      }
    }

    return { code: code, isError: true };
  }

  return { code: code, isError: false };
};

/**
 * Get a JWT token and validate if it is expired
 * @param {String} token
 * @returns
 */
const isTokenExpired = token => {
  if (token) {
    const { exp } = jwtDecode(token);
    const expired = Date.now() >= exp * 1000;
    return expired;
  }

  return true;
};