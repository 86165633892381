import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { paramsAdd, paramsEdit, paramsNext, routes } from '../constants';
import { setLoader } from '../redux/actions';
import { useTextLanguage } from '.';
import {
  BASE_MESSAGE_TIER,
  TIER_CLEAR_CATEGORY,
  USER_FILES
} from '../redux/types';
import { notUndefinedNull } from '../helpers';

// Hook to get values in loading
export const useLoading = () => {
  const { delay, messageTier } = useSelector(state => state.base);
  const { action, nextTier, tier } = useSelector(state => state.tier);
  const { appOrigin } = useSelector(state => state.user);
  const { languageText } = useTextLanguage();

  return {
    appOrigin,
    tier: (action === paramsAdd) ? nextTier : tier,
    delay,
    language: languageText,
    message: messageTier
  };
};

/**
 * Use to completed tier
 * @param {Object} nextTier
 * @returns
 */
export const useCompleted = nextTier => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { code } = useParams();
  const { action, lastTier, tier } = useSelector(state => state.tier);
  const { appOrigin, level } = useSelector(state => state.user);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (nextTier?.active && lastTier?.verification_level) {
      setLoading(true);

      if (code === paramsNext && ((
        level !== lastTier.verification_level && action === paramsAdd) || (
          tier?.verification_level === lastTier.verification_level && action === paramsEdit) || (
          tier?.verification_level < lastTier.verification_level && action === paramsEdit))) {
        dispatch({ type: BASE_MESSAGE_TIER, payload: true });
        setTimeout(() => {
          dispatch({ type: BASE_MESSAGE_TIER, payload: false });
          dispatch({ type: TIER_CLEAR_CATEGORY });
          dispatch({ type: USER_FILES, payload: { files: null } });
          dispatch(setLoader(false));
          if (action === paramsAdd) {
            navigate(routes.tier(nextTier?.unique_name, paramsAdd));
          } else {
            if (parseInt(level) >= parseInt(nextTier.verification_level)) {
              navigate(routes.tier(nextTier?.unique_name, paramsEdit));
            } else {
              navigate(routes.tier(nextTier?.unique_name, paramsAdd));
            }
          }
        }, 5000);
      } else {
        setLoading(false);
        dispatch({ type: BASE_MESSAGE_TIER, payload: false });
      }
    }
  }, [nextTier]);

  return { appOrigin, loading };
};