import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { Category } from './Category';
import { CloseImage } from '../user/styled';
import { customStyles } from '../../constants';
import { useTokenTier } from '../../hooks';
import closeIcon from '../../assets/images/token-modal/close.png';
import Modal from 'react-modal';
import { Button } from '../shared/simple';
import styled from 'styled-components';
import { t } from 'i18next'

const CurrentPhone = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  gap: 10px;
`
/**
 * Token component
 * @component
 * @param {Object}   category
 * @param {Object}   tier
 * @param {Object}   tierFieldState
 * @param {Number}   openModal
 * @param {Function} setOpenModal
 * @returns
 */
export const Token = ({ category, openModal, tier, tierFieldState, setOpenModal}) => {
  const { setValue } = useFormContext();
  const [additionalPhones, setAdditionalPhones] = React.useState(null)
  const {
    loading,
    modalIsOpen,
    sendToken,
    setModalIsOpen,
    showTimer,
    submitModal,
    tokenSms,
    skipButton,
    mainValidated,
    mainPhoneNumber
  } = useTokenTier(openModal, tier, tierFieldState, setOpenModal, additionalPhones, setAdditionalPhones);

  const handleClose = () => {
    setModalIsOpen(false)
    setOpenModal(1)
  }

  return (
    <Modal
      ariaHideApp={ false }
      key={ `modal_${category?.unique_name}_${category.id}` }
      isOpen={ modalIsOpen }
      onAfterOpen={() => {
        setValue(`${tokenSms?.unique_name}__id__${tokenSms.id}`, '');
        sendToken();
      }}
      onRequestClose={handleClose}
      onAfterClose={() => {
        setValue(`${tokenSms?.unique_name}__id__${tokenSms.id}`, '');
      }}
      style={ customStyles }
    >

      {
        mainValidated
        ? (
          <>
            {/* Additional Phones */}
            <div>
              <CurrentPhone>
                { t('common.validateSecondary') }
                <span className="badge bg-secondary">
                  {additionalPhones?.[0]?.[1]}
                </span>
                <CloseImage
                  alt=""
                  key={ `close_${category?.unique_name}_${category.id}` }
                  onClick={handleClose}
                  src={ closeIcon }
                />
              </CurrentPhone>
              <hr />
            </div>
          </>
        ) 
        : <>
            {/* Main Phone */}
            <div>
              <CurrentPhone>
                { t('common.validateMain') }
                <span className="badge bg-secondary">
                  {mainPhoneNumber}
                </span>
                <CloseImage
                  alt=""
                  key={ `close_${category?.unique_name}_${category.id}` }
                  onClick={handleClose}
                  src={ closeIcon }
                />
              </CurrentPhone>
              <hr />
            </div>
          </>
      }

      <Category
        category={ category }
        loading={ loading }
        key={ `category_${category.unique_name}_${category.id}` }
        onClickToken={() => {
          setValue(`${tokenSms.unique_name}__id__${tokenSms.id}`, '');
          sendToken();
        }}
        showTimer={ showTimer }
        submitModal={ submitModal }
      />
      {
        mainValidated
        ? (
          <>
            <Button
              className="btn mb-0"
              color="first"
              type="button"
              onClick={skipButton}
            >
              { t('common.skip') }
            </Button>
          </>
        ) 
        : null
      }


    </Modal>
  );
};

Token.propTypes = {
  category: PropTypes.object.isRequired,
  openModal: PropTypes.number.isRequired,
  tier: PropTypes.object.isRequired,
  tierFieldState: PropTypes.array.isRequired
};